require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/02c2e6494017ceff6b3ec64335888081/code.js', () => { 

BackendlessUI.Functions.Custom['fn_02c2e6494017ceff6b3ec64335888081'] = function fn_02c2e6494017ceff6b3ec64335888081(schemaName) {
var schema;


  schema = ({ [`timesheetSessions`]: undefined });

return 
}

})
define('./functions/03879a2285ff44c48fea35816ca5fc9e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_03879a2285ff44c48fea35816ca5fc9e'] = async function fn_03879a2285ff44c48fea35816ca5fc9e(PD, AD) {
var _RESULTS, _SHOW_ARCHIVE, _WHERE_CLAUSE, filterOptions, accountOwnerName, j, accountOwnerId, item, parent2, parentId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}

/**
 * Describe this function...
 */
async function enrich() {
  for (var j_index in _RESULTS) {
    j = _RESULTS[j_index];
    j['clientId'] = (getObjectProperty(j, 'objectId'));
    j['name'] = (getObjectProperty(j, 'orgName'));
    if ((await BackendlessUI.Functions.Custom['fn_5f270786c02ad6d4dbb4af382cb9cfdf'](({ [`name`]: (getObjectProperty(j, 'bookingName')),[`email`]: (getObjectProperty(j, 'bookingEmail')),[`phone`]: (getObjectProperty(j, 'bookingPhone')) }), ({ [`name`]: (getObjectProperty(j, 'billingName')),[`email`]: (getObjectProperty(j, 'billingEmail')),[`phone`]: (getObjectProperty(j, 'billingPhone')) })))) {
      j['sameContact'] = true;
    }
    parentId = (getObjectProperty(j, 'parent.objectId'));
    if (parentId) {
      parent2 = (await asyncListFind(_RESULTS, async (item) => {


       return ((getObjectProperty(item, 'objectId')) == parentId);
      }));
      if (!parent2) {
        parent2 = ({ [`orgName`]: 'NOT OWNED' });
      }
      j['hasParent'] = true;
      j['parentName'] = (getObjectProperty(parent2, 'orgName'));
      j['name'] = ([(getObjectProperty(j, 'name')),' (',(getObjectProperty(parent2, 'orgName')),')'].join(''));
    }
    accountOwnerId = (getObjectProperty(j, 'accountOwner.objectId'));
    if (accountOwnerId) {
      accountOwnerName = (getObjectProperty((await asyncListFind((getObjectProperty(AD, 'currentOrg.users')), async (item) => {


       return ((getObjectProperty(item, 'objectId')) == accountOwnerId);
      })), 'name'));
      j['owner'] = accountOwnerName;
    }
  }
}


  _SHOW_ARCHIVE = false;
  filterOptions = (getObjectProperty(PD, 'ui.filters'));
  if (filterOptions) {
    if (filterOptions.includes('showArchived')) {
      _SHOW_ARCHIVE = true;
    }
    if (filterOptions.includes('myClients')) {
      _WHERE_CLAUSE = ['accountOwner.objectId = \'',(getObjectProperty(AD, 'currentUser.objectId')),'\''].join('');
    }
  }
  _RESULTS = (await Backendless.Request.get(`${Backendless.appPath}/services/ORGS/v1/orgs`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'orgId': JSON.stringify((getObjectProperty(AD, 'currentOrg.objectId'))),'tablename': JSON.stringify('clients'),'whereClause': JSON.stringify(_WHERE_CLAUSE),'showArchive': _SHOW_ARCHIVE }).send());
  await enrich();
  PD['orgs'] = _RESULTS;

return 
}

})
define('./functions/064af7773cbb9bdb2ff71d23201cd648/code.js', () => { 

BackendlessUI.Functions.Custom['fn_064af7773cbb9bdb2ff71d23201cd648'] = async function fn_064af7773cbb9bdb2ff71d23201cd648(property, list) {
var item;



return ([property,' in (',(await Promise.all(list.map(async item => {; return (['\'',item,'\''].join(''));}))).join(','),')'].join(''))
}

})
define('./functions/0791a4ce57a674abe99a52461d43d007/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0791a4ce57a674abe99a52461d43d007'] = async function fn_0791a4ce57a674abe99a52461d43d007(timestamp) {
return (await (async function(timestamp) {
	  // Create a date object for the given timestamp adjusted to Europe/London timezone
	  const options = { timeZone: 'Europe/London', hour12: false };
	  const date = new Date(timestamp).toLocaleString('en-US', options);
	  const dateParts = new Date(date).toString().split(' ');

	  // Extract hours and minutes
	  const timeParts = dateParts[4].split(':');
	  let hours = parseInt(timeParts[0], 10);
	  const minutes = parseInt(timeParts[1], 10);

	  // Determine AM or PM suffix
	  const suffix = hours >= 12 ? 'p' : 'a';

	  // Convert hours to 12-hour format
	  hours = hours % 12;
	  hours = hours ? hours : 12; // the hour '0' should be '12'

	  // Format time
	  const formattedTime = `${hours}${minutes > 0 ? ':' + timeParts[1] : ''}${suffix}`;

	  return formattedTime;
})(timestamp))
}

})
define('./functions/0d6ab7518735beb79ac73b951ae997e1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0d6ab7518735beb79ac73b951ae997e1'] = async function fn_0d6ab7518735beb79ac73b951ae997e1(calendar, sessions) {
return (await (async function(calendar, sessions) {
	    sessions.forEach(session => {
	        const sessionDate = session.date;
	        for (let cell in calendar) {
	            if (calendar[cell].date === sessionDate) {
	                if (!calendar[cell].sessions) {
	                    calendar[cell].sessions = [];
	                }
	                calendar[cell].sessions.push(session);
	                break;
	            }
	        }
	    });
	    return calendar;
})((await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](calendar)), sessions))
}

})
define('./functions/11ee3094f9ea7cfc9fc548a8b2383f08/code.js', () => { 

BackendlessUI.Functions.Custom['fn_11ee3094f9ea7cfc9fc548a8b2383f08'] = async function fn_11ee3094f9ea7cfc9fc548a8b2383f08(value, property, data) {
return (await (async function(value, property, data) {
	  // Helper function to recursively search for the property in nested objects or arrays
	  function searchInObject(obj) {
	    for (const key in obj) {
	      if (Array.isArray(obj[key])) {
	        // Iterate through the array and search for the matching object
	        for (const item of obj[key]) {
	          if (item[property] === value) {
	            return item;
	          }
	        }
	      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
	        // Recursively search in nested objects
	        const result = searchInObject(obj[key]);
	        if (result) {
	          return result;
	        }
	      }
	    }
	    return null;
	  }

	  // Start the search
	  return searchInObject(data);
})(value, property, data))
}

})
define('./functions/162c4ab2f5f85e8d4aff7147db1d6f39/code.js', () => { 

BackendlessUI.Functions.Custom['fn_162c4ab2f5f85e8d4aff7147db1d6f39'] = async function fn_162c4ab2f5f85e8d4aff7147db1d6f39(schema, orgType, data, permissionType) {
return (await (async function(schema, orgType, permissionType, data) {

	function filterObject(obj, permissionSchema, orgType) {
	  const result = {};
	  for (const field in obj) {
	    const value = obj[field];

	    // Skip fields that are null or undefined
	    if (value == null) continue;

	    // Check if the field is directly allowed
	    const allowedTypes = permissionSchema[field];
	    if (allowedTypes && allowedTypes.includes(orgType)) {
	      result[field] = value;
	      continue;
	    }

	    // Handle nested objects
	    if (typeof value === 'object' && !Array.isArray(value)) {
	      const nestedPermissionSchema = getNestedPermissionSchema(permissionSchema, field);
	      if (nestedPermissionSchema) {
	        const nestedResult = filterObject(value, nestedPermissionSchema, orgType);
	        if (Object.keys(nestedResult).length > 0) {
	          result[field] = nestedResult;
	        }
	      }
	    }

	    // Handle arrays
	    if (Array.isArray(value)) {
	      const nestedPermissionSchema = getNestedPermissionSchema(permissionSchema, field);
	      if (nestedPermissionSchema) {
	        const filteredArray = value.map(item => filterObject(item, nestedPermissionSchema, orgType));
	        result[field] = filteredArray.filter(item => Object.keys(item).length > 0);
	      }
	    }
	  }
	  return result;
	}

	function getNestedPermissionSchema(permissionSchema, field) {
	  const nestedPermissionSchema = {};
	  for (const key in permissionSchema) {
	    if (key.startsWith(`${field}.`)) {
	      const nestedField = key.slice(field.length + 1);
	      nestedPermissionSchema[nestedField] = permissionSchema[key];
	    } else if (key.startsWith(`[${field}].`)) {
	      const nestedField = key.slice(field.length + 3);
	      nestedPermissionSchema[nestedField] = permissionSchema[key];
	    }
	  }
	  return Object.keys(nestedPermissionSchema).length > 0 ? nestedPermissionSchema : null;
	}

	// Get the relevant schema based on permission type
	const permissionSchema = schema[permissionType];

	if (Array.isArray(data)) {
	  return data.map(obj => filterObject(obj, permissionSchema, orgType));
	} else if (data && typeof data === 'object') {
	  return filterObject(data, permissionSchema, orgType);
	} else {
	  throw new TypeError('Invalid data type. Expected an object or an array of objects.');
	}
})(null, orgType, permissionType, data))
}

})
define('./functions/1aea58e1ea0daedb71a7a1544e2963e5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1aea58e1ea0daedb71a7a1544e2963e5'] = async function fn_1aea58e1ea0daedb71a7a1544e2963e5() {
  await (async function() {
  	const script = document.createElement('script');
  	script.src = 'https://unpkg.com/luxon/build/global/luxon.min.js';
  	script.onload = function() {
  	  console.log('Luxon is loaded and ready to use!');
  	  // You can now use Luxon functions after this point.
  	  // Example:
  	  const now = luxon.DateTime.local().setZone('Europe/London').toString();
  	  console.log('London time:', now);
  	};
  	script.onerror = function() {
  	  console.error('Failed to load Luxon.');
  	};
  	document.head.appendChild(script);
  })();

return 
}

})
define('./functions/20a6411414dd0a2b5cdef3bb3bc8ed0f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_20a6411414dd0a2b5cdef3bb3bc8ed0f'] = async function fn_20a6411414dd0a2b5cdef3bb3bc8ed0f(timeStr) {
return (await (async function(timeStr) {
	    // Split the time string by colon
	    const parts = timeStr.split(':');
	    if (parts.length !== 2) return false;

	    const [hourStr, minuteStr] = parts;

	    // Check that both hour and minute are strictly numeric
	    if (!/^\d+$/.test(hourStr) || !/^\d+$/.test(minuteStr)) {
	        return false;
	    }

	    const hour = parseInt(hourStr, 10);
	    const minute = parseInt(minuteStr, 10);

	    // Validate hour and minute ranges
	    if (hour < 0 || hour > 23) return false;
	    if (minute < 0 || minute > 59) return false;

	    return true;
})(timeStr))
}

})
define('./functions/269a047dbcd9deccf699dd9be21fa0ed/code.js', () => { 

BackendlessUI.Functions.Custom['fn_269a047dbcd9deccf699dd9be21fa0ed'] = async function fn_269a047dbcd9deccf699dd9be21fa0ed(obj1, obj2, ignore) {
return (await (async function(obj1, obj2, ignore) {
	function deepEqual(obj1, obj2, ignore) {
	    // If ignore is not provided, set it to an empty array
	    ignore = ignore || [];

	    function isObject(value) {
	        return value && typeof value === 'object' && !Array.isArray(value);
	    }

	    function isArray(value) {
	        return Array.isArray(value);
	    }

	    function arraysEqual(arr1, arr2) {
	        if (arr1.length !== arr2.length) return false;
	        for (let i = 0; i < arr1.length; i++) {
	            if (!deepEqual(arr1[i], arr2[i], ignore)) return false;
	        }
	        return true;
	    }

	    if (!isObject(obj1) || !isObject(obj2)) {
	        return obj1 === obj2;
	    }

	    const keys1 = Object.keys(obj1).filter(key => !ignore.includes(key));
	    const keys2 = Object.keys(obj2).filter(key => !ignore.includes(key));

	    if (keys1.length !== keys2.length) return false;

	    for (let key of keys1) {
	        if (!keys2.includes(key)) return false;

	        const val1 = obj1[key];
	        const val2 = obj2[key];

	        const areObjects = isObject(val1) && isObject(val2);
	        const areArrays = isArray(val1) && isArray(val2);

	        if (
	            (areObjects && !deepEqual(val1, val2, ignore)) ||
	            (areArrays && !arraysEqual(val1, val2)) ||
	            (!areObjects && !areArrays && val1 !== val2)
	        ) {
	            return false;
	        }
	    }

	    return true;
	}

	return deepEqual(obj1, obj2, ignore);
})(obj1, obj2, ignore))
}

})
define('./functions/29957d83d80974dad1a157b43bf89f85/code.js', () => { 

BackendlessUI.Functions.Custom['fn_29957d83d80974dad1a157b43bf89f85'] = async function fn_29957d83d80974dad1a157b43bf89f85(inputValue, orgType, componentId, PD, userOrgId) {
var options, org, results;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function locum() {
  options = [];
  results = (await Backendless.Data.of('locums').find(Backendless.DataQueryBuilder.create().setWhereClause((['(orgName LIKE \'%',inputValue,'%\' OR reference LIKE \'%',inputValue,'%\') AND agency.objectId = \'',userOrgId,'\''].join(''))).setPageSize(100)));
  for (var org_index in results) {
    org = results[org_index];
    addItemToList(options, ({ [`value`]: (getObjectProperty(org, 'objectId')),[`label`]: [(getObjectProperty(org, 'orgName')),' (',(getObjectProperty(org, 'reference')),')'].join('') }));
  }
  return options
}

/**
 * Describe this function...
 */
async function populateDropdown() {
  if (orgType == 'locums') {
    return (await locum());
  }
  if (orgType == 'clients') {
    return (await client());
  }
  if (orgType == 'agencies') {
    return (await agency());
  }
}

/**
 * Describe this function...
 */
async function client() {
  options = [];
  results = (await Backendless.Data.of('clients').find(Backendless.DataQueryBuilder.create().setWhereClause((['(orgName LIKE \'%',inputValue,'%\' OR reference LIKE \'%',inputValue,'%\') AND agency.objectId = \'',userOrgId,'\''].join(''))).setPageSize(100)));
  for (var org_index2 in results) {
    org = results[org_index2];
    addItemToList(options, ({ [`value`]: (getObjectProperty(org, 'objectId')),[`label`]: [(getObjectProperty(org, 'orgName')),' (',(getObjectProperty(org, 'reference')),')'].join('') }));
  }
  return options
}

/**
 * Describe this function...
 */
async function agency() {
  options = [];
  results = (await Backendless.Data.of('agencies').find(Backendless.DataQueryBuilder.create().setWhereClause((['(orgName LIKE \'%',inputValue,'%\' OR reference LIKE \'%',inputValue,'%\') AND agency.objectId = \'',userOrgId,'\''].join(''))).setPageSize(100)));
  for (var org_index3 in results) {
    org = results[org_index3];
    addItemToList(options, ({ [`value`]: (getObjectProperty(org, 'objectId')),[`label`]: [(getObjectProperty(org, 'orgName')),' (',(getObjectProperty(org, 'reference')),')'].join('') }));
  }
  return options
}


  stopSetTimeout('lazySearch');
  if ((new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$', '')).test(inputValue)) {
    return inputValue;
  } else if (!inputValue) {
  } else {

    ;(function() {
      const callback = async () => {
          options = await populateDropdown();
      if (!!options.length) {
        await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](PD, (String('___ui.') + String(componentId)), options);
      }

      };

      const timerId = 'lazySearch';
      const timerDelay = 500;

      runSetTimeout(timerId, callback, timerDelay)
    })()
  }

return 
}

})
define('./functions/2d2410fcb9c847a73b5234b1e3a3cfbd/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2d2410fcb9c847a73b5234b1e3a3cfbd'] = async function fn_2d2410fcb9c847a73b5234b1e3a3cfbd(field, values) {
return (await (async function(field, values) {
	    if (!values || values.length === 0 || !field) {
	        return '';
	    }
	    const valueList = values.map(value => `'${value}'`).join(', ');
	    return `${field} IN (${valueList})`;
})(field, values))
}

})
define('./functions/2d49dc39e5110a714d2836779fb915f7/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2d49dc39e5110a714d2836779fb915f7'] = async function fn_2d49dc39e5110a714d2836779fb915f7(changedField, startDateString, startTimeString, endTimeString, breaks, duration) {
var END_TIME_STRING, END_DATE_STRING, START_TIME_STRING, START_DATE_STRING, DURATION, CHANGED_FIELD, BREAKS, end, start, dayOffset;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function updateStart() {
  start = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](START_DATE_STRING, START_TIME_STRING));
  end = (await BackendlessUI.Functions.Custom['fn_b48df14473b24f1ae216bd5e59da9584'](start, BREAKS, DURATION));
  END_DATE_STRING = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](end)), 'shortDateString'));
  END_TIME_STRING = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](end)), 'timeString'));
}

/**
 * Describe this function...
 */
async function updateEnd() {
  dayOffset = (await BackendlessUI.Functions.Custom['fn_3a2aee9d92cd50253b5175bcd681beb3'](START_TIME_STRING, END_TIME_STRING));
  end = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](START_DATE_STRING, END_TIME_STRING)) + dayOffset * 86400000;
  END_DATE_STRING = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](end)), 'shortDateString'));
  DURATION = (await BackendlessUI.Functions.Custom['fn_b74c8eb6ac2467ea66e7b09125c4b966']((await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](START_DATE_STRING, START_TIME_STRING)), end, BREAKS));
}


  CHANGED_FIELD = changedField;
  START_DATE_STRING = startDateString;
  START_TIME_STRING = startTimeString;
  END_TIME_STRING = endTimeString;
  BREAKS = breaks;
  DURATION = duration;
  END_DATE_STRING = null;
  if (CHANGED_FIELD == 'end') {
    await updateEnd();
  } else {
    await updateStart();
  }

return ({ [`start`]: (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](START_DATE_STRING, START_TIME_STRING)),[`end`]: (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](END_DATE_STRING, END_TIME_STRING)),[`duration`]: DURATION,[`startString`]: ({ [`date`]: START_DATE_STRING,[`time`]: START_TIME_STRING }),[`endString`]: ({ [`date`]: END_DATE_STRING,[`time`]: END_TIME_STRING }) })
}

})
define('./functions/2d66f332efdfa294dacc1adcc07afcb4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2d66f332efdfa294dacc1adcc07afcb4'] = async function fn_2d66f332efdfa294dacc1adcc07afcb4(duration) {
return (await (async function(duration) {
	    // Check if (duration * 2) / 60 is a whole number
	    if ((duration * 2) % 60 === 0) {
	        const hours = duration / 60;
	        const hourStr = hours === 1 ? "hr" : "hrs";
	        return `${hours}${hourStr}`;
	    } else {
	        // Convert duration into hours and minutes
	        const hours = Math.floor(duration / 60);
	        const minutes = duration % 60;
	        const hoursStr = hours > 0 ? `${hours}h ` : "";
	        const minutesStr = `${minutes}m`;
	        return hoursStr + minutesStr;
	    }
})(duration))
}

})
define('./functions/30e666660b38ecf7e5e3d027a1b44bef/code.js', () => { 

BackendlessUI.Functions.Custom['fn_30e666660b38ecf7e5e3d027a1b44bef'] = async function fn_30e666660b38ecf7e5e3d027a1b44bef(month, year, monthOffset) {
return (await (async function(month, year, monthOffset) {
	  const minDate = new Date(2024, 0); // January 2024
	  const maxDate = new Date(2030, 11); // December 2030

	  // Create a Date object based on the input month and year
	  let currentDate = new Date(year, month - 1); // Month is 0-indexed

	  // Adjust the month by the offset
	  currentDate.setMonth(currentDate.getMonth() + monthOffset);

	  // Ensure the date does not go beyond the allowed range
	  if (currentDate < minDate) {
	    currentDate = minDate;
	  } else if (currentDate > maxDate) {
	    currentDate = maxDate;
	  }

	  // Extract the adjusted year and month
	  const adjustedYear = currentDate.getFullYear();
	  const adjustedMonth = currentDate.getMonth() + 1; // Convert back to 1-indexed

	  return {
	    year: adjustedYear,
	    month: adjustedMonth
	  };
})(month, year, monthOffset))
}

})
define('./functions/31fb469a79e9912288153bfa95861b3b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_31fb469a79e9912288153bfa95861b3b'] = async function fn_31fb469a79e9912288153bfa95861b3b(PD, AD) {
var msg, html, groupedSessions, customMsg, selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setDefaultInfo() {
  if (getObjectProperty(PD, 'selectedLocum.email')) {
    return ;
  }
  msg = ['Dear ',(getObjectProperty(PD, 'selectedLocum.name')),',\n\nHere\'s a reminder of the outstanding sessions to be submitted.'].join('');
  (getObjectProperty(PD, 'selectedLocum'))['email'] = ({ [`customMsg`]: msg });
}


  await setDefaultInfo();
  selectedSessions = (getObjectProperty(PD, 'selectedLocum.selectedChaseTable'));
  groupedSessions = (await BackendlessUI.Functions.Custom['fn_e6a439de34b58b9a7e563184c75416ea'](selectedSessions, 'clientId', ['clientId', 'client']));
  customMsg = (await (async function(inputString) {
  	    if (typeof inputString !== 'string') {
  	        throw new TypeError('Input must be a string');
  	    }
  	    return inputString.replace(/\n/g, '<br>');
  })((getObjectProperty(PD, 'selectedLocum.email.customMsg'))));
  console.log('groupedSessions');
  console.log(groupedSessions);
  html = (await (async function(locumName, customMsg, sessions) {
  	function sanitize(value) {
  	    return value == null ? '' : value;
  	}

  	function generateEmailHTML(locumName, customMsg, sessions) {
  	    let emailHTML = `
  	    <!DOCTYPE html>
  	    <html lang="en">
  	    <head>
  	        <meta charset="UTF-8">
  	        <meta name="viewport" content="width=device-width, initial-scale=1.0">
  	        <style>
  	            body {
  	                font-family: Arial, sans-serif;
  	            }
  	            .container {
  	                margin: 0 auto;
  	                padding: 20px;
  	                max-width: 600px;
  	                border: 1px solid #ddd;
  	                border-radius: 8px;
  	                margin-bottom: 20px;
  	            }
  	            .header {
  	                margin-bottom: 20px;
  	            }
  	            .header p {
  	                margin: 0;
  	                font-size: 16px;
  	                line-height: 1.5;
  	            }
  	            .table-container {
  	                margin-top: 20px;
  	            }
  	            table {
  	                width: 100%;
  	                border-collapse: collapse;
  	                margin-bottom: 20px;
  	            }
  	            th, td {
  	                padding: 10px;
  	                border: 1px solid #ddd;
  	                text-align: left;
  	            }
  	            th {
  	                background-color: #f4f4f4;
  	            }
  	        </style>
  	        <title>Email Template</title>
  	    </head>
  	    <body>
  	        <div class="header">
  	            <!-- COMMENTED OUT
  	            <p>Dear ${sanitize(locumName)},</p>
  	            <br>

  	              <p>Please kindly submit signed timesheets for these sessions. If any information below are different to what you expect, please feel free to contact us for assistance.</p>
  	              <br>
  	            -->
  	            <p>${sanitize(customMsg)}</p>
  	        </div>`;

  	    sessions.forEach(clientGroup => {
  	        emailHTML += `
  	        <div class="container">
  	            <div class="header">
  	                <p><strong>Client:</strong> ${sanitize(clientGroup.client)}</p>
  	            </div>
  	            <div class="table-container">
  	                <h2>Sessions</h2>
  	                <table>
  	                    <thead>
  	                        <tr>
  	                            <th>Session</th>
  	                            <th>Rate</th>
  	                            <th>Session notes</th>
  	                        </tr>
  	                    </thead>
  	                    <tbody>`;

  	        clientGroup.items.forEach(session => {
  	            emailHTML += `
  	                        <tr>
  	                            <td>${sanitize(session.session)}</td>
  	                            <td>${sanitize(session.rateLocum)}</td>
  	                            <td>${sanitize(session.notesSession)}</td>
  	                        </tr>`;
  	        });

  	        emailHTML += `
  	                    </tbody>
  	                </table>
  	            </div>
  	        </div>`;
  	    });

  	    emailHTML += `
  	    </body>
  	    </html>`;

  	    return emailHTML;
  	}

  	return generateEmailHTML(locumName, customMsg, sessions);
  })((getObjectProperty(PD, 'selectedLocum.name')), customMsg, groupedSessions));
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6']((getObjectProperty(PD, 'selectedLocum')), 'email.body', html);
  Object.assign((getObjectProperty(PD, 'selectedLocum.email')), ({ [`to`]: (getObjectProperty(PD, 'selectedLocum.billingEmail')),[`cc`]: (getObjectProperty(AD, 'currentUser.email')) }));

return html
}

})
define('./functions/3364675c1c882e21413ac8fdc3b4cdae/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3364675c1c882e21413ac8fdc3b4cdae'] = async function fn_3364675c1c882e21413ac8fdc3b4cdae(AD, PD) {
var PD, MONTHLY_DATA, AD, month, monthArray, DELTA_START_END, timePeriods, QUARTERS, USER_ID, changedSessions, txResults, oneTxResult, tx, whereClause_delta, whereClause_realised, whereClause_booked, monthCount, quarter, marginDelta, booked, realisedMargins, bookedMargins, comparisonWeek;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function changesDuringDeltaPeriod() {
  changedSessions = (await Backendless.Data.of('sessions').find(Backendless.DataQueryBuilder.create().setWhereClause((['client.accountOwner.objectId = \'',USER_ID,'\' AND marginTracker.timestamp >= ',(getObjectProperty(DELTA_START_END, 'start')),' AND marginTracker.timestamp >= ',(getObjectProperty(DELTA_START_END, 'end')),' AND status = \'FILLED\''].join(''))).setPageSize(100)));
  console.log('CHANGED SESSIONS');
  console.log(changedSessions);
}

/**
 * Describe this function...
 */
async function getData() {
  for (var quarter_index in QUARTERS) {
    quarter = QUARTERS[quarter_index];
    tx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
    for (var month_index in quarter) {
      month = quarter[month_index];
      await ( async () => {
        const _uow = tx;

        whereClause_realised = ['client.accountOwner.objectId = \'',USER_ID,'\' AND start > ',(getObjectProperty(month, 'start')),' AND end < ',(getObjectProperty(month, 'end')),' AND (timesheets[sessions].status = \'APPROVED\' ',' OR timesheets[sessions].status = \'INVOICED\') '].join('');
        whereClause_booked = ['client.accountOwner.objectId = \'',USER_ID,'\' AND start >= ',(getObjectProperty(month, 'start')),' AND end <= ',(getObjectProperty(month, 'end')),' AND status = \'FILLED\''].join('');
        whereClause_delta = ['timestamp >=',(getObjectProperty(DELTA_START_END, 'start')),' AND timestamp <=',(getObjectProperty(DELTA_START_END, 'end')),' AND sessions[marginTracker].start >=',(getObjectProperty(month, 'start')),' AND sessions[marginTracker].end <=',(getObjectProperty(month, 'end')),' AND sessions[marginTracker].client.accountOwner.objectId = \'',USER_ID,'\''].join('');
        _uow.find('sessions', Backendless.DataQueryBuilder.create().setWhereClause(whereClause_realised).setProperties(['sum(margin) as realisedMargin', 'sum(actualMargin) as realisedActualMargin', 'count(margin) as realisedSessionsCount', 'client.accountOwner.name', 'client.accountOwner.objectId']).setGroupBy(['client.accountOwner.objectId']).setPageSize(10)).setOpResultId(([(getObjectProperty(month, 'month')),'_',(getObjectProperty(month, 'year')),'_realised'].join('')));
        _uow.find('sessions', Backendless.DataQueryBuilder.create().setWhereClause(whereClause_booked).setProperties(['sum(margin) as bookedMargin', 'sum(actualMargin) as bookedActualMargin', 'count(margin) as bookedSessionsCount', 'client.accountOwner.name as consultant', 'client.accountOwner.objectId as consultantId', 'count(DISTINCT client.objectId) as bookedClientsCount', 'count(DISTINCT locum.objectId) as bookedLocumsCount', 'sum(duration) as bookedMins']).setGroupBy(['client.accountOwner.objectId']).setPageSize(10)).setOpResultId(([(getObjectProperty(month, 'month')),'_',(getObjectProperty(month, 'year')),'_booked'].join('')));
        _uow.find('marginTracker', Backendless.DataQueryBuilder.create().setWhereClause(whereClause_delta).setProperties(['sum(marginDelta) as marginDelta']).setPageSize(10)).setOpResultId(([(getObjectProperty(month, 'month')),'_',(getObjectProperty(month, 'year')),'_delta'].join('')));

      })();

    }
    oneTxResult = (getObjectProperty((await tx.execute()), 'results'));
    if (txResults) {
      Object.assign(txResults, oneTxResult);
    } else {
      txResults = oneTxResult;
    }
  }
  return (await (async function(input) {
	    const resultArray = [];
	    const monthDataMap = {};

	    for (const key in input) {
	        if (input.hasOwnProperty(key)) {
	            const match = key.match(/^(\w{3})_(\d{4})_/);
	            if (match) {
	                const monthName = match[1];
	                const year = parseInt(match[2], 10);
	                const result = input[key].result;

	                if (result && result.length > 0) {
	                    const mapKey = `${monthName}_${year}`;
	                    if (!monthDataMap[mapKey]) {
	                        monthDataMap[mapKey] = {
	                            month: monthName,
	                            year: year
	                        };
	                    }

	                    result.forEach(item => {
	                        Object.assign(monthDataMap[mapKey], item);
	                    });
	                }
	            }
	        }
	    }

	    for (const key in monthDataMap) {
	        if (monthDataMap.hasOwnProperty(key)) {
	            resultArray.push(monthDataMap[key]);
	        }
	    }

	    return resultArray;
})(txResults))
}

/**
 * Describe this function...
 */
async function enrichData() {
  for (var month_index2 in MONTHLY_DATA) {
    month = MONTHLY_DATA[month_index2];
    month['lastWk'] = (await (async function(booked, marginDelta) {
    	  const bookedMargin = booked ?? 0;
    	  const marginDeltaValue = marginDelta ?? 0;
    	  const result =  bookedMargin - marginDeltaValue;
    	  return Math.round(result * 100) / 100;
    })((getObjectProperty(month, 'bookedMargin')), (getObjectProperty(month, 'marginDelta'))));
    if (getObjectProperty(month, 'bookedMins')) {
      month['bookedHrs'] = (Number.parseFloat(((getObjectProperty(month, 'bookedMins')) / 60)).toFixed(0));
    }
    if (getObjectProperty(month, 'bookedMargin')) {
      month['achieved'] = (String(Number.parseFloat((((getObjectProperty(month, 'bookedMargin')) / (getObjectProperty(month, 'target'))) * 100)).toFixed(0)) + String('%'));
    }
  }
}


  AD = AD;
  PD = PD;
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](PD, 'ui.showSpinner', true);
  USER_ID = (getObjectProperty(AD, 'currentUser.objectId'));
  monthArray = (await (async function(dateTime) {
  	const { DateTime } = luxon;

  	const getYearMonths = (year) => {
  	  const startDate = DateTime.fromObject({ year: year, month: 1, day: 1 }, { zone: 'Europe/London' }).startOf('year');
  	  let monthsArray = [];

  	  for (let i = 0; i < 12; i++) {
  	    const monthDate = startDate.plus({ months: i });
  	    const monthObject = {
  	      month: monthDate.toFormat('MMM'),
  	      year: monthDate.year,
  	      start: monthDate.startOf('month').toMillis(),
  	      end: monthDate.endOf('month').toMillis()
  	    };
  	    monthsArray.push(monthObject);
  	  }

  	  return monthsArray;
  	};

  	return getYearMonths(dateTime);
  })((getObjectProperty(PD, 'ui.year'))));
  QUARTERS = (await (async function(months) {
  	    const quarters = [];
  	    const monthsPerQuarter = 3;

  	    for (let i = 0; i < months.length; i += monthsPerQuarter) {
  	        quarters.push(months.slice(i, i + monthsPerQuarter));
  	    }

  	    return quarters;
  })(monthArray));
  DELTA_START_END = (getObjectProperty(PD, 'ui.deltaStartEnd'));
  MONTHLY_DATA = await getData();
  MONTHLY_DATA = (await (async function(margins, targets) {
  	    // Create a mapping of month names to lowercase and handle 'decm' for December
  	    const monthMap = {
  	        "Jan": "jan",
  	        "Feb": "feb",
  	        "Mar": "mar",
  	        "Apr": "apr",
  	        "May": "may",
  	        "Jun": "jun",
  	        "Jul": "jul",
  	        "Aug": "aug",
  	        "Sep": "sep",
  	        "Oct": "oct",
  	        "Nov": "nov",
  	        "Dec": "decm"
  	    };

  	    // Loop through each margin
  	    margins.forEach(margin => {
  	        const monthLower = monthMap[margin.month];

  	        // Find the corresponding targets for the year
  	        const targetForYear = targets.find(target => target.year === margin.year);

  	        // If targets for the year are found, add the corresponding target value
  	        if (targetForYear && monthLower in targetForYear) {
  	            margin.target = targetForYear[monthLower];
  	        } else {
  	            // If no target found for this month, you can set a default value like null or 0
  	            margin.target = null; // or 0
  	        }
  	    });

  	    return margins;
  })(MONTHLY_DATA, (getObjectProperty(AD, 'userTargets'))));
  MONTHLY_DATA = (await (async function(arr1, arr2) {
  	  const mergedArray = [...arr1];

  	  arr2.forEach(obj2 => {
  	    const matchIndex = mergedArray.findIndex(obj1 => obj1.month === obj2.month && obj1.year === obj2.year);

  	    if (matchIndex > -1) {
  	      mergedArray[matchIndex] = { ...mergedArray[matchIndex], ...obj2 };
  	    } else {
  	      mergedArray.push(obj2);
  	    }
  	  });

  	  return mergedArray;
  })(monthArray, MONTHLY_DATA));
  MONTHLY_DATA = (await asyncListSort(MONTHLY_DATA, 1, async (month) => {


   return (getObjectProperty(month, 'start'));
  }));
  await enrichData();
  AD['cashflow'] = (await BackendlessUI.Functions.Custom['fn_c67e840d0a55e6fa6c4225c2979e2892'](MONTHLY_DATA));
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](PD, 'ui.showSpinner', false);

return 
}

})
define('./functions/35adebfca318334c7b3df2f3ec67e7e4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_35adebfca318334c7b3df2f3ec67e7e4'] = async function fn_35adebfca318334c7b3df2f3ec67e7e4(PD, AD) {
var locumName, locumId, selectedSessions, clientName, clientId, processor, item, processorCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}

/**
 * Describe this function...
 */
async function setProcessor() {
  processorCount = (await BackendlessUI.Functions.Custom['fn_465a168c4dbaac59e91987824a13cce3'](selectedSessions, 'processor'));
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](PD, 'ui.processorWarning', (processorCount > 1 ? true : false));
  if (processorCount == 1) {
    processor = (getObjectProperty((selectedSessions[0]), 'processor'));
  } else {
    processor = (getObjectProperty((await asyncListFind((getObjectProperty(AD, 'clients.all')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) == clientId);
    })), 'defaultProcessor'));
  }
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6']((getObjectProperty(PD, 'selectedLocum')), 'timesheet.processor', processor);
}

/**
 * Describe this function...
 */
async function enrichWithGeneralInfo() {
  Object.assign((getObjectProperty(PD, 'selectedLocum.timesheet')), ({ [`client`]: ({ [`objectId`]: clientId,[`orgName`]: clientName }),[`locum`]: ({ [`objectId`]: locumId,[`orgName`]: locumName }),[`readOnly`]: false }));
}

/**
 * Describe this function...
 */
async function enrichWithActual() {
  selectedSessions = (await BackendlessUI.Functions.Custom['fn_8b9f564fdda88d8b74ab06d3e55b7ae4'](selectedSessions));
  (getObjectProperty(PD, 'selectedLocum.timesheet'))['sessions'] = selectedSessions;
}


  delete (getObjectProperty(PD, 'selectedLocum'))['timesheet'];
  selectedSessions = (getObjectProperty(PD, 'selectedLocum.selectedSessionsTable'));
  clientId = (getObjectProperty((selectedSessions[0]), 'clientId'));
  clientName = (getObjectProperty((selectedSessions[0]), 'client'));
  locumId = (getObjectProperty(PD, 'selectedLocum.objectId'));
  locumName = (getObjectProperty(PD, 'selectedLocum.name'));
  await setProcessor();
  await enrichWithActual();
  await enrichWithGeneralInfo();

return 
}

})
define('./functions/363dd2f66c6285b9d669ca1a2b3620fb/code.js', () => { 

BackendlessUI.Functions.Custom['fn_363dd2f66c6285b9d669ca1a2b3620fb'] = async function fn_363dd2f66c6285b9d669ca1a2b3620fb(AD, PD, locum) {
var locums, PD, whereClause, locum;


  if (locum) {
    whereClause = ['locum.objectId =\'',locum,'\''].join('');
  } else {
    whereClause = ['start < ',((new Date()).valueOf()),' AND status = \'FILLED\'',' AND timesheets[sessions].objectId IS NULL'].join('');
  }
  locums = (await Backendless.Data.of('sessions').find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setProperties(['locum.objectId AS locumId', 'locum.orgName AS locum', 'count(objectId) AS sessionsCount', 'sum(margin) AS marginSum', 'locum.billingEmail AS locumEmail']).setSortBy('sessionsCount DESC').setGroupBy('locum').setPageSize(100)));
  PD['locums'] = locums;

return 
}

})
define('./functions/39a11f0756bf12ba67360bfdb75caaa9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_39a11f0756bf12ba67360bfdb75caaa9'] = async function fn_39a11f0756bf12ba67360bfdb75caaa9(name) {
return (await (async function(name) {
	/**
	 * Extracts the two-letter initials from a given name.
	 *
	 * @param {string} name - The name to extract initials from.
	 * @returns {string} - The two-letter initials.
	 */

	    if (!name || typeof name !== "string") {
	        return ""; // Return empty string for invalid input
	    }

	    // Split the name by spaces, hyphens, or other delimiters
	    const words = name.trim().split(/[-\s]+/);

	    // Take the first letter of the first two words (if available)
	    const initials = words.slice(0, 2).map(word => word[0]?.toUpperCase() || "").join("");

	    // Ensure the result is exactly two characters long
	    return initials.padEnd(2, "").slice(0, 2);


})(name))
}

})
define('./functions/3a2aee9d92cd50253b5175bcd681beb3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3a2aee9d92cd50253b5175bcd681beb3'] = function fn_3a2aee9d92cd50253b5175bcd681beb3(startTimeString, endTimeString) {
var endHour, startHour, endMin, startMin;


  if (!((new RegExp('^(?:[01]\\d|2[0-3]):[0-5]\\d$', '')).test(startTimeString)) || !((new RegExp('^(?:[01]\\d|2[0-3]):[0-5]\\d$', '')).test(endTimeString))) {
    return 'calculateDayDiffBetweenTimeStrings: Incorrect TimeStrings';
  }
  startHour = (Number((startTimeString.slice(0, 2))));
  endHour = (Number((endTimeString.slice(0, 2))));
  startMin = (Number((startTimeString.slice(3, 5))));
  endMin = (Number((endTimeString.slice(3, 5))));
  if (startHour == endHour) {
    if (startMin >= endMin) {
      return 1;
    } else {
      return 0;
    }
  }
  if (startHour > endHour) {
    return 1;
  } else {
    return 0;
  }

return 
}

})
define('./functions/3b3f85c9afed14257c2a26e3e2360d4c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3b3f85c9afed14257c2a26e3e2360d4c'] = function fn_3b3f85c9afed14257c2a26e3e2360d4c(orgType) {
var orgType;


  orgType = orgType;
  if (orgType == 'agencies') {
    return 'agency';
  } else if (orgType == 'clients') {
    return 'client';
  } else {
    return 'locum';
  }

return 
}

})
define('./functions/3bbd1fdf9c5fbee700cabdd36f5f2804/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3bbd1fdf9c5fbee700cabdd36f5f2804'] = async function fn_3bbd1fdf9c5fbee700cabdd36f5f2804(arr, propery, _groupName, _flattenGroupObj) {
return (await (async function(arr, property, groupKeyName, flattenGroupObject) {
	/**
	 * Groups objects in an array by a specified property and removes the redundant property from each item.
	 *
	 * @param {Array} arr - Array of objects to be grouped.
	 * @param {string} property - Property by which to group the objects.
	 * @param {string} [groupKeyName] - Optional name for the group key (default is "items").
	 * @param {boolean} [flattenGroupObject=false] - Whether to flatten the grouped property into the resulting object.
	 * @returns {Array} - Array of grouped objects, each containing the property and its corresponding group of items.
	 */

	    // Set default group key name if not provided
	    const keyName = groupKeyName || "items";

	    // Create a map to hold groups by the specified property
	    const propertyMap = new Map();

	    arr.forEach(item => {
	        // Handle grouping key based on objectId if property is an object
	        const key = typeof item[property] === "object" && item[property]?.objectId
	            ? item[property].objectId // Use objectId as the key
	            : item[property];         // Use property value directly otherwise

	        // If the key is not already in the map, add it
	        if (!propertyMap.has(key)) {
	            const groupObject = flattenGroupObject && typeof item[property] === "object"
	                ? { ...item[property] } // Spread the properties of the object into the group object
	                : { [property]: item[property] }; // Default structure

	            propertyMap.set(key, {
	                ...groupObject,       // Include the group properties
	                [keyName]: []         // Initialize empty group array
	            });
	        }

	        // Destructure to exclude the property from the item
	        const { [property]: _, ...itemWithoutProperty } = item;

	        // Push the modified item into the corresponding property's group array
	        propertyMap.get(key)[keyName].push(itemWithoutProperty);
	    });

	    // Convert the map values to an array and return
	    return Array.from(propertyMap.values());


})(arr, propery, _groupName, _flattenGroupObj))
}

})
define('./functions/3ed59821a80529e8b6475f3a89c3587d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3ed59821a80529e8b6475f3a89c3587d'] = async function fn_3ed59821a80529e8b6475f3a89c3587d(page, sortBy, filters) {
var pageCount, timesheets, pageSize, whereClause, timesheetCount, timesheet, page, sortby, approxEnd, approxStart, filters, timestamp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getStartOfDayTimestamp(timestamp) {
  return (await (async function(timestamp) {
	    // Create a new Date object from the input timestamp
	    const date = new Date(timestamp);

	    // Set the hours, minutes, seconds, and milliseconds to zero
	    date.setHours(0, 0, 0, 0);

	    // Return the timestamp in milliseconds
	    return date.getTime();
})(timestamp))
}

/**
 * Describe this function...
 */
async function getEndOfDayTimestamp(timestamp) {
  return (await (async function(timestamp) {
	    // Create a new Date object from the input timestamp
	    const date = new Date(timestamp);

	    // Set the hours, minutes, seconds, and milliseconds to the end of the day
	    date.setHours(23, 59, 59, 999);

	    // Return the timestamp in milliseconds
	    return date.getTime();
})(timestamp))
}


  pageSize = 100;
  filters = filters;
  if (filters) {
    if (!(await BackendlessUI.Functions.Custom['fn_8d4b531b860a4ba62584e6712dfb87eb']((getObjectProperty(filters, 'status'))))) {
      whereClause = String(' AND ') + String((await BackendlessUI.Functions.Custom['fn_064af7773cbb9bdb2ff71d23201cd648']('status', (getObjectProperty(filters, 'status')))));
    }
    if (getObjectProperty(filters, 'clientId')) {
      whereClause = [whereClause,' AND client.objectId = \'',(getObjectProperty(filters, 'clientId')),'\''].join('');
    }
    if (getObjectProperty(filters, 'locumId')) {
      whereClause = [whereClause,' AND locum.objectId = \'',(getObjectProperty(filters, 'locumId')),'\''].join('');
    }
    if (getObjectProperty(filters, 'filename')) {
      whereClause = [whereClause,' AND filename LIKE \'%',(getObjectProperty(filters, 'filename')),'%\''].join('');
    }
    if (getObjectProperty(filters, 'submittedAfter')) {
      whereClause = [whereClause,' AND created > ',await getStartOfDayTimestamp((getObjectProperty(filters, 'submittedAfter')))].join('');
    }
    if (getObjectProperty(filters, 'submittedBefore')) {
      whereClause = [whereClause,' AND created < ',await getEndOfDayTimestamp((getObjectProperty(filters, 'submittedBefore')))].join('');
    }
    if (getObjectProperty(filters, 'hasSessionsApprox')) {
      console.log('EXTRACT DATE & GIVE 6HR BUFFER EITHER SIDE');
      approxStart = await getStartOfDayTimestamp((getObjectProperty(filters, 'hasSessionsApprox'))) - 21600000;
      approxEnd = await getEndOfDayTimestamp((getObjectProperty(filters, 'hasSessionsApprox'))) + 21600000;
      whereClause = [whereClause,' AND sessions.actualStart > ',approxStart,' AND sessions.actualEnd < ',approxEnd].join('');
    }
    whereClause = whereClause.slice(5, whereClause.length);
  }
  if (!sortby) {
    sortby = 'created';
  }
  timesheets = (await Backendless.Data.of('timesheets').find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setRelationsDepth(1).setSortBy(sortby).setPageSize(pageSize).setOffset((page * pageSize))));
  for (var timesheet_index in timesheets) {
    timesheet = timesheets[timesheet_index];
    Object.assign(timesheet, ({ [`dataGrid`]: ({ [`locumName`]: (getObjectProperty(timesheet, 'locum.orgName')),[`clientName`]: (getObjectProperty(timesheet, 'client.orgName')),[`sessionsCount`]: (getObjectProperty(timesheet, 'sessions')).length,[`submitted`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(timesheet, 'created')))), 'isoDateString')),[`timesheetId`]: (getObjectProperty(timesheet, 'objectId')) }) }));
  }
  timesheetCount = timesheets.length;
  if (timesheetCount == pageSize) {
    pageCount = Math.ceil((await Backendless.Data.of('timesheets').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause(whereClause))) / pageSize);
  } else {
    pageCount = 1;
  }

return ({ [`timesheets`]: timesheets,[`pageCount`]: pageCount })
}

})
define('./functions/3f69fa33122800d9d1545a8a547afb0a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3f69fa33122800d9d1545a8a547afb0a'] = async function fn_3f69fa33122800d9d1545a8a547afb0a(AD, orgId, userId, whereClause, showArchive) {
var clients, myClients, item, client;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  clients = (await Backendless.Request.get(`${Backendless.appPath}/services/ORGS/v1/orgs`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'orgId': JSON.stringify(orgId),'tablename': JSON.stringify('clients'),'whereClause': JSON.stringify(whereClause),'showArchive': showArchive }).send());
  for (var client_index in clients) {
    client = clients[client_index];
    Object.assign(client, ({ [`value`]: (getObjectProperty(client, 'objectId')),[`label`]: [(getObjectProperty(client, 'orgName')),' (',(getObjectProperty(client, 'reference')),')'].join('') }));
  }
  myClients = (await asyncListFilter(clients, async (item) => {


   return ((getObjectProperty(item, 'accountOwner.objectId')) == userId);
  }));
  AD['clients'] = ({ [`user`]: myClients,[`all`]: clients });

return 
}

})
define('./functions/4432cb9baabf27e7955c6f348f7be508/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4432cb9baabf27e7955c6f348f7be508'] = async function fn_4432cb9baabf27e7955c6f348f7be508(PD, orgId) {
var whereClause, PD, orgId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgId = orgId;
  PD = PD;
  if (getObjectProperty(PD, 'ui_statusFilter')) {
    whereClause = String(' AND ') + String((await BackendlessUI.Functions.Custom['fn_064af7773cbb9bdb2ff71d23201cd648']('status', (getObjectProperty(PD, 'ui_statusFilter')))));
  }
  whereClause = [whereClause,' AND agency.objectId = \'',orgId,'\''].join('');
  if (getObjectProperty(PD, 'ui_clientFilter')) {
    whereClause = [whereClause,' AND client.objectId = \'',(getObjectProperty(PD, 'ui_clientFilter')),'\''].join('');
  }
  if (getObjectProperty(PD, 'ui_locumFilter')) {
    whereClause = [whereClause,' AND locum.objectId = \'',(getObjectProperty(PD, 'ui_locumFilter')),'\''].join('');
  }
  if (getObjectProperty(PD, 'ui_filenameFilter')) {
    whereClause = [whereClause,' AND filename LIKE \'%',(getObjectProperty(PD, 'ui_filenameFilter')),'%\''].join('');
  }
  whereClause = whereClause.slice(5, whereClause.length);
  console.log('abc');

return whereClause
}

})
define('./functions/465a168c4dbaac59e91987824a13cce3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_465a168c4dbaac59e91987824a13cce3'] = async function fn_465a168c4dbaac59e91987824a13cce3(array, property) {
return (await (async function(array, property) {
	// Helper function to get nested property value
	function getNestedProperty(obj, propertyPath) {
	  return propertyPath.split('.').reduce((acc, part) => acc && acc[part], obj);
	}

	// countUnique Function
	function countUnique(array, propertyPath) {
	  const uniqueValues = new Set();

	  array.forEach(item => {
	    const value = getNestedProperty(item, propertyPath);
	    if (value !== null && value !== undefined) {
	      uniqueValues.add(value);
	    }
	  });

	  return uniqueValues.size;
	}

	return countUnique(array, property);
})(array, property))
}

})
define('./functions/48a1a5fc2c6b5b463f468f6fc5b4c1d6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_48a1a5fc2c6b5b463f468f6fc5b4c1d6'] = async function fn_48a1a5fc2c6b5b463f468f6fc5b4c1d6(AD, orgId, userId, whereClause, showArchive) {
var locums, myLocums, item, locum;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  locums = (await Backendless.Request.get(`${Backendless.appPath}/services/ORGS/v1/orgs`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'orgId': JSON.stringify(orgId),'tablename': JSON.stringify('locums'),'whereClause': JSON.stringify(whereClause),'showArchive': showArchive }).send());
  for (var locum_index in locums) {
    locum = locums[locum_index];
    Object.assign(locum, ({ [`value`]: (getObjectProperty(locum, 'objectId')),[`label`]: [(getObjectProperty(locum, 'orgName')),' (',(getObjectProperty(locum, 'reference')),')'].join('') }));
  }
  myLocums = (await asyncListFilter(locums, async (item) => {


   return ((getObjectProperty(item, 'accountOwner.objectId')) == userId);
  }));
  AD['locums'] = ({ [`user`]: myLocums,[`all`]: locums,[`allWithNone`]: (addItemToList((await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](locums)), ({ [`label`]: '-- NONE --',[`value`]: 'NONE' }))) });

return 
}

})
define('./functions/4d418f750b624e699d6def8d73e65db4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4d418f750b624e699d6def8d73e65db4'] = async function fn_4d418f750b624e699d6def8d73e65db4(items, groupingProperty) {
return (await (async function(items, groupingProperty) {
	  const grouped = {};

	  items.forEach(item => {
	    const groupingObject = item[groupingProperty];
	    const groupingObjectId = groupingObject.objectId;

	    if (!grouped[groupingObjectId]) {
	      grouped[groupingObjectId] = {
	        [groupingProperty]: groupingObject,
	        items: []
	      };
	    }

	    grouped[groupingObjectId].items.push(item);
	  });

	  return Object.entries(grouped).map(([groupingObjectId, groupedObject]) => ({
	    ...groupedObject
	  }));
})(items, groupingProperty))
}

})
define('./functions/4efc42e29da30fb6e3ef1b6212d5cb4c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4efc42e29da30fb6e3ef1b6212d5cb4c'] = async function fn_4efc42e29da30fb6e3ef1b6212d5cb4c() {
return (await (async function() {
	const {
	  DateTime
	} = luxon;


	const now = DateTime.local().setZone('Europe/London');

	// Helper function to format date to start of day
	function startOfDay(dateTime) {
	    return dateTime.startOf('day').toMillis();
	}

	// Helper function to format date to end of day
	function endOfDay(dateTime) {
	    return dateTime.endOf('day').toMillis();
	}

	// Adjust the week to start on Monday instead of Sunday
	const dayOfWeek = now.weekday; // Luxon weekday (1 = Monday, ..., 7 = Sunday)
	const firstDayOfWeek = now.minus({ days: dayOfWeek - 1 }).startOf('day'); // Move to the previous Monday
	const lastDayOfWeek = firstDayOfWeek.plus({ days: 6 }).endOf('day');

	// Last week (starting from Monday)
	const firstDayOfLastWeek = firstDayOfWeek.minus({ days: 7 }).startOf('day');
	const lastDayOfLastWeek = lastDayOfWeek.minus({ days: 7 }).endOf('day');

	// This month
	const startOfMonth = now.startOf('month');
	const endOfMonth = now.endOf('month');

	// Last month
	const startOfLastMonth = startOfMonth.minus({ months: 1 }).startOf('month');
	const endOfLastMonth = startOfMonth.minus({ months: 1 }).endOf('month');

	const result = {
	    thisWeek: {
	        start: startOfDay(firstDayOfWeek),
	        end: endOfDay(lastDayOfWeek)
	    },
	    lastWeek: {
	        start: startOfDay(firstDayOfLastWeek),
	        end: endOfDay(lastDayOfLastWeek)
	    },
	    thisMonth: {
	        start: startOfDay(startOfMonth),
	        end: endOfDay(endOfMonth)
	    },
	    lastMonth: {
	        start: startOfDay(startOfLastMonth),
	        end: endOfDay(endOfLastMonth)
	    }
	};

	return result;
})())
}

})
define('./functions/4fec03ae65739f42bf2fd185b3dadf9f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f'] = async function fn_4fec03ae65739f42bf2fd185b3dadf9f(arr) {
return (await (async function(arr) {
	  return Array.isArray(arr) ? arr.length : 0;
})(arr))
}

})
define('./functions/5e91068a76772dc26bed68786e14880c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5e91068a76772dc26bed68786e14880c'] = async function fn_5e91068a76772dc26bed68786e14880c(PD, AD) {
var _RESULTS, _SHOW_ARCHIVE, _WHERE_CLAUSE, filterOptions, accountOwnerName, j, matchedOwner, accountOwnerId, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}

/**
 * Describe this function...
 */
async function enrich() {
  console.log('%c>> ' + 'Start Enriching', 'color: #f06292', undefined);
  for (var j_index in _RESULTS) {
    j = _RESULTS[j_index];
    j['locumId'] = (getObjectProperty(j, 'objectId'));
    j['name'] = (getObjectProperty(j, 'orgName'));
    accountOwnerId = (getObjectProperty(j, 'accountOwner.objectId'));
    if (accountOwnerId) {
      console.log('%c>> ' + 'Get Name', 'color: #4caf50', j);
      matchedOwner = (await asyncListFind((getObjectProperty(AD, 'currentOrg.users')), async (item) => {


       return ((getObjectProperty(item, 'objectId')) == accountOwnerId);
      }));
      accountOwnerName = null;
      if (matchedOwner) {
        accountOwnerName = (getObjectProperty(matchedOwner, 'name'));
        console.log('%c>> ' + 'Matched Name', 'color: #f06292', accountOwnerName);
      }
      j['owner'] = accountOwnerName;
    }
  }
}


  _SHOW_ARCHIVE = false;
  filterOptions = (getObjectProperty(PD, 'ui.filters'));
  if (filterOptions) {
    if (filterOptions.includes('showArchived')) {
      _SHOW_ARCHIVE = true;
    }
    if (filterOptions.includes('myLocums')) {
      _WHERE_CLAUSE = ['accountOwner.objectId = \'',(getObjectProperty(AD, 'currentUser.objectId')),'\''].join('');
    }
  }
  _RESULTS = (await Backendless.Request.get(`${Backendless.appPath}/services/ORGS/v1/orgs`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'orgId': JSON.stringify((getObjectProperty(AD, 'currentOrg.objectId'))),'tablename': JSON.stringify('locums'),'whereClause': JSON.stringify(_WHERE_CLAUSE),'showArchive': _SHOW_ARCHIVE }).send());
  await enrich();
  PD['orgs'] = _RESULTS;

return 
}

})
define('./functions/5f270786c02ad6d4dbb4af382cb9cfdf/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5f270786c02ad6d4dbb4af382cb9cfdf'] = async function fn_5f270786c02ad6d4dbb4af382cb9cfdf(obj1, obj2) {
return (await (async function(obj1, obj2) {
	function deepEqual(obj1, obj2) {
	  if (obj1 === obj2) {
	    return true;
	  }

	  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
	    return false;
	  }

	  let keys1 = Object.keys(obj1);
	  let keys2 = Object.keys(obj2);

	  if (keys1.length !== keys2.length) {
	    return false;
	  }

	  for (let key of keys1) {
	    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
	      return false;
	    }
	  }

	  return true;
	}

	return deepEqual(obj1, obj2);
})(obj1, obj2))
}

})
define('./functions/5f6f7f1806d336185ecbde944bc549a8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5f6f7f1806d336185ecbde944bc549a8'] = async function fn_5f6f7f1806d336185ecbde944bc549a8(inputValue, orgType, componentData, userOrgId) {
var options, orgName, item, org, results;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function locum() {
  options = [];
  results = (await Backendless.Data.of('locums').find(Backendless.DataQueryBuilder.create().setWhereClause((['(orgName LIKE \'%',inputValue,'%\' OR reference LIKE \'%',inputValue,'%\') AND agency.objectId = \'',userOrgId,'\''].join(''))).setPageSize(100)));
  for (var org_index in results) {
    org = results[org_index];
    addItemToList(options, ({ [`value`]: (getObjectProperty(org, 'objectId')),[`label`]: [(getObjectProperty(org, 'orgName')),' (',(getObjectProperty(org, 'reference')),')'].join('') }));
  }
  return options
}

/**
 * Describe this function...
 */
async function populateDropdown() {
  if (orgType == 'locums') {
    return (await locum());
  }
  if (orgType == 'clients') {
    return (await client());
  }
  if (orgType == 'agencies') {
    return (await agency());
  }
}

/**
 * Describe this function...
 */
async function client() {
  options = [];
  results = (await Backendless.Data.of('clients').find(Backendless.DataQueryBuilder.create().setWhereClause((['(orgName LIKE \'%',inputValue,'%\' OR reference LIKE \'%',inputValue,'%\') AND agency.objectId = \'',userOrgId,'\''].join(''))).setPageSize(100)));
  for (var org_index2 in results) {
    org = results[org_index2];
    addItemToList(options, ({ [`value`]: (getObjectProperty(org, 'objectId')),[`label`]: [(getObjectProperty(org, 'orgName')),' (',(getObjectProperty(org, 'reference')),')'].join('') }));
  }
  return options
}

/**
 * Describe this function...
 */
async function agency() {
  options = [];
  results = (await Backendless.Data.of('agencies').find(Backendless.DataQueryBuilder.create().setWhereClause((['(orgName LIKE \'%',inputValue,'%\' OR reference LIKE \'%',inputValue,'%\') AND agency.objectId = \'',userOrgId,'\''].join(''))).setPageSize(100)));
  for (var org_index3 in results) {
    org = results[org_index3];
    addItemToList(options, ({ [`value`]: (getObjectProperty(org, 'objectId')),[`label`]: [(getObjectProperty(org, 'orgName')),' (',(getObjectProperty(org, 'reference')),')'].join('') }));
  }
  return options
}


  stopSetTimeout('lazySearch');
  if ((new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$', '')).test(inputValue)) {
    await (async function(obj, retainedProperties) {
    	  if (!retainedProperties || retainedProperties.length === 0) {
    	    for (let key in obj) {
    	      if (obj.hasOwnProperty(key)) {
    	        delete obj[key];
    	      }
    	    }
    	  } else {
    	    const keysToRetain = new Set(retainedProperties);
    	    for (let key in obj) {
    	      if (obj.hasOwnProperty(key) && !keysToRetain.has(key)) {
    	        delete obj[key];
    	      }
    	    }
    	  }
    })(componentData, ['___ui']);
    orgName = (getObjectProperty((await asyncListFind((getObjectProperty(componentData, '___ui.options')), async (item) => {


     return ((getObjectProperty(item, 'value')) == inputValue);
    })), 'label'));
    Object.assign(componentData, ({ [`objectId`]: inputValue,[`orgName`]: orgName }));
  } else if (!inputValue) {
  } else if (inputValue == 'DELETE') {
    Object.assign(componentData, ({ [`objectId`]: 'DELETE',[`orgName`]: '-- UNASSIGNED --' }));
  } else {

    ;(function() {
      const callback = async () => {
          options = await populateDropdown();
      console.log(options);
      if (!!options.length) {
        await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](componentData, '___ui.options', options);
      }

      };

      const timerId = 'lazySearch';
      const timerDelay = 500;

      runSetTimeout(timerId, callback, timerDelay)
    })()
  }

return 
}

})
define('./functions/635be40e3abc515816711a224dd834dd/code.js', () => { 

BackendlessUI.Functions.Custom['fn_635be40e3abc515816711a224dd834dd'] = async function fn_635be40e3abc515816711a224dd834dd(variable) {
return (await (async function(variable) {
	// Check if the variable is null or undefined, return null if true
	if (variable === null || variable === undefined) {
	    return null;
	}

	// Deep copy the variable
	const copiedVariable = JSON.parse(JSON.stringify(variable));

	return copiedVariable;

})(variable))
}

})
define('./functions/666d976d080af841315daaf37fbb8b97/code.js', () => { 

BackendlessUI.Functions.Custom['fn_666d976d080af841315daaf37fbb8b97'] = async function fn_666d976d080af841315daaf37fbb8b97(idArray, dataArray) {
return (await (async function(idArray, dataArray) {
	/**
	* Filters objects from dataArray based on the presence of their ids in idArray.
	*
	* @param {string[]} idArray - Array of string ids to filter by.
	* @param {Object[]} dataArray - Array of objects to filter.
	* @returns {Object[]} - Filtered array of objects whose ids are in idArray.
	*/

	// Create a set for faster lookup of ids
	const idSet = new Set(idArray);

	// Filter dataArray to include only objects with ids in idArray
	const filteredArray = dataArray.filter(obj => idSet.has(obj.objectId));

	return filteredArray;
})(idArray, dataArray))
}

})
define('./functions/694a8ce30fe66fc117ec880d68c8c5c8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_694a8ce30fe66fc117ec880d68c8c5c8'] = async function fn_694a8ce30fe66fc117ec880d68c8c5c8(data, headers) {
  await (async function(data, headers) {

  	    function getNestedValue(obj, path) {
  	        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  	    }

  	    // Create CSV content
  	    const csvContent = [];

  	    // Add CSV header row
  	    csvContent.push(headers.map(header => header.columnName).join(','));

  	    // Add CSV data rows
  	    data.forEach(item => {
  	        const row = headers.map(header => {
  	            const value = getNestedValue(item, header.property);
  	            return typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value;
  	        }).join(',');
  	        csvContent.push(row);
  	    });

  	    // Create Blob from CSV content
  	    const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });

  	    // Create a link element and trigger a download
  	    const link = document.createElement('a');
  	    link.href = URL.createObjectURL(csvBlob);
  	    link.download = 'data.csv';
  	    document.body.appendChild(link);
  	    link.click();
  	    document.body.removeChild(link);


  })(data, headers);

return 
}

})
define('./functions/6ae1d56914c71148f9362167743ced54/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6ae1d56914c71148f9362167743ced54'] = async function fn_6ae1d56914c71148f9362167743ced54(PD, AD) {
var sessions, blankCalendar, calendar, j, whereClause, nonOwned, clientFilter, locumFilter, lastDay, firstDay, year, month;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](PD, 'ui.calendarLoading', true);
  month = (getObjectProperty(PD, 'ui.month'));
  year = (getObjectProperty(PD, 'ui.year'));
  blankCalendar = (await BackendlessUI.Functions.Custom['fn_6fb689c132231c2582b76bf54ca8d848'](month, year));
  firstDay = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae']((getObjectProperty(blankCalendar, '1_1.date')), '00:00'));
  lastDay = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae']((getObjectProperty(blankCalendar, '6_7.date')), '23:59'));
  whereClause = ['start > ',firstDay,' AND end <',lastDay,' AND status <> \'CANCELLED\''].join('');
  locumFilter = (getObjectProperty(PD, 'ui.locumFilter.objectId'));
  if (locumFilter) {
    if (locumFilter == 'DELETE') {
      whereClause = String(whereClause) + String(' AND locum IS NULL');
    } else {
      whereClause = [whereClause,' AND locum.objectId = \'',locumFilter,'\''].join('');
    }
  }
  clientFilter = (getObjectProperty(PD, 'ui.clientFilter.objectId'));
  if (clientFilter) {
    whereClause = [whereClause,' AND client.objectId = \'',clientFilter,'\''].join('');
  }
  nonOwned = (getObjectProperty(PD, 'ui.nonOwned'));
  if (!nonOwned) {
    whereClause = [whereClause,' AND client.accountOwner.objectId = \'',(getObjectProperty(AD, 'currentUser.objectId')),'\''].join('');
  }
  sessions = (await Backendless.Request.get(`${Backendless.appPath}/services/SESSIONS/v1/sessions`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'orgId': JSON.stringify((getObjectProperty(AD, 'currentOrg.objectId'))),'whereClause': JSON.stringify(whereClause) }).send());
  for (var j_index in sessions) {
    j = sessions[j_index];
    await BackendlessUI.Functions.Custom['fn_8564e643897ab40ff5d31e1473139bce'](j);
  }
  calendar = (await BackendlessUI.Functions.Custom['fn_0d6ab7518735beb79ac73b951ae997e1']((await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](blankCalendar)), sessions));
  PD['calendar'] = calendar;
  PD['blankCalendar'] = blankCalendar;
  PD['sessions'] = sessions;
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](PD, 'ui.calendarLoading', false);

return 
}

})
define('./functions/6b8f6cc13106b28efabd511df5058732/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6b8f6cc13106b28efabd511df5058732'] = async function fn_6b8f6cc13106b28efabd511df5058732(filters) {
return (await (async function(filters) {

	  const conditions = [];

	  for (const column in filters) {
	    const filter = filters[column];

	    if (filter.conditions) {
	      const groupConditions = filter.conditions.map(cond =>
	        getCondition(cond, column)
	      );
	      conditions.push(`(${groupConditions.join(` ${filter.operator} `)})`);
	    } else {
	      conditions.push(getCondition(filter, column));
	    }
	  }

	  return conditions.length ? `${conditions.join(' AND ')}` : '';


	function getCondition(filter, column) {
	  const { type, filter: value, filterType } = filter;

	  // Common null checks for both types
	  if (type === 'blank') return `${column} IS NULL`;
	  if (type === 'notBlank') return `${column} IS NOT NULL`;

	  if (filterType === 'number') {
	    // Handle number-specific conditions
	    if (type === 'inRange') {
	      if (typeof filter.filter === 'undefined' || typeof filter.filterTo === 'undefined') {
	        throw new Error('Both "filter" and "filterTo" are required for inRange');
	      }
	      const from = Number(filter.filter);
	      const to = Number(filter.filterTo);
	      if (isNaN(from) || isNaN(to)) {
	        throw new Error('Invalid number values for inRange');
	      }
	      return `${column} BETWEEN ${from} AND ${to}`;
	    }

	    // Validate number value
	    if (typeof value === 'undefined' || value === null) {
	      throw new Error(`Missing filter value for ${type} condition`);
	    }
	    const numValue = Number(value);
	    if (isNaN(numValue)) {
	      throw new Error(`Invalid number value: ${value}`);
	    }

	    switch(type) {
	      case 'equals': return `${column} = ${numValue}`;
	      case 'notEqual': return `${column} != ${numValue}`;
	      case 'greaterThan': return `${column} > ${numValue}`;
	      case 'greaterThanOrEqual': return `${column} >= ${numValue}`;
	      case 'lessThan': return `${column} < ${numValue}`;
	      case 'lessThanOrEqual': return `${column} <= ${numValue}`;
	      default: throw new Error(`Unsupported number condition: ${type}`);
	    }
	  }
	  else if (filterType === 'text') {
	    // Handle text-specific conditions
	    if (typeof value === 'undefined' || value === null) {
	      throw new Error(`Missing filter value for ${type} condition`);
	    }

	    const escapedValue = String(value).replace(/'/g, "''");

	    switch(type) {
	      case 'contains': return `${column} LIKE '%${escapedValue}%'`;
	      case 'notContains': return `${column} NOT LIKE '%${escapedValue}%'`;
	      case 'equals': return `${column} = '${escapedValue}'`;
	      case 'notEqual': return `${column} != '${escapedValue}'`;
	      case 'startsWith': return `${column} LIKE '${escapedValue}%'`;
	      case 'endsWith': return `${column} LIKE '%${escapedValue}'`;
	      default: throw new Error(`Unsupported text condition: ${type}`);
	    }
	  }
	  else {
	    throw new Error(`Unsupported filter type: ${filterType}`);
	  }
	}

})(filters))
}

})
define('./functions/6c0c08444022d09eeab341060619eb12/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'] = async function fn_6c0c08444022d09eeab341060619eb12(timestamp) {
return (await (async function(timestamp) {
	/** ----- PRE-OPTIMISATION ----- */

	// const { DateTime } = luxon;

	// // Define UK timezone
	// const ukTimezone = 'Europe/London';

	// if (timestamp == null) { // Handles both null and undefined
	//   return {
	//     weekday: null,
	//     dateString: null,
	//     timeString: null,
	//     isoDateString: null,
	//     shortDateString: null,
	//     abbreviatedDateTime: null, // Adding the new field
	//     abbreviatedTime: null      // Adding the new field
	//   };
	// }

	// // Create DateTime object from timestamp
	// const dt = DateTime.fromMillis(timestamp, {
	//   zone: ukTimezone
	// });

	// // Format the properties
	// const weekday = dt.toFormat('ccc'); // Short weekday name (e.g., Wed)
	// const dateString = dt.toFormat('dd/MM/yyyy'); // Date string (e.g., 27/05/2024)
	// const timeString = dt.toFormat('HH:mm'); // Time string in 24hr format (e.g., 14:30)
	// const isoDateString = dt.toISODate(); // ISO date string (e.g., 2024-05-27)
	// const shortDateString = dt.toFormat('dd/MM/yy'); // Short date string (e.g., 27/05/24)

	// // Format the new properties
	// const abbreviatedTime = dt.toFormat('h:mma').toLowerCase().replace('m', ''); // 9:30a or 9:30p
	// const abbreviatedDateTime = `${dt.toFormat('dd/MM')} ${abbreviatedTime}`; // 10/04 9:30a

	// // Return the result object
	// return {
	//   weekday,
	//   dateString,
	//   timeString,
	//   isoDateString,
	//   shortDateString,
	//   abbreviatedDateTime, // Including the new field in the return object
	//   abbreviatedTime      // Including the new field in the return object
	// };


	const { DateTime } = luxon;

	// Define UK timezone
	const ukTimezone = 'Europe/London';


	  if (timestamp == null) { // Handles both null and undefined
	    return {
	      weekday: null,
	      dateString: null,
	      timeString: null,
	      isoDateString: null,
	      shortDateString: null,
	      abbreviatedDateTime: null,
	      abbreviatedTime: null
	    };
	  }

	  // Create DateTime object from timestamp
	  const dt = DateTime.fromMillis(timestamp, { zone: ukTimezone });

	  // Use built-in properties to minimize toFormat calls
	  const weekday = dt.weekdayShort; // e.g., 'Wed'

	  // Precompute padded day and month
	  const day = String(dt.day).padStart(2, '0');
	  const month = String(dt.month).padStart(2, '0');
	  const year = dt.year;

	  // Construct date strings manually
	  const dateString = `${day}/${month}/${year}`;
	  const shortYear = String(dt.year).slice(-2);
	  const shortDateString = `${day}/${month}/${shortYear}`;

	  // Use built-in properties for time
	  const hours = String(dt.hour).padStart(2, '0');
	  const minutes = String(dt.minute).padStart(2, '0');
	  const timeString = `${hours}:${minutes}`;

	  // ISO date string using built-in method
	  const isoDateString = dt.toISODate();

	  // Abbreviated time without string manipulation
	  const hour12 = dt.hour % 12 || 12;
	  const meridiem = dt.hour < 12 ? 'a' : 'p';
	  const abbreviatedTime = `${hour12}:${minutes}${meridiem}`;

	  // Abbreviated date-time
	  const abbreviatedDateTime = `${day}/${month} ${abbreviatedTime}`;

	  // Return the result object
	  return {
	    weekday,
	    dateString,
	    timeString,
	    isoDateString,
	    shortDateString,
	    abbreviatedDateTime,
	    abbreviatedTime
	  };

})(timestamp))
}

})
define('./functions/6fb689c132231c2582b76bf54ca8d848/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6fb689c132231c2582b76bf54ca8d848'] = async function fn_6fb689c132231c2582b76bf54ca8d848(month, year) {
return (await (async function(month, year) {
	// Array of month names for converting month numbers to strings
	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	// Initialize the date to the first of the given month and year
	const startDate = new Date(year, month - 1, 1);

	// Adjust the start date to the first Monday of the week, potentially in the previous month
	const startDay = startDate.getDay();
	const offset = startDay === 0 ? -6 : 1 - startDay; // Calculate the offset to reach Monday
	const firstMonday = new Date(year, month - 1, startDate.getDate() + offset);

	// Initialize the calendar object
	const calendar = {};

	// Initialize variables for looping through days
	let currentWeek = 1;
	let currentDate = new Date(firstMonday);

	// Generate data for 6 weeks
	for (let I = 0; I < 6 * 7; I++) { // 6 weeks * 7 days
	    // Calculate week and day for the cell
	    const weekDay = (I % 7) + 1; // Day of the week (1-7 for Monday to Sunday)
	    const cell = `${currentWeek}_${weekDay}`;

	    // Format the date manually to avoid timezone issues
	    const yearStr = currentDate.getFullYear();
	    const monthStr = String(currentDate.getMonth() + 1).padStart(2, '0');
	    const dayStr = String(currentDate.getDate()).padStart(2, '0');
	    const isoDate = `${yearStr}-${monthStr}-${dayStr}`;

	    const day = currentDate.getDate();
	    const monthName = monthNames[currentDate.getMonth()];

	    // Prepare the data object
	    const cellData = {
	        date: isoDate,
	        day: day,
	        month: monthName,
	        year: yearStr,
	        isThisMonth: currentDate.getMonth() === (month - 1) // Check if the date belongs to the specified month
	    };

	    // Set isNewMonth if it's the first day of the month
	    if (day === 1) {
	        cellData.isNewMonth = true;
	    }

	    // Set isNewYear if it's the first day of the year
	    if (day === 1 && currentDate.getMonth() === 0) {
	        cellData.isNewYear = true;
	    }

	    // Assign the object with the specified structure to the calendar object
	    calendar[cell] = cellData;

	    // Move to the next day
	    currentDate.setDate(currentDate.getDate() + 1);

	    // Update the week number after each Sunday
	    if (weekDay === 7) {
	        currentWeek++;
	    }
	}

	// Return the calendar object
	return calendar;
})(month, year))
}

})
define('./functions/720e14fd43b2fb292b36238f0fd362ae/code.js', () => { 

BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'] = async function fn_720e14fd43b2fb292b36238f0fd362ae(dateString, timeString) {
var dateTime;


  dateTime = (await (async function(dateString, timeString) {
  	    const { DateTime } = luxon;

  	    // Initialize variables for date parts and format
  	    let formatString = 'dd/MM/yyyy HH:mm';

  	    // Check and adjust the date format based on input patterns
  	    if (dateString.includes('/')) {  // DD/MM/YYYY or DD/MM/YY
  	        const dateParts = dateString.split('/');
  	        if (dateParts[2].length === 2) {
  	            dateParts[2] = '20' + dateParts[2];  // Assuming the year is in the 2000s
  	            dateString = dateParts.join('/');
  	        }
  	        formatString = `${dateParts[2].length === 4 ? 'dd/MM/yyyy' : 'dd/MM/yy'} HH:mm`;
  	    } else if (dateString.includes(' ')) {  // "Tue Apr 16 2024" format
  	        // Split the string and reassemble without the day name
  	        const parts = dateString.split(' ');
  	        dateString = parts.slice(1).join(' ');  // Remove the weekday part
  	        formatString = 'MMM dd yyyy HH:mm';  // Update format to match "Apr 16 2024 HH:mm"
  	    } else if (dateString.includes('-')) {  // ISO format "2024-05-26"
  	        formatString = 'yyyy-MM-dd HH:mm';  // Update format to match "2024-05-26 HH:mm"
  	    }

  	    // Combine the adjusted date string with the time string
  	    const combinedDateTimeString = `${dateString} ${timeString}`;

  	    // Parse the combined string into a DateTime object with the specified format
  	    // and assume it's in the 'Europe/London' timezone
  	    const dateTime = DateTime.fromFormat(combinedDateTimeString, formatString, {
  	        zone: 'Europe/London'
  	    });

  	    // Check if the date is valid, handle invalid dates
  	    if (dateTime.isValid) {
  	        // Return the DateTime object as a JavaScript Date object
  	        return dateTime.toJSDate();
  	    } else {
  	        throw new Error('Invalid date format or values provided.');
  	    }
  })(dateString, timeString));
  dateTime = (dateTime.valueOf());

return dateTime
}

})
define('./functions/72681b18dd67520305c5871579253389/code.js', () => { 

BackendlessUI.Functions.Custom['fn_72681b18dd67520305c5871579253389'] = async function fn_72681b18dd67520305c5871579253389(dateString, daysToAdd) {
return (await (async function(dateString, daysToAdd) {
	/**
	* dateString format YYYY-MM-DD
	*
	* returns a new date string also in YYYY-MM-DD format
	*/

	// Parse the input date string
	const [year, month, day] = dateString.split('-').map(Number);

	// Create a new Date object
	const date = new Date(year, month - 1, day);

	// Add the specified number of days
	date.setDate(date.getDate() + daysToAdd);

	// Format the new date as YYYY-MM-DD
	const newYear = date.getFullYear();
	const newMonth = String(date.getMonth() + 1).padStart(2, '0');
	const newDay = String(date.getDate()).padStart(2, '0');

	// Return the new date string
	return `${newYear}-${newMonth}-${newDay}`;
})(dateString, daysToAdd))
}

})
define('./functions/76071a552afc670aed4da85907180bbc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_76071a552afc670aed4da85907180bbc'] = async function fn_76071a552afc670aed4da85907180bbc(obj1, obj2) {
return (await (async function(obj1, obj2) {
	if (obj1 === obj2) {
	  return false; // No difference
	}

	if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
	  return true; // Primitive values or one of them is null
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
	  return true; // Different number of keys
	}

	for (const key of keys1) {
	  if (!keys2.includes(key)) {
	    return true; // Key missing in obj2
	  }

	  const val1 = obj1[key];
	  const val2 = obj2[key];

	  if (Array.isArray(val1) && Array.isArray(val2)) {
	    if (val1.length !== val2.length || val1.some((item, index) => deepCompare(item, val2[index]))) {
	      return true; // Difference in arrays
	    }
	  } else if (typeof val1 === 'object' && typeof val2 === 'object' && val1 !== null && val2 !== null) {
	    if (deepCompare(val1, val2)) {
	      return true; // Difference in nested objects
	    }
	  } else if (val1 !== val2) {
	    return true; // Difference in primitive values
	  }
	}

	return false;
})(obj1, obj2))
}

})
define('./functions/79618ef2c5230b4959bec167239763fa/code.js', () => { 

BackendlessUI.Functions.Custom['fn_79618ef2c5230b4959bec167239763fa'] = async function fn_79618ef2c5230b4959bec167239763fa(orgId, PD) {
var timesheets, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  timesheets = (await Backendless.Data.of('timesheets').find(Backendless.DataQueryBuilder.create().setWhereClause((String('status = \'APPROVED\' AND processor <> \'DIRECT\'') + String(' AND client.hpsId <> NULL AND locum.hpsId <> NULL'))).setRelationsDepth(1).setSortBy('created').setPageSize(100)));
  for (var j_index in timesheets) {
    j = timesheets[j_index];
    Object.assign(j, ({ [`dataGrid`]: ({ [`timesheetId`]: (getObjectProperty(j, 'objectId')),[`submitted`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'created')))), 'isoDateString')),[`locumId`]: (getObjectProperty(j, 'locum.objectId')),[`clientId`]: (getObjectProperty(j, 'client.objectId')),[`locumName`]: (getObjectProperty(j, 'locum.orgName')),[`clientName`]: (getObjectProperty(j, 'client.orgName')),[`sessionCount`]: (getObjectProperty(j, 'sessions')).length }) }));
  }
  PD['timesheets'] = timesheets;

return 
}

})
define('./functions/7b9ca5fce7d3d4a7696a4c04b85e0fc2/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7b9ca5fce7d3d4a7696a4c04b85e0fc2'] = async function fn_7b9ca5fce7d3d4a7696a4c04b85e0fc2(obj, nestedPath, value) {
  await (async function(obj, nestedPath, value) {
  	/**
  	 * Sets a deeply nested value within an object, creating intermediate objects as needed.
  	 *
  	 * @param {Object} obj - The base object on which to set the value.
  	 * @param {String} nestedPath - A dot-separated string indicating the path where the value should be set.
  	 * @param {*} value - The value to set at the target location.
  	 *
  	 * The function first splits `nestedPath` into an array of strings representing each property in the path.
  	 * It then iterates through these properties, ensuring each exists and is an object. If a property in the path
  	 * does not exist or is not an object, it is initialized as an empty object. This process continues until the
  	 * script reaches the second-to-last property in the path.
  	 *
  	 * Finally, the function sets `value` on the final property in the path, ensuring that the deeply nested
  	 * structure is updated as intended. This utility is helpful for dynamically generating nested structures
  	 * without manually verifying and creating each level of the hierarchy.
  	 *
  	 * Example Usage:
  	 * let obj = {};
  	 * setNestedValue(obj, 'a.b.c', 4);
  	 * // obj is now { a: { b: { c: 4 } } }
  	 */

  	const properties = nestedPath.split('.');
  	let current = obj;

  	for (let i = 0; i < properties.length - 1; i++) {
  	  const property = properties[i];

  	  // If the property doesn't exist, or is not an object, create it
  	  if (!current[property] || typeof current[property] !== 'object') {
  	    current[property] = {};
  	  }

  	  current = current[property];
  	}

  	// Set the value for the final property
  	current[properties[properties.length - 1]] = value;

  })(obj, nestedPath, value);

return 
}

})
define('./functions/83d5a6879d5ab7634fa6d5b1feeae15c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_83d5a6879d5ab7634fa6d5b1feeae15c'] = async function fn_83d5a6879d5ab7634fa6d5b1feeae15c(client, agency, userBody, sessions) {
return (await (async function(client, agency, userBody, sessions) {
	// Custom function : clientEmailGeneration

	    function sanitize(value) {
	        return value == null ? '' : value;
	    }

	    let emailHTML = `
	    <!DOCTYPE html>
	    <html lang="en">
	    <head>
	        <meta charset="UTF-8">
	        <meta name="viewport" content="width=device-width, initial-scale=1.0">
	        <style>
	            body {
	                font-family: Arial, sans-serif;
	            }
	            .container {
	                margin: 0 auto;
	                padding: 20px;
	                max-width: 600px;
	                border: 1px solid #ddd;
	                border-radius: 8px;
	            }
	            .header {
	                margin-bottom: 20px;
	            }
	            .header p {
	                margin: 0;
	                font-size: 16px;
	                line-height: 1.5;
	            }
	            .table-container {
	                margin-top: 20px;
	            }
	            table {
	                width: 100%;
	                border-collapse: collapse;
	                margin-bottom: 20px;
	            }
	            th, td {
	                padding: 10px;
	                border: 1px solid #ddd;
	                text-align: left;
	            }
	            th {
	                background-color: #f4f4f4;
	            }
	        </style>
	        <title>Email Template</title>
	    </head>
	    <body>
	        <div class="container">
	            <div class="header">
	                <p><strong>Client:</strong> ${sanitize(client)}</p>
	                <p><strong>Agency:</strong> ${sanitize(agency)}</p>
	                <p>${sanitize(userBody)}</p>
	            </div>
	            <div class="table-container">
	                <h2>Sessions</h2>
	                <table>
	                    <thead>
	                        <tr>
	                            <th>Status</th>
	                            <th>Date</th>
	                            <th>Start</th>
	                            <th>End</th>
	                            <th>Locum</th>
	                            <th>Rate</th>
	                            <th>Notes</th>
	                        </tr>
	                    </thead>
	                    <tbody>`;

	    sessions.forEach(session => {
	        emailHTML += `
	                        <tr>
	                            <td>${sanitize(session.confirmationStatusClient)}</td>
	                            <td>${sanitize(session.date)}</td>
	                            <td>${sanitize(session.dates.start.time)}</td>
	                            <td>${sanitize(session.dates.end.time)}</td>
	                            <td>${sanitize(session.locum.orgName)}</td>
	                            <td>${sanitize(session.rateClient)}</td>
	                            <td>${sanitize(session.notesSession)}</td>
	                        </tr>`;
	    });

	    emailHTML += `
	                    </tbody>
	                </table>
	            </div>
	        </div>
	    </body>
	    </html>`;

	    return emailHTML;

})(client, agency, userBody, sessions))
}

})
define('./functions/8475b3f34a83206b9e192b82530e7d2b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8475b3f34a83206b9e192b82530e7d2b'] = async function fn_8475b3f34a83206b9e192b82530e7d2b(timestamp) {
return (await (async function(timestamp) {
	/**
	 * Returns a UK-formatted date and time string in the format DD/MM hh:mma.
	 *
	 * @param {number|null|undefined} timestamp - The Unix timestamp in milliseconds.
	 * @returns {string|null} The formatted date-time or null if timestamp is invalid.
	 */

	  if (timestamp == null) {
	    return null; // Handle null or undefined inputs immediately
	  }

	  // Create a new formatter each time. While not as optimal as caching, this remains concise and clear.
	  const formatter = new Intl.DateTimeFormat('en-GB', {
	    timeZone: 'Europe/London',
	    day: '2-digit',
	    month: '2-digit',
	    hour: 'numeric',
	    minute: '2-digit',
	    hour12: true
	  });

	  const dt = new Date(timestamp);
	  const parts = formatter.formatToParts(dt);

	  let day, month, hour, minute, dayPeriod;

	  // Extract required parts
	  for (const part of parts) {
	    switch (part.type) {
	      case 'day':
	        day = part.value;    // Already two-digit
	        break;
	      case 'month':
	        month = part.value;  // Already two-digit
	        break;
	      case 'hour':
	        hour = part.value.padStart(2, '0'); // Ensure two-digit hour
	        break;
	      case 'minute':
	        minute = part.value; // Already two-digit
	        break;
	      case 'dayPeriod':
	        dayPeriod = part.value.toLowerCase(); // 'am' or 'pm'
	        break;
	      default:
	        // Ignore other parts
	        break;
	    }
	  }

	  // Construct final format: DD/MM hh:mma
	  return `${day}/${month} ${hour}:${minute}${dayPeriod}`;
})(timestamp))
}

})
define('./functions/8564e643897ab40ff5d31e1473139bce/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8564e643897ab40ff5d31e1473139bce'] = async function fn_8564e643897ab40ff5d31e1473139bce(session) {
var session, end, start;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  session = session;
  start = (getObjectProperty(session, 'start'));
  end = (getObjectProperty(session, 'end'));
  Object.assign(session, ({ [`dates`]: ({ [`start`]: ({ [`date`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](start)), 'shortDateString')),[`time`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](start)), 'timeString')) }),[`end`]: ({ [`date`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](end)), 'shortDateString')),[`time`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](end)), 'timeString')) }) }),[`duration`]: (getObjectProperty(session, 'duration')) ? (getObjectProperty(session, 'duration')) : (await BackendlessUI.Functions.Custom['fn_b74c8eb6ac2467ea66e7b09125c4b966'](start, end, (getObjectProperty(session, 'break')))),[`date`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](start)), 'isoDateString')),[`dataGrid`]: ({ [`sessionId`]: (getObjectProperty(session, 'objectId')),[`startTime`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](start)), 'timeString')),[`endTime`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](end)), 'timeString')),[`clientName`]: (getObjectProperty(session, 'client.orgName')),[`locumName`]: (getObjectProperty(session, 'locum.orgName')) }) }));
  if (!(getObjectProperty(session, 'client.objectId'))) {
    session['locum'] = ({ [`objectId`]: undefined });
  }
  if (!(getObjectProperty(session, 'locum.objectId'))) {
    session['locum'] = ({ [`objectId`]: undefined });
  }

return session
}

})
define('./functions/88457bb202285b8ddf4845cdd7fb729f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_88457bb202285b8ddf4845cdd7fb729f'] = async function fn_88457bb202285b8ddf4845cdd7fb729f(name) {
return (await (async function(name) {
	if (!name) return null;
	let parts = name.split(' ');
	if (parts.length === 1) return name;
	let abbreviated = parts[0][0]; // First letter of the first name
	for (let i = 1; i < parts.length - 1; i++) {
	  abbreviated += ' ' + parts[i][0]; // Add the first letter of each middle name
	}
	abbreviated += ' ' + parts[parts.length - 1]; // Add the last name
	return abbreviated;
})(name))
}

})
define('./functions/8b9f564fdda88d8b74ab06d3e55b7ae4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8b9f564fdda88d8b74ab06d3e55b7ae4'] = async function fn_8b9f564fdda88d8b74ab06d3e55b7ae4(sessions) {
var sessions, session, start, end;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessions = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](sessions));
  for (var session_index in sessions) {
    session = sessions[session_index];
    if (!(getObjectProperty(session, 'actualStart'))) {
      start = (getObjectProperty(session, 'start'));
      end = (getObjectProperty(session, 'end'));
      Object.assign(session, ({ [`actualStart`]: start,[`actualEnd`]: end,[`actualBreak`]: (getObjectProperty(session, 'break')),[`actualDuration`]: (end - start) / 60000 }));
    }
    if (!(getObjectProperty(session, 'dates.actualStart'))) {
      Object.assign(session, ({ [`actualStartString`]: (await BackendlessUI.Functions.Custom['fn_8475b3f34a83206b9e192b82530e7d2b']((getObjectProperty(session, 'actualStart')))),[`actualEndString`]: (await BackendlessUI.Functions.Custom['fn_8475b3f34a83206b9e192b82530e7d2b']((getObjectProperty(session, 'actualEnd')))) }));
    }
  }

return sessions
}

})
define('./functions/8d4b531b860a4ba62584e6712dfb87eb/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8d4b531b860a4ba62584e6712dfb87eb'] = async function fn_8d4b531b860a4ba62584e6712dfb87eb(arr) {
return (await (async function(arr) {
	return !Array.isArray(arr) || arr.length === 0;
})(arr))
}

})
define('./functions/91df63ee70a7346b083e81ead2a93b36/code.js', () => { 

BackendlessUI.Functions.Custom['fn_91df63ee70a7346b083e81ead2a93b36'] = async function fn_91df63ee70a7346b083e81ead2a93b36(session, AD) {
var item, processor;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}


  processor = (await asyncListFind((getObjectProperty(AD, 'processors')), async (item) => {


   return ((getObjectProperty(item, 'processor')) == (getObjectProperty(session, 'processor')));
  }));
  if (processor) {
    return (await (async function(session, processorRate, vatRate) {
    	function roundMoney(num) {
    	  return Math.round(num * 100)/100;
    	}

    	let breakInMinutes = session.break || 0;
    	if (isNaN(breakInMinutes)) {
    	    breakInMinutes = 0;
    	}

    	let duration = ((session.end - session.start) / (1000 * 60 * 60)) - (breakInMinutes / 60); // duration calculated in hours
    	console.log('Duration:', duration);

    	// Check if any critical variable is null or undefined, return 0
    	if (duration == null || session.rateClient == null || session.rateLocum == null || processorRate == null || vatRate == null) {
    	  // console.log('One or more critical variables are null or undefined.');
    	  return 0;
    	}

    	// Default to 0 if extraClient or extraLocum are null or undefined
    	let extraClient = session.extraClient ?? 0;
    	let extraLocum = session.extraLocum ?? 0;
    	// console.log('Extra Client:', extraClient);
    	// console.log('Extra Locum:', extraLocum);

    	let costClient = roundMoney((session.rateClient * duration) + extraClient);
    	let netLocum = roundMoney((session.rateLocum * duration) + extraLocum); // What locum gets BEFORE adding processor fees
    	let costLocum = roundMoney(netLocum / (1 - processorRate)); // Total locum cost AFTER adding back processor fees

    	let processorFee = roundMoney(costClient * processorRate); // Processor fee on full client amount
    	let processorFeeLocum = processorRate <= 0 ? 0 : netLocum / ((1 / processorRate) - 1); // Locum's portion of processor fee. Will return zero if processor fee is < 0%
    	let costAgency = roundMoney(costClient - costLocum);
    	let vat = costAgency < 0 ? 0 : roundMoney(costAgency / (1 + (1 / vatRate))); // if Margin is zero, set VAT to zero

    	// console.log('Rate Client:', session.rateClient);
    	// console.log('Rate Locum:', session.rateLocum);
    	// console.log('Processor Rate:', processorRate);
    	// console.log('VAT Rate:', vatRate);
    	// console.log('Cost Client:', costClient);
    	// console.log('Cost Locum:', costLocum);
    	// console.log('Cost Agency:', costAgency);
    	// console.log('Processor Fee:', processorFee);
    	// console.log('VAT:', vat);

    	let margin = roundMoney(costClient - netLocum - processorFee - vat);
    	let hourlyMargin = roundMoney(margin/duration);
    	// console.log('Result:', result);

    	return {
    	  'margin': margin,
    	  'hourlyMargin': hourlyMargin,
    	  'processorFee': processorFee,
    	  'processorFeeLocum' : processorFeeLocum,
    	  'vat': vat
    	};
    })(session, (getObjectProperty((await asyncListFind((getObjectProperty(AD, 'processors')), async (item) => {


     return ((getObjectProperty(item, 'processor')) == (getObjectProperty(session, 'processor')));
    })), 'fee')), (getObjectProperty(AD, 'vat'))));
  }

return 
}

})
define('./functions/95c944c98e485f86d92494227ab43547/code.js', () => { 

BackendlessUI.Functions.Custom['fn_95c944c98e485f86d92494227ab43547'] = async function fn_95c944c98e485f86d92494227ab43547(orgId, locumId, clientId) {
var sessions;


  if (locumId && clientId) {
    sessions = (await Backendless.Data.of('sessions').find(Backendless.DataQueryBuilder.create().setWhereClause((['agency.objectId = \'',orgId,'\' AND locum.objectId = \'',locumId,'\' AND client.objectId = \'',clientId,'\' AND timesheets[sessions].objectId IS NULL',' AND status = \'FILLED\''].join(''))).setRelationsDepth(1).setSortBy('start').setPageSize(100)));
  }

return sessions
}

})
define('./functions/a33f5a58073d8e32e3d41471b16dd866/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a33f5a58073d8e32e3d41471b16dd866'] = function fn_a33f5a58073d8e32e3d41471b16dd866() {
return 
}

})
define('./functions/a3ae033f2c0dfcff4a67570cee1961a6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a3ae033f2c0dfcff4a67570cee1961a6'] = async function fn_a3ae033f2c0dfcff4a67570cee1961a6(date, PD) {
var end, newSession, defaultRepeateEnd, sessions, start;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  sessions = (getObjectProperty(PD, 'sessions'));
  start = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](date, '09:00'));
  end = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](date, '11:00'));
  defaultRepeateEnd = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((start + 6 * 86400000))), 'shortDateString'));
  newSession = ({ [`start`]: start,[`end`]: end,[`rateClient`]: 105,[`rateLocum`]: 85,[`client`]: ({ [`objectId`]: undefined }),[`locum`]: ({ [`objectId`]: undefined }),[`___ui`]: ({ [`repeatEnd`]: defaultRepeateEnd }) });
  await BackendlessUI.Functions.Custom['fn_8564e643897ab40ff5d31e1473139bce'](newSession);
  PD['selectedItem'] = newSession;
  removeItemInList(sessions, newSession, 'objectId');
  addItemToList(sessions, newSession);
  PD['calendar'] = (await BackendlessUI.Functions.Custom['fn_0d6ab7518735beb79ac73b951ae997e1']((getObjectProperty(PD, 'blankCalendar')), (getObjectProperty(PD, 'sessions'))));

return 
}

})
define('./functions/a67f4c9ab1c992325037993cc146ba9b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a67f4c9ab1c992325037993cc146ba9b'] = async function fn_a67f4c9ab1c992325037993cc146ba9b(sessions) {
var actualUkEnd, actualUkStart, ukEnd, ukStart, j, sessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  for (var j_index in sessions) {
    j = sessions[j_index];
    if (!(getObjectProperty(j, 'actualStart'))) {
      j['actualBreak'] = (getObjectProperty(j, 'break'));
      j['actualStart'] = (getObjectProperty(j, 'start'));
      j['actualEnd'] = (getObjectProperty(j, 'start'));
    }
    ukStart = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'start'))));
    ukEnd = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'end'))));
    actualUkStart = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'actualStart'))));
    actualUkEnd = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'actualEnd'))));
    Object.assign(j, ({ [`date`]: [(getObjectProperty(ukStart, 'weekday')),' ',(getObjectProperty(ukStart, 'dateString'))].join(''),[`startTime`]: (getObjectProperty(ukStart, 'timeString')),[`endTime`]: (getObjectProperty(ukEnd, 'timeString')),[`actualDate`]: [(getObjectProperty(actualUkStart, 'weekday')),' ',(getObjectProperty(actualUkStart, 'dateString'))].join(''),[`actualStartTime`]: (getObjectProperty(actualUkStart, 'timeString')),[`actualEndTime`]: (getObjectProperty(actualUkEnd, 'timeString')) }));
  }

return 
}

})
define('./functions/a70b96a266543c5ed4930e623734c10f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a70b96a266543c5ed4930e623734c10f'] = async function fn_a70b96a266543c5ed4930e623734c10f(sessions) {
  await BackendlessUI.Functions.Custom['fn_694a8ce30fe66fc117ec880d68c8c5c8'](sessions, [({ [`columnName`]: 'Session ID',[`property`]: 'objectId' }), ({ [`columnName`]: 'Client',[`property`]: 'client' }), ({ [`columnName`]: 'Client ID',[`property`]: 'clientId' }), ({ [`columnName`]: 'Locum',[`property`]: 'locum' }), ({ [`columnName`]: 'Locum ID',[`property`]: 'locumId' }), ({ [`columnName`]: 'Date',[`property`]: 'date' }), ({ [`columnName`]: 'Start',[`property`]: 'startTime' }), ({ [`columnName`]: 'End',[`property`]: 'endTime' }), ({ [`columnName`]: 'Break',[`property`]: 'break' }), ({ [`columnName`]: 'Actual Start',[`property`]: 'actualStart' })]);

return 
}

})
define('./functions/a9e3c79bdf0fe6d868622bb7f53c9c86/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a9e3c79bdf0fe6d868622bb7f53c9c86'] = function fn_a9e3c79bdf0fe6d868622bb7f53c9c86(sessions, newSession) {
var sessions, session, day;

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}


  replaceItemInList(sessions, newSession, 'objectId');

return sessions
}

})
define('./functions/b063f3ff234bb19422decd3f13ae13ae/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b063f3ff234bb19422decd3f13ae13ae'] = async function fn_b063f3ff234bb19422decd3f13ae13ae(PD, timesheetId) {
var timesheet, timesheetId, session;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function enrichWithDateStrings() {
  var session_list = (getObjectProperty(timesheet, 'sessions'));
  for (var session_index in session_list) {
    session = session_list[session_index];
    Object.assign(session, ({ [`actualStartString`]: (await BackendlessUI.Functions.Custom['fn_8475b3f34a83206b9e192b82530e7d2b']((getObjectProperty(session, 'start')))),[`actualEndString`]: (await BackendlessUI.Functions.Custom['fn_8475b3f34a83206b9e192b82530e7d2b']((getObjectProperty(session, 'end')))) }));
  }
}


  timesheetId = timesheetId;
  timesheet = (await Backendless.Data.of('timesheets').findById(timesheetId, Backendless.DataQueryBuilder.create().setRelationsDepth(1)));
  await enrichWithDateStrings();
  timesheet['readOnly'] = true;
  (getObjectProperty(PD, 'selectedLocum'))['timesheet'] = timesheet;

return 
}

})
define('./functions/b48df14473b24f1ae216bd5e59da9584/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b48df14473b24f1ae216bd5e59da9584'] = function fn_b48df14473b24f1ae216bd5e59da9584(start, breaks, duration) {
var durationWithBreaks, breaks;


  if (breaks) {
    breaks = breaks;
  } else {
    breaks = 0;
  }
  durationWithBreaks = duration + breaks;

return (start + durationWithBreaks * 60000)
}

})
define('./functions/b74c8eb6ac2467ea66e7b09125c4b966/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b74c8eb6ac2467ea66e7b09125c4b966'] = async function fn_b74c8eb6ac2467ea66e7b09125c4b966(start, end, breaks) {
var duration, breaksInHour;


  if (breaks) {
    breaksInHour = breaks;
  } else {
    breaksInHour = 0;
  }
  duration = (await (async function(start, end) {
  	const MILLISECONDS_IN_HOUR = 3600000;  // 60 * 60 * 1000
  	const MILLISECONDS_IN_DAY = 86400000;  // 24 * 60 * 60 * 1000
  	const MILLISECONDS_IN_MINUTE = 60000;  // 60 * 1000

  	// Create a new Date object for today's date set to midnight (start of the day)
  	const today = new Date();
  	today.setHours(0, 0, 0, 0);

  	// Extract hours and minutes from the start timestamp and set them to the today's date
  	const startHours = new Date(start).getHours();
  	const startMinutes = new Date(start).getMinutes();
  	today.setHours(startHours, startMinutes);
  	start = today.getTime();

  	// Reset today's date to midnight again
  	today.setHours(0, 0, 0, 0);

  	// Extract hours and minutes from the end timestamp and set them to the today's date
  	const endHours = new Date(end).getHours();
  	const endMinutes = new Date(end).getMinutes();
  	today.setHours(endHours, endMinutes);
  	end = today.getTime();

  	// Strip out the seconds and milliseconds from start and end
  	start = Math.floor(start / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;
  	end = Math.floor(end / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;

  	let duration;

  	// Check if start and end are exactly the same
  	if (start === end) {
  	    duration = 24 * 60; // Duration is 1440 minutes (24 hours)
  	} else if (end > start) {
  	    // Normal case where end is after start
  	    duration = (end - start) / MILLISECONDS_IN_MINUTE;
  	} else {
  	    // Case where end is on the next day
  	    duration = (end + MILLISECONDS_IN_DAY - start) / MILLISECONDS_IN_MINUTE;
  	}

  	return duration;

  	/** BELOW FUNCTION WORKS, BUT UNIT OF DURATION IS HOURS.**/

  	// const MILLISECONDS_IN_HOUR = 3600000;  // 60 * 60 * 1000
  	// const MILLISECONDS_IN_DAY = 86400000;  // 24 * 60 * 60 * 1000
  	// const MILLISECONDS_IN_MINUTE = 60000;  // 60 * 1000

  	// // Create a new Date object for today's date set to midnight (start of the day)
  	// const today = new Date();
  	// today.setHours(0, 0, 0, 0);

  	// // Extract hours and minutes from the start timestamp and set them to the today's date
  	// const startHours = new Date(start).getHours();
  	// const startMinutes = new Date(start).getMinutes();
  	// today.setHours(startHours, startMinutes);
  	// start = today.getTime();

  	// // Reset today's date to midnight again
  	// today.setHours(0, 0, 0, 0);

  	// // Extract hours and minutes from the end timestamp and set them to the today's date
  	// const endHours = new Date(end).getHours();
  	// const endMinutes = new Date(end).getMinutes();
  	// today.setHours(endHours, endMinutes);
  	// end = today.getTime();

  	// // Strip out the seconds and milliseconds from start and end
  	// start = Math.floor(start / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;
  	// end = Math.floor(end / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;

  	// let duration;

  	// // Check if start and end are exactly the same
  	// if (start === end) {
  	//     duration = 24; // Duration is 24 hours
  	// } else if (end > start) {
  	//     // Normal case where end is after start
  	//     duration = (end - start) / MILLISECONDS_IN_HOUR;
  	// } else {
  	//     // Case where end is on the next day
  	//     duration = (end + MILLISECONDS_IN_DAY - start) / MILLISECONDS_IN_HOUR;
  	// }

  	// return duration;


  	/** BELOW FUNCTION WORKS, BUT WHEN START AND END TIME IS EXACTLY THE SAME, IT ASSUMES THE DURATION IS ZERO.**/

  	// const MILLISECONDS_IN_HOUR = 3600000;  // 60 * 60 * 1000
  	// const MILLISECONDS_IN_DAY = 86400000;  // 24 * 60 * 60 * 1000
  	// const MILLISECONDS_IN_MINUTE = 60000;  // 60 * 1000

  	// // Create a new Date object for today's date set to midnight (start of the day)
  	// const today = new Date();
  	// today.setHours(0, 0, 0, 0);

  	// // Extract hours and minutes from the start timestamp and set them to the today's date
  	// const startHours = new Date(start).getHours();
  	// const startMinutes = new Date(start).getMinutes();
  	// today.setHours(startHours, startMinutes);
  	// start = today.getTime();

  	// // Reset today's date to midnight again
  	// today.setHours(0, 0, 0, 0);

  	// // Extract hours and minutes from the end timestamp and set them to the today's date
  	// const endHours = new Date(end).getHours();
  	// const endMinutes = new Date(end).getMinutes();
  	// today.setHours(endHours, endMinutes);
  	// end = today.getTime();

  	// // Strip out the seconds and milliseconds from start and end
  	// start = Math.floor(start / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;
  	// end = Math.floor(end / MILLISECONDS_IN_MINUTE) * MILLISECONDS_IN_MINUTE;

  	// let duration;

  	// // Compare start and end
  	// if (end >= start) {
  	//     duration = (end - start) / MILLISECONDS_IN_HOUR;
  	// } else {
  	//     duration = (end + MILLISECONDS_IN_DAY - start) / MILLISECONDS_IN_HOUR;
  	// }

  	// return duration;



  })(start, end)) - breaksInHour;
  if (!(duration % 1 == 0)) {
    duration = Math.round(duration * 100) / 100;
  }

return duration
}

})
define('./functions/b78404622ac4324879cd1a77d399f5ef/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b78404622ac4324879cd1a77d399f5ef'] = async function fn_b78404622ac4324879cd1a77d399f5ef(url, path) {
function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  if (url) {
    return (subsequenceFromStartLast((await (async function(url, param) {
    	/**
    	 * Extracts the filename from a URL based on a specified parameter, decodes it, and handles '+' characters correctly.
    	 *
    	 * @param {string} url - The URL from which to extract the filename.
    	 * @param {string} param - The parameter string after which the filename is located.
    	 * @returns {string} - The extracted, decoded, and formatted filename with its extension.
    	 */

    	  // Add the trailing slash to the parameter
    	  const paramWithSlash = param + '/';

    	  // Find the position of the parameter with the trailing slash in the URL
    	  const paramPosition = url.indexOf(paramWithSlash);

    	  // If the parameter is not found, return an empty string
    	  if (paramPosition === -1) {
    	    return '';
    	  }

    	  // Extract the filename by slicing the URL from the end of the parameter with the trailing slash
    	  const encodedFilename = url.slice(paramPosition + paramWithSlash.length);

    	  // Decode the filename to make it more readable
    	  let decodedFilename = decodeURIComponent(encodedFilename);

    	  // Replace '+' with spaces (only those representing spaces in the URL)
    	  // To do this correctly, we'll use a regex that matches '+' only if it's not part of '%2B'
    	  decodedFilename = decodedFilename.replace(/\+/g, ' ').replace(/%2B/g, '+');

    	  return decodedFilename;

    })(url, path)), 8));
  }

return 
}

})
define('./functions/b7aeb43c64755fe4b7675ee9c32c6b1a/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b7aeb43c64755fe4b7675ee9c32c6b1a'] = async function fn_b7aeb43c64755fe4b7675ee9c32c6b1a(start, end, marginTracker) {
return (await (async function(start, end, marginTracker) {
	  let startStatus = null;
	  let endStatus = null;
	  let sum = 0;

	  for (let i = 0; i < marginTracker.length; i++) {
	    const item = marginTracker[i];
	    if (item.timestamp >= start && item.timestamp <= end) {
	      if (startStatus === null) {
	        startStatus = item.previousStatus;
	      }
	      endStatus = item.newStatus;
	      sum += item.marginDelta;
	    }
	  }

	  return {
	    marginDeltaSum: sum,
	    startStatus: startStatus,
	    endStatus: endStatus
	  };
})(start, end, marginTracker))
}

})
define('./functions/bff8b1efd3a8cf98c72677aed7d13e48/code.js', () => { 

BackendlessUI.Functions.Custom['fn_bff8b1efd3a8cf98c72677aed7d13e48'] = async function fn_bff8b1efd3a8cf98c72677aed7d13e48(AD, PD) {
var outstandingSessions, session, locumId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  locumId = (getObjectProperty(PD, 'selectedLocum.objectId'));
  outstandingSessions = (await Backendless.Data.of('sessions').find(Backendless.DataQueryBuilder.create().setWhereClause((['start < ',((new Date()).valueOf()),' AND status = \'FILLED\'',' AND timesheets[sessions].objectId IS NULL',' AND locum.objectId = \'',locumId,'\''].join(''))).setRelated(['client']).setSortBy('start').setPageSize(100)));
  for (var session_index in outstandingSessions) {
    session = outstandingSessions[session_index];
    Object.assign(session, ({ [`sessionId`]: (getObjectProperty(session, 'objectId')),[`session`]: (await BackendlessUI.Functions.Custom['fn_f3867a1d5db145aa5a0581ffde6c8f6d']((getObjectProperty(session, 'start')), (getObjectProperty(session, 'end')))),[`daysOld`]: (await (async function(timestamp) {
    	    const givenDate = new Date(timestamp);
    	    const today = new Date();

    	    // Reset the hours to avoid partial days being counted as the same day
    	    givenDate.setHours(0, 0, 0, 0);
    	    today.setHours(0, 0, 0, 0);

    	    // Calculate the difference in time
    	    const timeDifference = today - givenDate;

    	    // Convert the difference from milliseconds to days and add 1
    	    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    	    return daysDifference;
    })((getObjectProperty(session, 'end')))),[`client`]: (getObjectProperty(session, 'client.orgName')),[`clientId`]: (getObjectProperty(session, 'client.objectId')) }));
  }
  (getObjectProperty(PD, 'selectedLocum'))['outstandingSessions'] = outstandingSessions;

return 
}

})
define('./functions/c46539ee9ebb2936bbd424e93e08356b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c46539ee9ebb2936bbd424e93e08356b'] = async function fn_c46539ee9ebb2936bbd424e93e08356b(dateStr) {
return (await (async function(dateStr) {
	/** ORIGINAL CODE PASS 01/1m/24 as valid, so now updated to show it's invalid */
	// // Parse the date string into day, month, and year
	// const [day, month, year] = dateStr.split('/').map(num => parseInt(num, 10));

	// // Check if year, month, and day are valid numbers
	// if (isNaN(year) || isNaN(month) || isNaN(day)) {
	//     return false;
	// }

	// // Adjust for yy format to yyyy (assuming 21st century)
	// const fullYear = year + (year < 100 ? 2000 : 0);

	// // Create a Date object (months are 0-indexed in JavaScript Date)
	// const inputDate = new Date(fullYear, month - 1, day);

	// // Check if it's an invalid date (e.g., 30th of February)
	// if (inputDate.getFullYear() !== fullYear || inputDate.getMonth() !== month - 1 || inputDate.getDate() !== day) {
	//     return false;
	// }

	// // Get today's date without time for comparison
	// const today = new Date();
	// today.setHours(0, 0, 0, 0);

	// return true;


	    const parts = dateStr.split('/');
	    if (parts.length !== 3) return false;

	    const [dayStr, monthStr, yearStr] = parts;

	    // Check that each part is strictly numeric
	    if (!/^\d+$/.test(dayStr) || !/^\d+$/.test(monthStr) || !/^\d+$/.test(yearStr)) {
	        return false;
	    }

	    const day = parseInt(dayStr, 10);
	    const month = parseInt(monthStr, 10);
	    const year = parseInt(yearStr, 10);

	    // Adjust for 2-digit year
	    const fullYear = year + (year < 100 ? 2000 : 0);

	    // Create and validate the date
	    const inputDate = new Date(fullYear, month - 1, day);
	    if (
	        inputDate.getFullYear() !== fullYear ||
	        inputDate.getMonth() !== (month - 1) ||
	        inputDate.getDate() !== day
	    ) {
	        return false;
	    }

	    return true;

})(dateStr))
}

})
define('./functions/c67e840d0a55e6fa6c4225c2979e2892/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c67e840d0a55e6fa6c4225c2979e2892'] = async function fn_c67e840d0a55e6fa6c4225c2979e2892(arr) {
var BUNDLES_BY_MONTH;



return (await (async function(inputData) {
	function formatNumbers(data) {
	  // Check if a value is likely a timestamp (13-digit number)
	  function isTimestamp(value) {
	    return typeof value === 'number' && value.toString().length === 13;
	  }

	  // Format a value if it's a number (but not a timestamp)
	  function formatValue(value) {
	    if (typeof value === 'number' && !isTimestamp(value)) {
	      // Format number to have 0 decimal places
	      return value.toLocaleString('en-US', {
	        minimumFractionDigits: 0,
	        maximumFractionDigits: 0
	      });
	    }
	    // Return non-number values as-is
	    return value;
	  }

	  // Recursively process an object or array
	  function processObject(obj) {
	    for (let key in obj) {
	      if (typeof obj[key] === 'object' && obj[key] !== null) {
	        // Recursively process nested objects
	        obj[key] = processObject(obj[key]);
	      } else if (!isTimestamp(obj[key])) {
	        // Format the value if it's not a timestamp
	        obj[key] = formatValue(obj[key]);
	      }
	      // Timestamps are left as-is
	    }
	    return obj;
	  }

	  // Process each item in the input data
	  // Create a deep copy of each item to avoid modifying the original data
	  return data.map(item => processObject(JSON.parse(JSON.stringify(item))));
	}

	// Usage
	return formatNumbers(inputData);
})(arr))
}

})
define('./functions/ced4e1a3026d81b341030207bf58034c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'] = async function fn_ced4e1a3026d81b341030207bf58034c(variable) {
return (await (async function(variable) {
	// Check if the variable is null or undefined, return null if true
	if (variable === null || variable === undefined) {
	    return null;
	}

	// Deep copy the variable
	const copiedVariable = JSON.parse(JSON.stringify(variable));

	return copiedVariable;

})(variable))
}

})
define('./functions/ced8ed7da4ec620952544184e7e6cc0b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ced8ed7da4ec620952544184e7e6cc0b'] = async function fn_ced8ed7da4ec620952544184e7e6cc0b(orgId) {
var sessions, j, ukEnd, ukStart;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessions = (await Backendless.Data.of('sessions').find(Backendless.DataQueryBuilder.create().setWhereClause((['agency.objectId = \'',orgId,'\''].join(''))).setRelationsDepth(1).setPageSize(100)));
  for (var j_index in sessions) {
    j = sessions[j_index];
    ukStart = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'start'))));
    ukEnd = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'end'))));
    Object.assign(j, ({ [`clientName`]: (getObjectProperty(j, 'client.orgName')),[`locumName`]: (getObjectProperty(j, 'locum.orgName')),[`clientId`]: (getObjectProperty(j, 'client.objectId')),[`date`]: [(getObjectProperty(ukStart, 'weekday')),' ',(getObjectProperty(ukStart, 'dateString'))].join(''),[`startTime`]: (getObjectProperty(ukStart, 'timeString')),[`endTime`]: (getObjectProperty(ukEnd, 'timeString')),[`clientEmail`]: (getObjectProperty(j, 'client.billingEmail')),[`locumEmail`]: (getObjectProperty(j, 'locum.billingEmail')),[`clientAddress`]: (getObjectProperty(j, 'client.address')) }));
  }

return sessions
}

})
define('./functions/cfb7c4ca60cd0925e266e26da4a56c6f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cfb7c4ca60cd0925e266e26da4a56c6f'] = async function fn_cfb7c4ca60cd0925e266e26da4a56c6f(AD, PD) {
var weeklyTimestamps, week, cancelledThisWeek, filledThisWeek, thisWeek, AD, item, PD;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}


  AD = AD;
  PD = PD;
  weeklyTimestamps = (await (async function(startDate, endDate) {
  	  const { DateTime } = luxon;

  	    const today = DateTime.now().setZone('Europe/London').startOf('day');
  	    const start = DateTime.fromJSDate(startDate).setZone('Europe/London').startOf('week').set({ weekday: 1 }).startOf('day');
  	    const end = DateTime.fromJSDate(endDate).setZone('Europe/London').endOf('week').set({ weekday: 1 }).minus({ milliseconds: 1 });

  	    const weeks = [];
  	    let currentWeekStart = start;

  	    while (currentWeekStart <= end) {
  	        const currentWeekEnd = currentWeekStart.plus({ days: 7 }).minus({ milliseconds: 1 });

  	        const isThisWeek = today >= currentWeekStart && today <= currentWeekEnd;

  	        weeks.push({
  	            startOfWeek: currentWeekStart.toMillis(),
  	            endOfWeek: currentWeekEnd.toMillis(),
  	            startDate: currentWeekStart.toISO({ zone: 'Europe/London' }),
  	            endDate: currentWeekEnd.toISO({ zone: 'Europe/London' }),
  	            thisWeek: isThisWeek
  	        });

  	        currentWeekStart = currentWeekStart.plus({ weeks: 1 });
  	    }

  	    return weeks;
  })((new Date((new Date()).getTime() - ((30 * 86400) * 1000))), (new Date())));
  thisWeek = (await asyncListFind(weeklyTimestamps, async (item) => {


   return ((getObjectProperty(item, 'thisWeek')) == true);
  }));
  console.log(thisWeek);
  filledThisWeek = (await Backendless.Data.of('sessions').find(Backendless.DataQueryBuilder.create().setWhereClause((['filledAt >= ',(getObjectProperty(thisWeek, 'startOfWeek')),' AND filledAt <= ',(getObjectProperty(thisWeek, 'endOfWeek')),' AND client.accountOwner.objectId = \'',(getObjectProperty(AD, 'currentUser.objectId')),'\' AND previousStatus <> \'FILLED\' and status = \'FILLED\''].join(''))).setProperties(['sum(margin) as margin', 'count(margin) as sessionsCount', 'count(DISTINCT client.objectId) as clientsCount', 'count(DISTINCT locum.objectId) as locumsCount']).setGroupBy(['client.accountOwner.objectId']).setPageSize(1)))[0];
  console.log(filledThisWeek);
  cancelledThisWeek = (await Backendless.Data.of('sessions').find(Backendless.DataQueryBuilder.create().setWhereClause((['cancelledAt >= ',(getObjectProperty(thisWeek, 'startOfWeek')),' AND cancelledAt <= ',(getObjectProperty(thisWeek, 'endOfWeek')),' AND client.accountOwner.objectId = \'',(getObjectProperty(AD, 'currentUser.objectId')),'\' AND previousStatus = \'FILLED\' and status = \'OPEN\''].join(''))).setProperties(['sum(margin) as margin', 'count(margin) as sessionsCount', 'count(DISTINCT client.objectId) as clientsCount', 'count(DISTINCT locum.objectId) as locumsCount']).setGroupBy(['client.accountOwner.objectId']).setPageSize(1)))[0];
  console.log(cancelledThisWeek);
  if (weeklyTimestamps) {
    Object.assign(thisWeek, (await (async function(filled, cancelled) {
    	    // Helper function to prefix properties
    	    function prefixProperties(obj, prefix) {
    	        return obj ? Object.entries(obj).reduce((acc, [key, value]) => {
    	            acc[prefix + key.charAt(0).toUpperCase() + key.slice(1)] = value;
    	            return acc;
    	        }, {}) : {};
    	    }

    	    // Extract properties with prefixes
    	    const filledProperties = prefixProperties(filled, 'filled');
    	    const cancelledProperties = prefixProperties(cancelled, 'cancelled');

    	    // Calculate net new properties
    	    const filledMargin = filled?.margin || 0;
    	    const cancelledMargin = cancelled?.margin || 0;
    	    const filledSessionsCount = filled?.sessionsCount || 0;
    	    const cancelledSessionsCount = cancelled?.sessionsCount || 0;

    	    const netNewProperties = {
    	        netNewMargin: filledMargin - cancelledMargin,
    	        netNewSessions: filledSessionsCount - cancelledSessionsCount
    	    };

    	    // Merge all properties
    	    return { ...filledProperties, ...cancelledProperties, ...netNewProperties };
    })(filledThisWeek, cancelledThisWeek)));
  }
  for (var week_index in weeklyTimestamps) {
    week = weeklyTimestamps[week_index];
    week['week'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(week, 'endOfWeek')))), 'isoDateString'));
  }
  AD['netNew'] = weeklyTimestamps;

return 
}

})
define('./functions/d17e964e29c4bb15c9de64bf5ef1ba7c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d17e964e29c4bb15c9de64bf5ef1ba7c'] = async function fn_d17e964e29c4bb15c9de64bf5ef1ba7c(sessions, agency, userBody, template) {
  if (template == 'splitByClient') {
    return (await (async function(agency, userBody, groupedSessions) {
    	// Custom function: ZZ_sessions_locumEmail

    	function sanitize(value) {
    	    return value == null ? '' : value;
    	}

    	function generateEmailHTML(groupedSessions) {
    	    let emailHTML = `
    	    <!DOCTYPE html>
    	    <html lang="en">
    	    <head>
    	        <meta charset="UTF-8">
    	        <meta name="viewport" content="width=device-width, initial-scale=1.0">
    	        <style>
    	            body {
    	                font-family: Arial, sans-serif;
    	            }
    	            .container {
    	                margin: 0 auto;
    	                padding: 20px;
    	                max-width: 600px;
    	                border: 1px solid #ddd;
    	                border-radius: 8px;
    	                margin-bottom: 20px;
    	            }
    	            .header {
    	                margin-bottom: 20px;
    	            }
    	            .header p {
    	                margin: 0;
    	                font-size: 16px;
    	                line-height: 1.5;
    	            }
    	            .table-container {
    	                margin-top: 20px;
    	            }
    	            table {
    	                width: 100%;
    	                border-collapse: collapse;
    	                margin-bottom: 20px;
    	            }
    	            th, td {
    	                padding: 10px;
    	                border: 1px solid #ddd;
    	                text-align: left;
    	            }
    	            th {
    	                background-color: #f4f4f4;
    	            }
    	        </style>
    	        <title>Email Template</title>
    	                    <div class="header">
    	                <p><strong>Agency:</strong> ${sanitize(agency)}</p>
    	                <p>${sanitize(userBody)}</p>
    	            </div>
    	    </head>
    	    <body>`;

    	    groupedSessions.forEach(clientGroup => {
    	        emailHTML += `
    	        <div class="container">
    	            <div class="header">
    	                <p><strong>Client:</strong> ${sanitize(clientGroup.client.orgName)}</p>
    	                <p><strong>Address:</strong> ${sanitize(clientGroup.client.address)}</p>
    	            </div>
    	            <div class="table-container">
    	                <h2>Sessions</h2>
    	                <table>
    	                    <thead>
    	                        <tr>
    	                            <th>Status</th>
    	                            <th>Date</th>
    	                            <th>Start</th>
    	                            <th>End</th>
    	                            <th>Rate</th>
    	                            <th>Notes</th>
    	                        </tr>
    	                    </thead>
    	                    <tbody>`;

    	        clientGroup.items.forEach(session => {
    	            emailHTML += `
    	                        <tr>
    	                            <td>${sanitize(session.confirmationStatusLocum)}</td>
    	                            <td>${sanitize(session.date)}</td>
    	                            <td>${sanitize(session.dates.start.time)}</td>
    	                            <td>${sanitize(session.dates.end.time)}</td>
    	                            <td>${sanitize(session.rateLocum)}</td>
    	                            <td>${sanitize(session.notesSession)}</td>
    	                        </tr>`;
    	        });

    	        emailHTML += `
    	                    </tbody>
    	                </table>
    	            </div>
    	        </div>`;
    	    });

    	    emailHTML += `
    	    </body>
    	    </html>`;

    	    return emailHTML;
    	}

    	return(generateEmailHTML(groupedSessions));
    })(agency, userBody, (await BackendlessUI.Functions.Custom['fn_4d418f750b624e699d6def8d73e65db4'](sessions, 'client'))));
  } else {
    return (await (async function(agency, userBody, sessions) {
    	// Custom function: ZZ_sessions_locumEmail

    	function sanitize(value) {
    	    return value == null ? '' : value;
    	}

    	function generateEmailHTML(sessions) {
    	    // Sort sessions by date and start time
    	    sessions.sort((a, b) => new Date(a.date + ' ' + a.dates.start.time) - new Date(b.date + ' ' + b.dates.start.time));

    	    // Use a Map to track unique clients by their objectId
    	    const uniqueClients = new Map();

    	    let emailHTML = `
    	    <!DOCTYPE html>
    	    <html lang="en">
    	    <head>
    	        <meta charset="UTF-8">
    	        <meta name="viewport" content="width=device-width, initial-scale=1.0">
    	        <style>
    	            body {
    	                font-family: Arial, sans-serif;
    	            }
    	            .container {
    	                margin: 0 auto;
    	                padding: 20px;
    	                max-width: 600px;
    	                border: 1px solid #ddd;
    	                border-radius: 8px;
    	                margin-bottom: 20px;
    	            }
    	            .header {
    	                margin-bottom: 20px;
    	            }
    	            .header p {
    	                margin: 0;
    	                font-size: 16px;
    	                line-height: 1.5;
    	            }
    	            .table-container {
    	                margin-top: 20px;
    	            }
    	            table {
    	                width: 100%;
    	                border-collapse: collapse;
    	                margin-bottom: 20px;
    	            }
    	            th, td {
    	                padding: 10px;
    	                border: 1px solid #ddd;
    	                text-align: left;
    	            }
    	            th {
    	                background-color: #f4f4f4;
    	            }
    	        </style>
    	        <title>Email Template</title>
    	    </head>
    	    <body>
    	        <div class="header">
    	            <p><strong>Agency:</strong> ${sanitize(sessions[0]?.agency.orgName)}</p>
    	        </div>`;

    	    // Generate sessions table
    	    emailHTML += `
    	    <div class="container">
    	        <div class="table-container">
    	            <h2>Sessions</h2>
    	            <table>
    	                <thead>
    	                    <tr>
    	                        <th>Client</th>
    	                        <th>Status</th>
    	                        <th>Date</th>
    	                        <th>Start</th>
    	                        <th>End</th>
    	                        <th>Rate</th>
    	                        <th>Notes</th>
    	                    </tr>
    	                </thead>
    	                <tbody>`;

    	    sessions.forEach(session => {
    	        emailHTML += `
    	                    <tr>
    	                        <td>${sanitize(session.client.orgName)}</td>
    	                        <td>${sanitize(session.confirmationStatusLocum)}</td>
    	                        <td>${sanitize(session.date)}</td>
    	                        <td>${sanitize(session.dates.start.time)}</td>
    	                        <td>${sanitize(session.dates.end.time)}</td>
    	                        <td>${sanitize(session.rateLocum)}</td>
    	                        <td>${sanitize(session.notesSession)}</td>
    	                    </tr>`;

    	        // Track unique clients
    	        if (!uniqueClients.has(session.client.objectId)) {
    	            uniqueClients.set(session.client.objectId, session.client);
    	        }
    	    });

    	    emailHTML += `
    	                </tbody>
    	            </table>
    	        </div>
    	    </div>`;

    	    // Generate unique client details section
    	    emailHTML += `
    	    <div class="container">
    	        <h2>Client Details</h2>`;

    	    uniqueClients.forEach(client => {
    	        emailHTML += `
    	        <div class="header">
    	            <p><strong>Client:</strong> ${sanitize(client.orgName)}</p>
    	            <p><strong>Address:</strong> ${sanitize(client.address)}</p>
    	        </div>`;
    	    });

    	    emailHTML += `
    	    </div>
    	    </body>
    	    </html>`;

    	    return emailHTML;
    	}

    	return(generateEmailHTML(sessions));
    })(agency, userBody, sessions));
  }

return 
}

})
define('./functions/dcaa7742e74995275d85b9230f9b24b6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'] = async function fn_dcaa7742e74995275d85b9230f9b24b6(obj, nestedPath, vaue) {
  await (async function(obj, nestedPath, value) {
  	/**
  	 * Sets a deeply nested value within an object, creating intermediate objects as needed.
  	 *
  	 * @param {Object} obj - The base object on which to set the value.
  	 * @param {String} nestedPath - A dot-separated string indicating the path where the value should be set.
  	 * @param {*} value - The value to set at the target location.
  	 *
  	 * The function first splits `nestedPath` into an array of strings representing each property in the path.
  	 * It then iterates through these properties, ensuring each exists and is an object. If a property in the path
  	 * does not exist or is not an object, it is initialized as an empty object. This process continues until the
  	 * script reaches the second-to-last property in the path.
  	 *
  	 * Finally, the function sets `value` on the final property in the path, ensuring that the deeply nested
  	 * structure is updated as intended. This utility is helpful for dynamically generating nested structures
  	 * without manually verifying and creating each level of the hierarchy.
  	 *
  	 * Example Usage:
  	 * let obj = {};
  	 * setNestedValue(obj, 'a.b.c', 4);
  	 * // obj is now { a: { b: { c: 4 } } }
  	 */

  	const properties = nestedPath.split('.');
  	let current = obj;

  	for (let i = 0; i < properties.length - 1; i++) {
  	  const property = properties[i];

  	  // If the property doesn't exist, or is not an object, create it
  	  if (!current[property] || typeof current[property] !== 'object') {
  	    current[property] = {};
  	  }

  	  current = current[property];
  	}

  	// Set the value for the final property
  	current[properties[properties.length - 1]] = value;

  })(obj, nestedPath, vaue);

return 
}

})
define('./functions/e062c6ac1908ee48e847dbfd09d4f472/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e062c6ac1908ee48e847dbfd09d4f472'] = async function fn_e062c6ac1908ee48e847dbfd09d4f472(AD, PD) {
var AD, dropdown, PD, targets, users, year;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  AD = AD;
  PD = PD;
  year = (getObjectProperty(PD, 'year'));
  targets = (await Backendless.Data.of('targets').find(Backendless.DataQueryBuilder.create().setWhereClause((String('year = ') + String(year))).setProperties(['objectId', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'decm', 'consultant.name as consultant', 'consultant.objectId as consultantId', 'total']).setPageSize(50)));
  console.log(targets);
  users = (getObjectProperty(AD, 'currentOrg.users'));
  dropdown = (await (async function(targets, users) {
  	  // Create an object to store matched and unmatched users
  	  const result = {
  	    match: [],
  	    noMatch: []
  	  };

  	  // Create a map for quick lookup of targets by consultantId
  	  const targetMap = new Map(targets.map(target => [target.consultantId, target]));

  	  // Iterate over the users array
  	  users.forEach(user => {
  	    // Check if the user's objectId exists in the target map
  	    if (targetMap.has(user.objectId)) {
  	      result.match.push(user);
  	    } else {
  	      result.noMatch.push(user);
  	    }
  	  });

  	  // Transform the result to the desired format
  	  function mapUser(user) {
  	    return {
  	      value: user.objectId,
  	      label: user.name
  	    };
  	  }

  	  const transformedResult = {
  	    match: result.match.map(mapUser),
  	    noMatch: result.noMatch.map(mapUser)
  	  };

  	  return transformedResult;
  })(targets, users));
  PD['addDropdown'] = (getObjectProperty(dropdown, 'noMatch'));
  PD['targets'] = targets;

return 
}

})
define('./functions/e091c5a99d6cb561c1c5d926db56846f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e091c5a99d6cb561c1c5d926db56846f'] = async function fn_e091c5a99d6cb561c1c5d926db56846f(url, path) {
  await (async function(fileName, byteArray) {
  	/**
  	 * Triggers a download of a file from a list of bytes.
  	 *
  	 * @param {Uint8Array} byteArray - The byte array representing the file content.
  	 * @param {string} fileName - The name of the file to be downloaded.
  	 */

  	  // Create a blob from the byte array
  	  const blob = new Blob([byteArray], { type: 'application/octet-stream' });

  	  // Create a link element
  	  const link = document.createElement('a');

  	  // Set the download attribute with the desired file name
  	  link.download = fileName;

  	  // Create an object URL for the blob
  	  link.href = window.URL.createObjectURL(blob);

  	  // Append the link to the document body (necessary for some browsers)
  	  document.body.appendChild(link);

  	  // Programmatically click the link to trigger the download
  	  link.click();

  	  // Remove the link from the document
  	  document.body.removeChild(link);

  })((await BackendlessUI.Functions.Custom['fn_b78404622ac4324879cd1a77d399f5ef'](url, path)), (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})(url)).set(({ [`user-token`]: (Backendless.getCurrentUserToken()) })).setEncoding(null).send()));

return 
}

})
define('./functions/e6a439de34b58b9a7e563184c75416ea/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e6a439de34b58b9a7e563184c75416ea'] = async function fn_e6a439de34b58b9a7e563184c75416ea(items, groupingProperty, extractedProperties) {
return (await (async function(items, groupingProperty, extractedProperties) {
	const groupItems = (items, groupingProperty, extractedProperties = []) => {
	  const grouped = {};

	  items.forEach(item => {
	    const groupingObjectId = item[groupingProperty];

	    if (!grouped[groupingObjectId]) {
	      grouped[groupingObjectId] = {
	        [groupingProperty]: groupingObjectId,
	        items: []
	      };

	      // Extract and elevate specified properties only if extractedProperties is defined and not empty
	      if (Array.isArray(extractedProperties) && extractedProperties.length > 0) {
	        extractedProperties.forEach(prop => {
	          grouped[groupingObjectId][prop] = item[prop];
	        });
	      }
	    }

	    grouped[groupingObjectId].items.push(item);
	  });

	  return Object.entries(grouped).map(([groupingObjectId, groupedObject]) => ({
	    ...groupedObject
	  }));
	};

	return groupItems(items, groupingProperty, extractedProperties)
})(items, groupingProperty, extractedProperties))
}

})
define('./functions/e73160caa2fefdbdf569314b42137f4c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e73160caa2fefdbdf569314b42137f4c'] = async function fn_e73160caa2fefdbdf569314b42137f4c(orgId, PD, page, sortBy) {
var timesheets, pageSize, whereClause, timesheet, page, sortby, PD, orgId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgId = orgId;
  PD = PD;
  page = page;
  sortby = sortBy;
  pageSize = 50;
  if (!(await BackendlessUI.Functions.Custom['fn_8d4b531b860a4ba62584e6712dfb87eb']((getObjectProperty(PD, 'ui_statusFilter'))))) {
    whereClause = String(' AND ') + String((await BackendlessUI.Functions.Custom['fn_064af7773cbb9bdb2ff71d23201cd648']('status', (getObjectProperty(PD, 'ui_statusFilter')))));
  }
  whereClause = [whereClause,' AND agency.objectId = \'',orgId,'\''].join('');
  if (getObjectProperty(PD, 'ui_clientFilter')) {
    whereClause = [whereClause,' AND client.objectId = \'',(getObjectProperty(PD, 'ui_clientFilter')),'\''].join('');
  }
  if (getObjectProperty(PD, 'ui_locumFilter')) {
    whereClause = [whereClause,' AND locum.objectId = \'',(getObjectProperty(PD, 'ui_locumFilter')),'\''].join('');
  }
  if (getObjectProperty(PD, 'ui_filenameFilter')) {
    whereClause = [whereClause,' AND filename LIKE \'%',(getObjectProperty(PD, 'ui_filenameFilter')),'%\''].join('');
  }
  whereClause = whereClause.slice(5, whereClause.length);
  if (!sortby) {
    sortby = 'created';
  }
  timesheets = (await Backendless.Data.of('timesheets').find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setRelationsDepth(1).setSortBy(sortby).setPageSize(pageSize).setOffset((page * pageSize))));
  for (var timesheet_index in timesheets) {
    timesheet = timesheets[timesheet_index];
    Object.assign(timesheet, ({ [`dataGrid`]: ({ [`locumName`]: (getObjectProperty(timesheet, 'locum.orgName')),[`clientName`]: (getObjectProperty(timesheet, 'client.orgName')),[`sessionsCount`]: (getObjectProperty(timesheet, 'sessions')).length,[`submitted`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(timesheet, 'created')))), 'isoDateString')),[`timesheetId`]: (getObjectProperty(timesheet, 'objectId')) }) }));
  }
  PD['timesheets'] = timesheets;
  PD['timesheetsPageCount'] = (Math.ceil((await Backendless.Data.of('timesheets').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause(whereClause))) / pageSize));

return timesheets
}

})
define('./functions/f055c017f669d18e350b65f687c3f30b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f055c017f669d18e350b65f687c3f30b'] = async function fn_f055c017f669d18e350b65f687c3f30b(PD, AD, filters) {
var response, PD, filters, locumId, AD, pageSize, whereClause, timesheets, timesheet, page, sortby, orgId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something() {
  if (!(await BackendlessUI.Functions.Custom['fn_8d4b531b860a4ba62584e6712dfb87eb']((getObjectProperty(filters, 'status'))))) {
    whereClause = String(' AND ') + String((await BackendlessUI.Functions.Custom['fn_064af7773cbb9bdb2ff71d23201cd648']('status', (getObjectProperty(PD, 'ui.statusFilter')))));
  }
  if (getObjectProperty(filters, 'clientId')) {
    whereClause = [whereClause,' AND client.objectId = \'',(getObjectProperty(filters, 'clientId')),'\''].join('');
  }
  if (getObjectProperty(filters, 'locumId')) {
    whereClause = [whereClause,' AND locum.objectId = \'',(getObjectProperty(filters, 'locumId')),'\''].join('');
  }
  if (getObjectProperty(filters, 'file')) {
    whereClause = [whereClause,' AND filename LIKE \'%',(getObjectProperty(filters, 'file')),'%\''].join('');
  }
  whereClause = whereClause.slice(5, whereClause.length);
  if (!sortby) {
    sortby = 'created';
  }
  timesheets = (await Backendless.Data.of('timesheets').find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setRelationsDepth(1).setSortBy(sortby).setPageSize(pageSize).setOffset((page * pageSize))));
  for (var timesheet_index in timesheets) {
    timesheet = timesheets[timesheet_index];
    Object.assign(timesheet, ({ [`dataGrid`]: ({ [`locumName`]: (getObjectProperty(timesheet, 'locum.orgName')),[`clientName`]: (getObjectProperty(timesheet, 'client.orgName')),[`sessionsCount`]: (getObjectProperty(timesheet, 'sessions')).length,[`submitted`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(timesheet, 'created')))), 'isoDateString')),[`timesheetId`]: (getObjectProperty(timesheet, 'objectId')) }) }));
  }
  AD['timesheets'] = timesheets;
  AD['timesheetsPageCount'] = (Math.ceil((await Backendless.Data.of('timesheets').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause(whereClause))) / pageSize));
}


  AD = AD;
  PD = PD;
  filters = filters;
  locumId = (getObjectProperty(PD, 'selectedLocum.objectId'));
  if (!filters) {
    filters = ({ [`locumId`]: locumId });
  }
  response = (await BackendlessUI.Functions.Custom['fn_3ed59821a80529e8b6475f3a89c3587d'](null, 'created', filters));
  Object.assign((getObjectProperty(PD, 'selectedLocum')), ({ [`timesheet`]: null,[`timesheets`]: (getObjectProperty(response, 'timesheets')) }));

return 
}

})
define('./functions/f3867a1d5db145aa5a0581ffde6c8f6d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f3867a1d5db145aa5a0581ffde6c8f6d'] = async function fn_f3867a1d5db145aa5a0581ffde6c8f6d(startTimestamp, endTimestamp) {
return (await (async function(startTimestamp, endTimestamp) {
	const {
	  DateTime
	} = luxon

	  // Convert timestamps to Europe/London timezone
	  const start = DateTime.fromMillis(startTimestamp, { zone: 'Europe/London' });
	  const end = DateTime.fromMillis(endTimestamp, { zone: 'Europe/London' });

	  // Format start date and time
	  const startDate = start.toFormat('ccc dd/MM');
	  const startTime = start.toFormat('HH:mm');

	  // Format end time
	  const endTime = end.toFormat('HH:mm');

	  // Calculate day difference
	  const dayDifference = Math.floor(end.diff(start, 'days').toObject().days);

	  // Prepare end day string if the end is on the next day or later
	  const endDayString = dayDifference > 0 ? ` (+${dayDifference}d)` : '';

	  // Return formatted string
	  return `${startDate}, ${startTime}-${endTime}${endDayString}`;

})(startTimestamp, endTimestamp))
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/clients/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var currentOrg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentOrg = (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'));
  if (currentOrg) {
    await BackendlessUI.Functions.Custom['fn_03879a2285ff44c48fea35816ca5fc9e'](___arguments.context.pageData, ___arguments.context.appData);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/clients/components/d765db5857579345749d3f665b38e7c7/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var selected, selectedId, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}


  if (getObjectProperty(___arguments.context.pageData, 'ui.editable')) {
    (function (message) { alert(message) })('Please save or cancel changes first.');
  } else {
    selectedId = (getObjectProperty(___arguments['cellParams'], 'data.clientId'));
    selected = (await asyncListFind((getObjectProperty(___arguments.context.pageData, 'orgs')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) == selectedId);
    }));
    ___arguments.context.pageData['selectedOrg'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selected));
    ___arguments.context.pageData['selectedOrg_original'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selected));
    ___arguments.context.pageData['ui.editable'] = false;
    console.log(getObjectProperty(___arguments['cellParams'], 'data.objectId'));
    console.log(selected);
  }

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/clients/components/f565417e7fb7a94cc5b5b07d6e2ea88e/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'), 'objectId')) == 'NEW') {
    return 'Add Client';
  } else {
    return 'Save Changes';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/clients/components/80686f076e29f9e794c4cc1f2b27ceeb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/87aacb88039d06bcaebccd56b9e2c515/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var orgName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgName = (getObjectProperty(___arguments.context.pageData, 'selectedOrg.orgName'));
  if (orgName) {
    return orgName;
  }

  return 'New Client'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/clients/components/45eb8cbc0b5e25ebbdc4cab5ea6525ae/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('c4a3d0764ec693ad35489df297947a4b');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/clients/components/c4a3d0764ec693ad35489df297947a4b/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('c4a3d0764ec693ad35489df297947a4b');
  try {
    await Backendless.Request.delete(`${Backendless.appPath}/services/ORGS/v1/org`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'orgType': 'clients','objectId': (getObjectProperty(___arguments.context.pageData, 'selectedOrg.objectId')) });
    ___arguments.context.pageData['selectedOrg'] = null;
    await BackendlessUI.Functions.Custom['fn_03879a2285ff44c48fea35816ca5fc9e'](___arguments.context.pageData, ___arguments.context.appData);
    (function (message) { alert(message) })('Client Deleted! 🗑️');
    ___arguments.context.pageData['ui.editable'] = false;

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/clients/components/844afe66c8fc436e90066c2e0b3c2618/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    
  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/a6221d9e0789cec28ca8a57cd6ec182c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'), 'sameContact')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/clients/components/ec658cc37188a366c3e41a63b5931f22/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var options, option, j, orgUsers, list, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  orgUsers = (getObjectProperty(___arguments.context.appData, 'currentOrg.users'));
  options = [({ [`value`]: 'DELETE',[`label`]: '-- UNASSIGN --' })];
  if (orgUsers) {
    for (var j_index in orgUsers) {
      j = orgUsers[j_index];
      option = ({ [`value`]: (getObjectProperty(j, 'objectId')),[`label`]: (getObjectProperty(j, 'name')) });
      addItemToList(options, option);
    }
  }
  options = (await asyncListSort(options, 1, async (item) => {


   return (getObjectProperty(j, 'label'));
  }));

  return options

  },
  /* handler:onOptionsAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/cfc876a37f91eaf6436d26cdae8c9eb1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'ui.editable'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/clients/components/f1229309ef0c27f1c6163ed1fb135be7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/b008aa43458e7466ff822a9d500603b3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/202bf31eb7497a75476ffc430b0e68ff/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/97b458fccbd9a2671cd397da75852f3c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/fcf5769e42e4ff89cd11b28b915c691b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/aa8a0389a3652f263ae728f1546c4934/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/b713a68662e668379f0afb68c1b36d1d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/c081c0bdf86af4791923e1f8ab3d6e7f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/b4765e57a2a6560c99ba7ac812317204/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/9ca6640c90c18d7e641a07e77fe109ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/34b7f0a816bba2c484eae0e785e654a2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/09c82aa2acd4767816df940330e43f69/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/7f1a999b940ddd6e4a6f7bea95162527/bundle.js', [], () => ({
  /* content */
  /* handler:disabledLogic */
  ['disabledLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:disabledLogic */
  /* handler:optionsLogic */
  async ['optionsLogic'](___arguments) {
    var options, item, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  options = (getObjectProperty(___arguments.context.pageData, 'orgs'));
  options = (await asyncListFilter(options, async (item) => {


   return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'), 'objectId')) && !(getObjectProperty(item, 'parent.objectId')));
  }));
  console.log(options);
  options = (await Promise.all(options.map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'objectId')),[`label`]: (getObjectProperty(item, 'orgName')) });})));
  options = (await asyncListSort(options, 1, async (item) => {


   return (getObjectProperty(item, 'label'));
  }));

  return options

  },
  /* handler:optionsLogic */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var parentId, item, _RESULTS;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}


  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'), 'parent.objectId', ___arguments['inputValue']);
  ___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58')['parentName'] = (getObjectProperty((await asyncListFind((getObjectProperty(___arguments.context.pageData, 'orgs')), async (item) => {


   return ((getObjectProperty(item, 'objectId')) == ___arguments['inputValue']);
  })), 'orgName'));

  },
  /* handler:onChange */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'), 'parent.objectId')) {
    return 'Parent';
  } else {
    return 'Select Parent';
  }

  },
  /* handler:placeholderLogic */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58')['hasParent'] = false;

  },
  /* handler:onButtonClearClick */
  /* content */
}))

define('./pages/clients/components/92413139913bb672907b0dcdb697381e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/d408e8cb482de527d2d280b887117e62/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var parentName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  parentName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'), 'parentName'));
  if (parentName) {
    return (String('Part of : ') + String(parentName));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/clients/components/47cca07874dae94514fc4f0e2198750b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'), 'hasParent')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'), 'parentName')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/clients/components/63654f16ce7fb52502d1b1d871ee8409/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'ui.editable')) {
    return ['editableForm'];
  } else {
    return ;
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/clients/components/f1b60dffe099319bb43ec501c622d4fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['ui.editable'] = true;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/clients/components/7bf4c7e8b00fc21551191d74a87243c8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.filters', (___arguments['selectValue'].map(item => item['value'])));
  await BackendlessUI.Functions.Custom['fn_03879a2285ff44c48fea35816ca5fc9e'](___arguments.context.pageData, ___arguments.context.appData);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/clients/components/faa36d872b1e6eb49a4d8e6ce25a6908/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedOrg'] = ({ [`objectId`]: 'NEW',[`sameContact`]: true });
  ___arguments.context.pageData['ui.editable'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/clients/components/0712323496394ee2eb0ba1183a251e58/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, selected, item, response, _DATA, oldParent;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}

/**
 * Describe this function...
 */
async function enrich() {
  if ((getObjectProperty(_DATA, 'objectId')) == 'NEW') {
    delete ___arguments.context.dataModel['objectId'];
  }
  if (getObjectProperty(_DATA, 'sameContact')) {
    Object.assign(_DATA, ({ [`billingEmail`]: (getObjectProperty(_DATA, 'bookingEmail')),[`billingName`]: (getObjectProperty(_DATA, 'bookingName')),[`billingPhone`]: (getObjectProperty(_DATA, 'bookingPhone')) }));
  }
  if (!(getObjectProperty(_DATA, 'parent.objectId')) || !(getObjectProperty(_DATA, 'hasParent'))) {
    console.log('No Parents');
    oldParent = (getObjectProperty(___arguments.context.pageData, 'selectedOrg_original.parent'));
    if (oldParent) {
      console.log('previously had parent');
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.dataModel, 'parent.objectId', 'DELETE');
    } else {
      console.log('previously no parent');
      delete ___arguments.context.dataModel['parent'];
    }
  }
}


  try {
    _DATA = ___arguments.context.dataModel;
    await enrich();
    response = (await Backendless.Request.put(`${Backendless.appPath}/services/ORGS/v1/org`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'data': ___arguments.context.dataModel,'orgType': 'clients' }));
    await BackendlessUI.Functions.Custom['fn_03879a2285ff44c48fea35816ca5fc9e'](___arguments.context.pageData, ___arguments.context.appData);
    if ((getObjectProperty(___arguments.context.dataModel, 'objectId')) == 'NEW') {
      (function (message) { alert(message) })('Client Added! 🎉🎉');
    } else {
      (function (message) { alert(message) })('Client Updated! 🎉🎉');
    }
    selected = (await asyncListFind((getObjectProperty(___arguments.context.pageData, 'orgs')), async (item) => {


     return ((getObjectProperty(response, 'objectId')) == (getObjectProperty(item, 'objectId')));
    }));
    ___arguments.context.pageData['selectedOrg'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selected));
    ___arguments.context.pageData['selectedOrg_original'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selected));
    ___arguments.context.pageData['ui.editable'] = false;

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  async ['onReset'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments.context.pageData, 'selectedOrg_original'))));
  ___arguments.context.pageData['ui.editable'] = false;

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/clients/components/0e374272579cae851b08ffb967c7c1da/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/482753a88366bee867f3c67ad527ace3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/935faf5f75e24426fad76eaaa548291d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/3b662ed60fe6f72dcb810c68f05fb6ae/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/clients/components/fb080b78a5ef5332afaf19dbfb6895d1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/dashboard/components/426986a905f1df638d4603770f598f63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_3364675c1c882e21413ac8fdc3b4cdae'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/42585b63916eab778e74cb9f9cab98ee/bundle.js', [], () => ({
  /* content */
  /* handler:rowsDataLogic */
  ['rowsDataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'cashflow'))

  },
  /* handler:rowsDataLogic */
  /* content */
}))

define('./pages/dashboard/components/75571ba995b4ff1e53a76e918b022abe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_cfb7c4ca60cd0925e266e26da4a56c6f'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/42c3a413196977aaae54e1ff9ddaaedc/bundle.js', [], () => ({
  /* content */
  /* handler:rowsDataLogic */
  ['rowsDataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'netNew'))

  },
  /* handler:rowsDataLogic */
  /* content */
}))

define('./pages/dashboard/components/3d8ff4192d2feb27dcd53e325ef2dccd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var delta, end, start, monthArray, targets, updatedSessions, whatHappened, j, DELTA_START_END, USER_ID;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  USER_ID = (getObjectProperty(___arguments.context.appData, 'currentUser.objectId'));
  DELTA_START_END = (getObjectProperty(___arguments.context.pageData, 'ui.deltaStartEnd'));
  console.log('LOADING ALL SESSIONS');
  updatedSessions = (await Backendless.Data.of('sessions').find(Backendless.DataQueryBuilder.create().setWhereClause((['client.accountOwner.objectId = \'',USER_ID,'\' AND marginTracker.timestamp >= ',(getObjectProperty(DELTA_START_END, 'start')),' AND marginTracker.timestamp <= ',(getObjectProperty(DELTA_START_END, 'end'))].join(''))).setRelationsDepth(1).setGroupBy('objectId').setPageSize(100)));
  console.log(updatedSessions);
  console.log('ENRICH WITH AUDIT TRAIL DATA');
  for (var j_index in updatedSessions) {
    j = updatedSessions[j_index];
    whatHappened = (await BackendlessUI.Functions.Custom['fn_b7aeb43c64755fe4b7675ee9c32c6b1a']((getObjectProperty(DELTA_START_END, 'start')), (getObjectProperty(DELTA_START_END, 'end')), (getObjectProperty(j, 'marginTracker'))));
    Object.assign(j, ({ [`marginDeltaSum`]: (getObjectProperty(whatHappened, 'marginDeltaSum')),[`startStatus`]: (getObjectProperty(whatHappened, 'startStatus')),[`endStatus`]: (getObjectProperty(whatHappened, 'endStatus')),[`statusChange`]: [(getObjectProperty(whatHappened, 'startStatus')),'→',(getObjectProperty(whatHappened, 'endStatus'))].join('') }));
  }
  console.log('GROUP BY CLIENTS');
  updatedSessions = (await (async function(sessions) {
  	const groupedSessions = {};

  	sessions.forEach(session => {
  	    const clientId = session.client.objectId;
  	    if (!groupedSessions[clientId]) {
  	        groupedSessions[clientId] = {
  	            client: session.client,
  	            sessions: []
  	        };
  	    }
  	    groupedSessions[clientId].sessions.push(session);
  	});

  	// Sort the sessions for each client by session.start
  	Object.values(groupedSessions).forEach(clientGroup => {
  	    clientGroup.sessions.sort((a, b) => new Date(a.start) - new Date(b.start));
  	});

  	// Convert the grouped sessions object to an array
  	const result = Object.values(groupedSessions);
  	return result;
  })(updatedSessions));
  console.log(updatedSessions);
  ___arguments.context.pageData['updatedSessions'] = updatedSessions;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var timePeriods;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.year', (new Date((new Date())).getFullYear()));
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.deltaPeriod', 'thisWeek');
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.deltaStartEnd', (getObjectProperty((await BackendlessUI.Functions.Custom['fn_4efc42e29da30fb6e3ef1b6212d5cb4c']()), 'thisWeek')));
  if (!(getObjectProperty(___arguments.context.appData, 'cashflow'))) {
    await BackendlessUI.Functions.Custom['fn_3364675c1c882e21413ac8fdc3b4cdae'](___arguments.context.appData, ___arguments.context.pageData);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/dashboard/components/aa0d80a16c98e3ab0475f7b13b5678ca/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_f3867a1d5db145aa5a0581ffde6c8f6d']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8b7cfe419b9eee74b0826e4abe3cca76'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8b7cfe419b9eee74b0826e4abe3cca76'), 'end'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/0118a8c8d364ec39a0f7a55a288ad379/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var timePeriods;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.deltaStartEnd', (getObjectProperty((await BackendlessUI.Functions.Custom['fn_4efc42e29da30fb6e3ef1b6212d5cb4c']()), ___arguments.value)));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboard/components/19f9cf1f55f7d0c28d81766ae785f98e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Session (',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('bbe47eea2aa6565f84cf342a716ea4c5'), 'sessions')).length,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/9a97430b65235346efd2cc7569366df6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Locum (',(await (async function(sessions) {
	  const locumSet = new Set();

	  sessions.forEach(session => {
	    if (session.locum && session.locum.objectId) {
	      locumSet.add(session.locum.objectId);
	    }
	  });

	  return locumSet.size;
})((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bbe47eea2aa6565f84cf342a716ea4c5'), 'sessions')))),')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/06795fceae11b27c00cbf7fba776cfbf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Margin this period (£',(Number.parseFloat((await (async function(sessions) {
	  let totalMarginDeltaSum = 0;

	  sessions.forEach(session => {
	    if (typeof session.marginDeltaSum === 'number') {
	      totalMarginDeltaSum += session.marginDeltaSum;
	    }
	  });

	  return totalMarginDeltaSum;
})((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bbe47eea2aa6565f84cf342a716ea4c5'), 'sessions'))))).toFixed(2)),')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/2f42ab822ece644696fd7779bc94bc9b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String(Number.parseFloat((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8b7cfe419b9eee74b0826e4abe3cca76'), 'marginDeltaSum'))).toFixed(2)))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/invoice/components/caabe3767f239893514dc97f358d072a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('99b3adabbc698c72a12798344743b68c')) == 'factor')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/invoice/components/fc12a1c36d5b5b64807c568e82e448ed/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('99b3adabbc698c72a12798344743b68c')) == 'direct')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/invoice/components/86f95ceee73923854b1e43083496a85e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_79618ef2c5230b4959bec167239763fa'](null, ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/invoice/components/1ffbc348c185f24f6ecaeadde5eccbe3/bundle.js', [], () => ({
  /* content */
  /* handler:rowsDataLogic */
  ['rowsDataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'timesheets'))

  },
  /* handler:rowsDataLogic */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(text) {
  	    // Create a temporary text area element
  	    const tempTextArea = document.createElement("textarea");
  	    tempTextArea.value = text;

  	    // Add it to the document body (this is required for the copy command to work)
  	    document.body.appendChild(tempTextArea);

  	    // Select the text
  	    tempTextArea.select();
  	    tempTextArea.setSelectionRange(0, 99999); // For mobile devices

  	    // Execute the copy command
  	    document.execCommand("copy");

  	    // Remove the temporary text area element
  	    document.body.removeChild(tempTextArea);

  	    console.log("Text copied to clipboard: ", text);
  })((getObjectProperty(___arguments['cellParams'], 'data.timesheetId')));
  ___arguments.context.pageData['selectedRows'] = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('1ffbc348c185f24f6ecaeadde5eccbe3'));

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/invoice/components/38a6940cce06fdff13f196cffbfdad7d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, response, results, timesheetIds, syncText, j, sessionMsg, syncTextItems;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function buildResponseText(response) {
  syncText = '';
  syncTextItems = null;
  sessionMsg = (getObjectProperty(response, 'success'));
  if (!!sessionMsg.length) {
    syncText = String(syncText) + String(['<br><b>',sessionMsg.length,' Success </b>'].join(''));
    for (var j_index in sessionMsg) {
      j = sessionMsg[j_index];
      syncText = String(syncText) + String(['<br>• Timesheet ID: ',(getObjectProperty(j, 'submitId')),' (HPS ID: ',(getObjectProperty(j, 'objectId')),')'].join(''));
    }
  }
  sessionMsg = (getObjectProperty(response, 'existing'));
  if (!!sessionMsg.length) {
    syncText = String(syncText) + String(['<br><b>',sessionMsg.length,' Existing </b> (timesheets not submitted as they already exist on HPS. Ask them to delete first before resubmitting or ask them to update HPS system directly for any changes)'].join(''));
    for (var j_index2 in sessionMsg) {
      j = sessionMsg[j_index2];
      syncText = String(syncText) + String(['<br>• Timesheet ID: ',(getObjectProperty(j, 'submitId')),' (HPS ID: ',(getObjectProperty(j, 'objectId')),')<br>'].join(''));
    }
  }
  sessionMsg = (getObjectProperty(response, 'failed'));
  if (!!sessionMsg.length) {
    syncText = String(syncText) + String(['<br><b>',sessionMsg.length,' Failed </b>'].join(''));
    for (var j_index3 in sessionMsg) {
      j = sessionMsg[j_index3];
      syncText = String(syncText) + String(['<br>• Timesheet ID: ',(getObjectProperty(j, 'submitId')),' (ERROR: ',(getObjectProperty(j, 'SERVER_MSG')),')'].join(''));
    }
  }
  console.log(syncText);
  return syncText
}


  try {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('38a6940cce06fdff13f196cffbfdad7d', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5d1402395b0d1cf403fe6d847563d9f8', true);
    timesheetIds = ((getObjectProperty(___arguments.context.pageData, 'selectedRows')).map(item => item['timesheetId']));
    response = (await Backendless.Request.post(`${Backendless.appPath}/services/INVOICES/sendToHps`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(timesheetIds)));
    results = [];
    if (getObjectProperty(response, 'existing')) {
      addItemToList(results, (getObjectProperty(response, 'existing')));
    }
    if (getObjectProperty(response, 'success')) {
      addItemToList(results, (getObjectProperty(response, 'success')));
    }
    ___arguments.context.pageData['response'] = (await buildResponseText(response));

  } catch (error) {
    (function (message) { alert(message) })(error);

  } finally {
    await BackendlessUI.Functions.Custom['fn_79618ef2c5230b4959bec167239763fa']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')), ___arguments.context.pageData);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('38a6940cce06fdff13f196cffbfdad7d', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5d1402395b0d1cf403fe6d847563d9f8', false);
    delete ___arguments.context.pageData['selectedRows'];

  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.pageData, 'selectedRows'))))) + String(' selected. Push to HPS'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_8d4b531b860a4ba62584e6712dfb87eb']((getObjectProperty(___arguments.context.pageData, 'selectedRows'))))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/invoice/components/0d99e50d2f43242e8c0ae5dd6e416a4d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'response'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/invoice/components/1303fc09447b222daeaa015f4396b906/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'response'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/loading/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var targets, error, roles, currentOrgId, redirectPage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadLocums() {
}

/**
 * Describe this function...
 */
async function setVars() {
  ___arguments.context.appData['processors'] = [({ [`processor`]: 'HPS',[`fee`]: 0.0399 }), ({ [`processor`]: 'DIRECT',[`fee`]: 0 })];
  ___arguments.context.appData['vat'] = 0.2;
}

/**
 * Describe this function...
 */
async function loadTargets() {
  targets = (await Backendless.Data.of('targets').find(Backendless.DataQueryBuilder.create().setWhereClause((['consultant.objectId = \'',(getObjectProperty(___arguments.context.appData, 'currentUser.objectId')),'\''].join(''))).setPageSize(20)));
  ___arguments.context.appData['userTargets'] = targets;
}

/**
 * Describe this function...
 */
async function directToLoginPage() {
  if (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).slice(0, 6) != 'public') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('publicLogin', undefined);
  }
}

/**
 * Describe this function...
 */
async function getUserOrgs() {
  try {
    ___arguments.context.appData['userOrgs'] = (await Backendless.Request.get(`${Backendless.appPath}/services/USERS/UserOrgs`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());

  } catch (error) {
    (function (message) { alert(message) })(error);

  }
}

/**
 * Describe this function...
 */
async function setTimeZone() {
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.appData, 'isUkTimezone', (await (async function() {
  	    try {
  	        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  	        return timezone === 'Europe/London';
  	    } catch (error) {
  	        console.error('Unable to detect timezone:', error);
  	        return false; // Return false if timezone detection fails
  	    }
  })()));
}

/**
 * Describe this function...
 */
async function checkAndGetUserItems() {
}

/**
 * Describe this function...
 */
async function getUserInfo() {
  if (!(getObjectProperty(___arguments.context.appData, 'currentUser'))) {
    ___arguments.context.appData['currentUser'] = (await Backendless.UserService.getCurrentUser(false));
    console.log('GETTING USER ROLES');
    roles = (await Backendless.UserService.getUserRoles());
    Object.assign((getObjectProperty(___arguments.context.appData, 'currentUser')), ({ [`roles`]: roles }));
    console.log(roles);
  }
}

/**
 * Describe this function...
 */
async function loadLibraries() {
  await BackendlessUI.Functions.Custom['fn_1aea58e1ea0daedb71a7a1544e2963e5']();
}

/**
 * Describe this function...
 */
async function setCurrentOrg() {
  currentOrgId = (getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'lastUsedOrgId'));
  ___arguments.context.appData['currentOrg'] = (await BackendlessUI.Functions.Custom['fn_11ee3094f9ea7cfc9fc548a8b2383f08'](currentOrgId, 'objectId', (getObjectProperty(___arguments.context.appData, 'userOrgs'))));
  if (!(getObjectProperty(___arguments.context.appData, 'currentOrg'))) {
    currentOrgId = (getObjectProperty((await (async function(data, property) {
    	  // Helper function to recursively search for the property in nested objects or arrays
    	  function searchInObject(obj) {
    	    for (const key in obj) {
    	      if (obj.hasOwnProperty(key)) {
    	        if (Array.isArray(obj[key])) {
    	          // Iterate through the array and search for the object containing the property
    	          for (const item of obj[key]) {
    	            if (item.hasOwnProperty(property)) {
    	              return item;
    	            }
    	          }
    	        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
    	          // Recursively search in nested objects
    	          const result = searchInObject(obj[key]);
    	          if (result) {
    	            return result;
    	          }
    	        }
    	      }
    	    }
    	    return null;
    	  }

    	  // Start the search
    	  return searchInObject(data);
    })((getObjectProperty(___arguments.context.appData, 'userOrgs')), 'objectId')), 'objectId'));
    await Backendless.Data.of('Users').save(({ [`objectId`]: (getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')),[`lastUsedOrgId`]: currentOrgId }), true);
    ___arguments.context.appData['currentOrg'] = (await BackendlessUI.Functions.Custom['fn_11ee3094f9ea7cfc9fc548a8b2383f08'](currentOrgId, 'objectId', (getObjectProperty(___arguments.context.appData, 'userOrgs'))));
  }
}

/**
 * Describe this function...
 */
async function checkIfAdmin() {
}

/**
 * Describe this function...
 */
async function redirectToPage() {
  redirectPage = (getObjectProperty(___arguments.context.pageData, 'redirect'));
  if (redirectPage && redirectPage != ((function () { return BackendlessUI.Navigator.getCurrentPageName() })())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })(redirectPage, undefined);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', undefined);
  }
}


  console.log('\\/ ======== [Loading Page] ======== \\/');
  console.log('Source page:');
  console.log(getObjectProperty(___arguments.context.pageData, 'redirect'));
  await loadLibraries();
  await getUserInfo();
  await getUserOrgs();
  await setCurrentOrg();
  await checkAndGetUserItems();
  await checkIfAdmin();
  await loadTargets();
  await setVars();
  await setTimeZone();
  await BackendlessUI.Functions.Custom['fn_3f69fa33122800d9d1545a8a547afb0a'](___arguments.context.appData, (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')), (getObjectProperty(___arguments.context.appData, 'currentUser.objectId')), null, null);
  await BackendlessUI.Functions.Custom['fn_48a1a5fc2c6b5b463f468f6fc5b4c1d6'](___arguments.context.appData, (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')), (getObjectProperty(___arguments.context.appData, 'currentUser.objectId')), null, null);
  console.log('---> App Data:');
  console.log(___arguments.context.appData);
  console.log('/\\ ======== [Loading Page] ======== /\\');
  await new Promise(r => setTimeout(r, 500 || 0));
  await redirectToPage();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/locums/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var currentOrg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('%c>> ' + 'Page Enter', 'color: null', undefined);
  currentOrg = (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'));
  if (currentOrg) {
    await BackendlessUI.Functions.Custom['fn_5e91068a76772dc26bed68786e14880c'](___arguments.context.pageData, ___arguments.context.appData);
  }
  console.log('%c>> ' + 'Page Finish', 'color: null', undefined);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/locums/components/d765db5857579345749d3f665b38e7c7/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var selected, selectedId, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}


  if (getObjectProperty(___arguments.context.pageData, 'ui.editable')) {
    (function (message) { alert(message) })('Please save or cancel changes first.');
  } else {
    selectedId = (getObjectProperty(___arguments['cellParams'], 'data.locumId'));
    selected = (await asyncListFind((getObjectProperty(___arguments.context.pageData, 'orgs')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) == selectedId);
    }));
    ___arguments.context.pageData['selectedOrg'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selected));
    ___arguments.context.pageData['selectedOrg_original'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selected));
    ___arguments.context.pageData['ui.editable'] = false;
  }

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/locums/components/f565417e7fb7a94cc5b5b07d6e2ea88e/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'), 'objectId')) == 'NEW') {
    return 'Add Locum';
  } else {
    return 'Save Changes';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/locums/components/87aacb88039d06bcaebccd56b9e2c515/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var orgName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgName = (getObjectProperty(___arguments.context.pageData, 'selectedOrg.orgName'));
  if (orgName) {
    return orgName;
  }

  return 'New Locum'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/locums/components/45eb8cbc0b5e25ebbdc4cab5ea6525ae/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('c4a3d0764ec693ad35489df297947a4b');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/locums/components/c4a3d0764ec693ad35489df297947a4b/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('c4a3d0764ec693ad35489df297947a4b');
  try {
    await Backendless.Request.delete(`${Backendless.appPath}/services/ORGS/v1/org`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'orgType': 'locums','objectId': (getObjectProperty(___arguments.context.pageData, 'selectedOrg.objectId')) });
    ___arguments.context.pageData['selectedOrg'] = null;
    await BackendlessUI.Functions.Custom['fn_5e91068a76772dc26bed68786e14880c'](___arguments.context.pageData, ___arguments.context.appData);
    (function (message) { alert(message) })('Locum Deleted! 🗑️');
    ___arguments.context.pageData['ui.editable'] = false;

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/locums/components/ec658cc37188a366c3e41a63b5931f22/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var options, option, j, orgUsers, list, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  orgUsers = (getObjectProperty(___arguments.context.appData, 'currentOrg.users'));
  options = [({ [`value`]: 'DELETE',[`label`]: '-- UNASSIGN --' })];
  if (orgUsers) {
    for (var j_index in orgUsers) {
      j = orgUsers[j_index];
      option = ({ [`value`]: (getObjectProperty(j, 'objectId')),[`label`]: (getObjectProperty(j, 'name')) });
      addItemToList(options, option);
    }
  }
  options = (await asyncListSort(options, 1, async (item) => {


   return (getObjectProperty(j, 'label'));
  }));

  return options

  },
  /* handler:onOptionsAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/cfc876a37f91eaf6436d26cdae8c9eb1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'ui.editable'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/locums/components/f1229309ef0c27f1c6163ed1fb135be7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/b008aa43458e7466ff822a9d500603b3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/202bf31eb7497a75476ffc430b0e68ff/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/97b458fccbd9a2671cd397da75852f3c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/fcf5769e42e4ff89cd11b28b915c691b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/aa8a0389a3652f263ae728f1546c4934/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/b713a68662e668379f0afb68c1b36d1d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/b4765e57a2a6560c99ba7ac812317204/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/9ca6640c90c18d7e641a07e77fe109ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/63654f16ce7fb52502d1b1d871ee8409/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'ui.editable')) {
    return ['editableForm'];
  } else {
    return ;
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/locums/components/f1b60dffe099319bb43ec501c622d4fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['ui.editable'] = true;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/locums/components/7bf4c7e8b00fc21551191d74a87243c8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.filters', (___arguments['selectValue'].map(item => item['value'])));
  await BackendlessUI.Functions.Custom['fn_5e91068a76772dc26bed68786e14880c'](___arguments.context.pageData, ___arguments.context.appData);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/locums/components/faa36d872b1e6eb49a4d8e6ce25a6908/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedOrg'] = ({ [`objectId`]: 'NEW',[`sameContact`]: true });
  ___arguments.context.pageData['ui.editable'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/locums/components/0712323496394ee2eb0ba1183a251e58/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, selected, item, response, _DATA, oldParent;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}

/**
 * Describe this function...
 */
async function enrich() {
  if ((getObjectProperty(_DATA, 'objectId')) == 'NEW') {
    delete ___arguments.context.dataModel['objectId'];
  }
  if (!(getObjectProperty(_DATA, 'parent.objectId')) || !(getObjectProperty(_DATA, 'hasParent'))) {
    console.log('No Parents');
    oldParent = (getObjectProperty(___arguments.context.pageData, 'selectedOrg_original.parent'));
    if (oldParent) {
      console.log('previously had parent');
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.dataModel, 'parent.objectId', 'DELETE');
    } else {
      console.log('previously no parent');
      delete ___arguments.context.dataModel['parent'];
    }
  }
}


  try {
    _DATA = ___arguments.context.dataModel;
    await enrich();
    response = (await Backendless.Request.put(`${Backendless.appPath}/services/ORGS/v1/org`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'data': _DATA,'orgType': 'locums' }));
    await BackendlessUI.Functions.Custom['fn_5e91068a76772dc26bed68786e14880c'](___arguments.context.pageData, ___arguments.context.appData);
    if ((getObjectProperty(___arguments.context.dataModel, 'objectId')) == 'NEW') {
      (function (message) { alert(message) })('Locum Added! 🎉🎉');
    } else {
      (function (message) { alert(message) })('Locum Updated! 🎉🎉');
    }
    selected = (await asyncListFind((getObjectProperty(___arguments.context.pageData, 'orgs')), async (item) => {


     return ((getObjectProperty(response, 'objectId')) == (getObjectProperty(item, 'objectId')));
    }));
    ___arguments.context.pageData['selectedOrg'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selected));
    ___arguments.context.pageData['selectedOrg_original'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selected));
    ___arguments.context.pageData['ui.editable'] = false;

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  async ['onReset'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments.context.pageData, 'selectedOrg_original'))));
  ___arguments.context.pageData['ui.editable'] = false;

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/locums/components/6018079a2e9d0173240770fd4c2cab5a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/03d440f73aa9b7810c08c1a4948f8501/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/c998cb4efe66a5bef3450a350e9df69d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/1d2d455aaa5f6224522f34858ffcca6b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums/components/a7ddccfab59d81c1ac64ebb395c4027f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var currentOrg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentOrg = (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'));
  if (currentOrg) {
    await BackendlessUI.Functions.Custom['fn_5e91068a76772dc26bed68786e14880c'](___arguments.context.pageData, ___arguments.context.appData);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/locums-old/components/d765db5857579345749d3f665b38e7c7/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments['cellParams'], 'data'))));
  ___arguments.context.pageData['selectedOrg_original'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments['cellParams'], 'data'))));
  ___arguments.context.pageData['ui.editable'] = false;

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/locums-old/components/f565417e7fb7a94cc5b5b07d6e2ea88e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function update() {
  await Backendless.Request.put(`${Backendless.appPath}/services/ORGS/updateOrg`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'data': ___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'),'orgType': 'locums' });
  (function (message) { alert(message) })('Locum Updated 🎉🎉');
}

/**
 * Describe this function...
 */
async function add() {
  await Backendless.Request.post(`${Backendless.appPath}/services/ORGS/addOrg`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'orgType': 'locums','data': ___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58') });
  (function (message) { alert(message) })('Locum Added 🎉🎉');
}


  try {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0712323496394ee2eb0ba1183a251e58'), 'objectId')) == 'new') {
      await add();
    } else {
      await update();
    }
    await BackendlessUI.Functions.Custom['fn_5e91068a76772dc26bed68786e14880c'](___arguments.context.pageData, ___arguments.context.appData);

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/locums-old/components/3dd75d37dd0fc1f5110b7c3da7642763/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['showArchive'] = (!(getObjectProperty(___arguments.context.pageData, 'showArchive')));
  await BackendlessUI.Functions.Custom['fn_5e91068a76772dc26bed68786e14880c'](___arguments.context.pageData, ___arguments.context.appData);

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'showArchive')) {
    return 'hide archived';
  }

  return 'show archived'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/locums-old/components/a33b55ca284edac752d589615e720d9b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['ui.editable'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/locums-old/components/a27ef116652c219fbd591fcea2d4c759/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/locums-old/components/e94f3307369cd864227802521f4b4dad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedOrg'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments.context.pageData, 'selectedOrg_original'))));
  ___arguments.context.pageData['ui.editable'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/locums-old/components/f1229309ef0c27f1c6163ed1fb135be7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/cfc876a37f91eaf6436d26cdae8c9eb1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'ui.editable'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/locums-old/components/80686f076e29f9e794c4cc1f2b27ceeb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/fcf5769e42e4ff89cd11b28b915c691b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/aa8a0389a3652f263ae728f1546c4934/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/202bf31eb7497a75476ffc430b0e68ff/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/97b458fccbd9a2671cd397da75852f3c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/b008aa43458e7466ff822a9d500603b3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/b713a68662e668379f0afb68c1b36d1d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/02322f148b857a0ca40ef1812c9b258e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedOrg'] = ({ [`objectId`]: 'new' });
  ___arguments.context.pageData['ui.editable'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/locums-old/components/87aacb88039d06bcaebccd56b9e2c515/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var orgName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgName = (getObjectProperty(___arguments.context.pageData, 'selectedOrg.orgName'));
  if (orgName) {
    return orgName;
  }

  return 'New Locum'

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/locums-old/components/45eb8cbc0b5e25ebbdc4cab5ea6525ae/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('c4a3d0764ec693ad35489df297947a4b');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/locums-old/components/c4a3d0764ec693ad35489df297947a4b/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('c4a3d0764ec693ad35489df297947a4b');
  try {
    await Backendless.Request.delete(`${Backendless.appPath}/services/ORGS/Org`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'orgType': 'locums','objectId': (getObjectProperty(___arguments.context.pageData, 'selectedOrg.objectId')) });
    ___arguments.context.pageData['selectedOrg'] = null;
    await BackendlessUI.Functions.Custom['fn_5e91068a76772dc26bed68786e14880c'](___arguments.context.pageData, ___arguments.context.appData);
    (function (message) { alert(message) })('Locum Deleted! 🗑️');

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/locums-old/components/064f75ba44d7cdbf2aea5337483f9868/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/39af9f26149e4bc5de1b439301e49307/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/locums-old/components/a80e3681faac8f64c3d2714ea808d656/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/profile/components/f79a97f48c31acfb194372755511513e/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    var options, option, j, orgTypes, userOrgs;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userOrgs = (getObjectProperty(___arguments.context.appData, 'userOrgs'));
  if (userOrgs) {
    orgTypes = (Object.keys(userOrgs));
    options = [];
    for (var j_index in orgTypes) {
      j = orgTypes[j_index];
      option = ({ [`value`]: j,[`label`]: textToTitleCase((await BackendlessUI.Functions.Custom['fn_3b3f85c9afed14257c2a26e3e2360d4c'](j))) });
      addItemToList(options, option);
    }
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('59c6028da9f3fee72575ccdcdf21466a')['selectedOrgId'] = null;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/profile/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    
  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/profile/components/bdf83e51fbe218ab335a7aa39a9d1aa9/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    var options, option, j, orgs, orgType;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  orgType = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('59c6028da9f3fee72575ccdcdf21466a'), 'orgType'));
  orgs = (getObjectProperty(___arguments.context.appData, (String('userOrgs.') + String(orgType))));
  options = [];
  for (var j_index in orgs) {
    j = orgs[j_index];
    option = ({ [`value`]: (getObjectProperty(j, 'objectId')),[`label`]: (getObjectProperty(j, 'orgName')) });
    addItemToList(options, option);
  }

  return options

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/profile/components/59c6028da9f3fee72575ccdcdf21466a/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var currentOrgId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentOrgId = (getObjectProperty(___arguments.context.dataModel, 'selectedOrgId'));
  ___arguments.context.appData['currentOrg'] = (await BackendlessUI.Functions.Custom['fn_11ee3094f9ea7cfc9fc548a8b2383f08'](currentOrgId, 'objectId', (getObjectProperty(___arguments.context.appData, 'userOrgs'))));
  await Backendless.Data.of('Users').save(({ [`objectId`]: (getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')),[`lastUsedOrgId`]: currentOrgId }), true);

  },
  /* handler:onSubmit */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['orgType'] = (getObjectProperty(___arguments.context.appData, 'currentOrg.___class'));
  ___arguments.context.dataModel['selectedOrgId'] = (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'));

  },
  /* handler:onMounted */
  /* handler:onReset */
  ['onReset'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['orgType'] = (getObjectProperty(___arguments.context.appData, 'currentOrg.___class'));
  ___arguments.context.dataModel['selectedOrgId'] = (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'));

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/publicLogin/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, stayLoggedIn, password, username, user, logging, buttonLogin;

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loading', undefined);
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/sessions/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var year, month;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  month = (new Date((new Date())).getMonth() + 1);
  year = (new Date((new Date())).getFullYear());
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('50fb1789a23936dd1e2c482629239481', month);
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('0e4a97fa906a638abf47f30c7b2a2ec5', year);
  (getObjectProperty(___arguments.context.pageData, 'ui'))['locumFilter'] = ({ [`objectId`]: undefined });
  (getObjectProperty(___arguments.context.pageData, 'ui'))['clientFilter'] = ({ [`objectId`]: undefined });
  (getObjectProperty(___arguments.context.pageData, 'ui'))['editable'] = true;
  (getObjectProperty(___arguments.context.pageData, 'ui'))['nonOwned'] = true;
  await BackendlessUI.Functions.Custom['fn_6ae1d56914c71148f9362167743ced54'](___arguments.context.pageData, ___arguments.context.appData);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/sessions/components/b9df3d03369e92fccea71f9bb09adda2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  newDate = (await BackendlessUI.Functions.Custom['fn_30e666660b38ecf7e5e3d027a1b44bef'](((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('50fb1789a23936dd1e2c482629239481')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('0e4a97fa906a638abf47f30c7b2a2ec5')), 1));
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('50fb1789a23936dd1e2c482629239481', (getObjectProperty(newDate, 'month')));
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('0e4a97fa906a638abf47f30c7b2a2ec5', (getObjectProperty(newDate, 'year')));
  await BackendlessUI.Functions.Custom['fn_6ae1d56914c71148f9362167743ced54'](___arguments.context.pageData, ___arguments.context.appData, null, null);

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    var month, year;


  month = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('50fb1789a23936dd1e2c482629239481'));
  year = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('0e4a97fa906a638abf47f30c7b2a2ec5'));

  return (year == 2030 && month == 12 ? true : false)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/sessions/components/da2ea742810d394dfe7e8eb4bd57e8b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  newDate = (await BackendlessUI.Functions.Custom['fn_30e666660b38ecf7e5e3d027a1b44bef'](((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('50fb1789a23936dd1e2c482629239481')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('0e4a97fa906a638abf47f30c7b2a2ec5')), -1));
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('50fb1789a23936dd1e2c482629239481', (getObjectProperty(newDate, 'month')));
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('0e4a97fa906a638abf47f30c7b2a2ec5', (getObjectProperty(newDate, 'year')));
  await BackendlessUI.Functions.Custom['fn_6ae1d56914c71148f9362167743ced54'](___arguments.context.pageData, ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/sessions/components/cecdae2a33eb8e0f15ac5cc27d2671a7/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    var end;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.date')) != ___arguments['value']) {
    console.log('==== Update Raw Date String');
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start'))['date'] = ___arguments['value'];
    console.log(___arguments['value']);
    console.log(getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.date'));
    ___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1')['start'] = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.date')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.time'))));
    end = (await BackendlessUI.Functions.Custom['fn_b48df14473b24f1ae216bd5e59da9584']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'break')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'duration'))));
    ___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1')['end'] = end;
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates'))['end'] = ({ [`date`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](end)), 'shortDateString')),[`time`]: (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](end)), 'timeString')) });
  }

  },
  /* handler:onComplete */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.partialDate', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.partialDate')).indexOf('d') !== -1) || ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.partialDate')).indexOf('m') !== -1) || ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.partialDate')).indexOf('y') !== -1)) {
    console.log('invalid date detected');
    originalDate = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.date'));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start'))['date'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start'))['date'] = originalDate;
  }

  },
  /* handler:onLostFocusEvent */
  /* content */
}))

define('./pages/sessions/components/4c8bc5ad47ff9534e72c06b23b1dcefe/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.time')) != ___arguments['value']) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start'))['time'] = ___arguments['value'];
    ___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1')['start'] = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.date')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.time'))));
    ___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1')['end'] = (await BackendlessUI.Functions.Custom['fn_b48df14473b24f1ae216bd5e59da9584']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'break')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'duration'))));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.end'))['time'] = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'end')))), 'timeString'));
  }

  },
  /* handler:onComplete */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.partial', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalTime;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.partial')).indexOf('_') !== -1) {
    originalTime = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.time'));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start'))['time'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start'))['time'] = originalTime;
  }

  },
  /* handler:onLostFocusEvent */
  /* content */
}))

define('./pages/sessions/components/67cc43a90b43a84c6dd8c0f17e39183e/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    var break2, end, start, dayOffset, endTimeString, startDateString, startTimeString;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.end.time')) != ___arguments['value']) {
    console.log('=== DEBUG, New End Time string entered');
    start = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'start'));
    break2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'break'));
    startDateString = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.date'));
    startTimeString = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.time'));
    endTimeString = ___arguments['value'];
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.end'))['time'] = endTimeString;
    console.log('Update End');
    dayOffset = (await BackendlessUI.Functions.Custom['fn_3a2aee9d92cd50253b5175bcd681beb3'](startTimeString, endTimeString));
    console.log('dayOffset');
    console.log(dayOffset);
    end = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](startDateString, endTimeString)) + dayOffset * 86400000;
    console.log(end);
    ___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1')['end'] = end;
    console.log('Update Duration');
    ___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1')['duration'] = (await BackendlessUI.Functions.Custom['fn_b74c8eb6ac2467ea66e7b09125c4b966'](start, end, break2));
  }

  },
  /* handler:onComplete */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.end.partial', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalTime;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.end.partial')).indexOf('_') !== -1) {
    originalTime = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.end.time'));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.end'))['time'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.end'))['time'] = originalTime;
  }

  },
  /* handler:onLostFocusEvent */
  /* content */
}))

define('./pages/sessions/components/f14e5036b25adb68a1aaed58cdb2e7b9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1')['duration'] = (await BackendlessUI.Functions.Custom['fn_b74c8eb6ac2467ea66e7b09125c4b966']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'start')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'end')), ___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/sessions/components/bd192846230e9afd7061c9114bf4b8b1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedItem'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, results, session, sessions, repeatWeekday, repeatEnd, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function generateRepeatSessions() {
  repeatWeekday = [];
  for (i = 1; i <= 7; i++) {
    if (getObjectProperty(___arguments.context.dataModel, (String('repeat') + String(i)))) {
      addItemToList(repeatWeekday, i);
    }
  }
  repeatEnd = (getObjectProperty(___arguments.context.dataModel, '___ui.repeatEndTimestamp'));
  console.log('Repeat Selected. End date: ');
  console.log(repeatEnd);
  sessions = (await (async function(session, repeatEnd, repeatWeekdays) {
  	const { DateTime } = luxon; // Assuming luxon is already available globally

  	function parseDate(timestamp) {
  	  if (typeof timestamp === 'number') {
  	    // Timestamp in milliseconds
  	    return DateTime.fromMillis(timestamp);
  	  }
  	  return null;
  	}

  	const sessions = [];
  	const endDate = parseDate(repeatEnd);

  	if (!endDate) {
  	  console.error("Invalid end date:", repeatEnd);
  	  return sessions;
  	}

  	console.log("End Date:", endDate.toISO());

  	// Add the original session to the array
  	sessions.push(session);

  	let currentStart = parseDate(session.start);
  	let currentEnd = parseDate(session.end);

  	if (!currentStart || !currentEnd) {
  	  console.error("Invalid session start or end date:", session.start, session.end);
  	  return sessions;
  	}

  	console.log("Original Session Start:", currentStart.toISO());
  	console.log("Original Session End:", currentEnd.toISO());

  	while (true) {
  	  let nextSessionAdded = false;

  	  // Iterate over each weekday in the repeatWeekdays array
  	  for (const day of repeatWeekdays) {
  	    console.log("Checking weekday:", day);

  	    // Calculate the next session's start and end dates
  	    let nextStart = currentStart;
  	    let nextEnd = currentEnd;

  	    // Find the next occurrence of the given weekday
  	    if (currentStart.weekday <= day) {
  	      nextStart = currentStart.plus({ days: day - currentStart.weekday });
  	      nextEnd = currentEnd.plus({ days: day - currentEnd.weekday });
  	    } else {
  	      nextStart = currentStart.plus({ days: 7 - (currentStart.weekday - day) });
  	      nextEnd = currentEnd.plus({ days: 7 - (currentEnd.weekday - day) });
  	    }

  	    console.log("Next Start before adjustment:", nextStart ? nextStart.toISO() : 'null');
  	    console.log("Next End before adjustment:", nextEnd ? nextEnd.toISO() : 'null');

  	    // Ensure the next session is at least one week after the original session
  	    if (nextStart <= currentStart) {
  	      nextStart = nextStart.plus({ weeks: 1 });
  	      nextEnd = nextEnd.plus({ weeks: 1 });
  	    }

  	    console.log("Next Start after adjustment:", nextStart ? nextStart.toISO() : 'null');
  	    console.log("Next End after adjustment:", nextEnd ? nextEnd.toISO() : 'null');

  	    if (nextStart <= endDate) {
  	      nextSessionAdded = true;
  	      const newSession = {
  	        ...session,
  	        start: nextStart.toMillis(),
  	        end: nextEnd.toMillis()
  	      };
  	      sessions.push(newSession);
  	      console.log("Added new session:", newSession);
  	    } else {
  	      console.log("Next start date exceeds end date. No session added.");
  	    }
  	  }

  	  // If no new session was added, break the loop
  	  if (!nextSessionAdded) {
  	    console.log("No new sessions added. Exiting loop.");
  	    break;
  	  }

  	  // Move to the next week's sessions
  	  currentStart = currentStart.plus({ weeks: 1 });
  	  currentEnd = currentEnd.plus({ weeks: 1 });

  	  console.log("Current Start moved to:", currentStart.toISO());
  	  console.log("Current End moved to:", currentEnd.toISO());
  	}

  	return sessions;


  	// const { DateTime } = luxon; // Assuming luxon is already available globally

  	// function parseDate(date) {
  	//   if (typeof date === 'number') {
  	//     // Timestamp in milliseconds
  	//     return DateTime.fromMillis(date);
  	//   } else if (typeof date === 'string') {
  	//     // Attempt to parse as ISO date first
  	//     const isoDate = DateTime.fromISO(date);
  	//     if (isoDate.isValid) {
  	//       return isoDate;
  	//     } else {
  	//       // Fallback to parsing as custom format using JavaScript Date
  	//       const jsDate = new Date(date);
  	//       if (!isNaN(jsDate)) {
  	//         return DateTime.fromJSDate(jsDate);
  	//       }
  	//     }
  	//   }
  	//   return null;
  	// }


  	//   const sessions = [];
  	//   const endDate = parseDate(repeatEnd);

  	//   if (!endDate) {
  	//     console.error("Invalid end date:", repeatEnd);
  	//     return sessions;
  	//   }

  	//   console.log("End Date:", endDate.toISO());

  	//   // Add the original session to the array
  	//   sessions.push(session);

  	//   let currentStart = parseDate(session.start);
  	//   let currentEnd = parseDate(session.end);

  	//   if (!currentStart || !currentEnd) {
  	//     console.error("Invalid session start or end date:", session.start, session.end);
  	//     return sessions;
  	//   }

  	//   console.log("Original Session Start:", currentStart.toISO());
  	//   console.log("Original Session End:", currentEnd.toISO());

  	//   while (true) {
  	//     let nextSessionAdded = false;

  	//     // Iterate over each weekday in the repeatWeekdays array
  	//     for (const day of repeatWeekdays) {
  	//       console.log("Checking weekday:", day);

  	//       // Calculate the next session's start and end dates
  	//       let nextStart = currentStart;
  	//       let nextEnd = currentEnd;

  	//       // Find the next occurrence of the given weekday
  	//       if (currentStart.weekday <= day) {
  	//         nextStart = currentStart.plus({ days: day - currentStart.weekday });
  	//         nextEnd = currentEnd.plus({ days: day - currentEnd.weekday });
  	//       } else {
  	//         nextStart = currentStart.plus({ days: 7 - (currentStart.weekday - day) });
  	//         nextEnd = currentEnd.plus({ days: 7 - (currentEnd.weekday - day) });
  	//       }

  	//       console.log("Next Start before adjustment:", nextStart ? nextStart.toISO() : 'null');
  	//       console.log("Next End before adjustment:", nextEnd ? nextEnd.toISO() : 'null');

  	//       // Ensure the next session is at least one week after the original session
  	//       if (nextStart <= currentStart) {
  	//         nextStart = nextStart.plus({ weeks: 1 });
  	//         nextEnd = nextEnd.plus({ weeks: 1 });
  	//       }

  	//       console.log("Next Start after adjustment:", nextStart ? nextStart.toISO() : 'null');
  	//       console.log("Next End after adjustment:", nextEnd ? nextEnd.toISO() : 'null');

  	//       if (nextStart <= endDate) {
  	//         nextSessionAdded = true;
  	//         const newSession = {
  	//           ...session,
  	//           start: nextStart.toISO(),
  	//           end: nextEnd.toISO()
  	//         };
  	//         sessions.push(newSession);
  	//         console.log("Added new session:", newSession);
  	//       } else {
  	//         console.log("Next start date exceeds end date. No session added.");
  	//       }
  	//     }

  	//     // If no new session was added, break the loop
  	//     if (!nextSessionAdded) {
  	//       console.log("No new sessions added. Exiting loop.");
  	//       break;
  	//     }

  	//     // Move to the next week's sessions
  	//     currentStart = currentStart.plus({ weeks: 1 });
  	//     currentEnd = currentEnd.plus({ weeks: 1 });

  	//     console.log("Current Start moved to:", currentStart.toISO());
  	//     console.log("Current End moved to:", currentEnd.toISO());
  	//   }

  	//   return sessions;

  })(___arguments.context.dataModel, repeatEnd, repeatWeekday));
  if ((await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f'](sessions))) {
    console.log('arrayLength');
    console.log(sessions);
  }
  return sessions
}


  try {
    if ((getObjectProperty(___arguments.context.dataModel, '___ui.repeat')) && !(getObjectProperty(___arguments.context.dataModel, 'objectId'))) {
      sessions = await generateRepeatSessions();
    } else {
      sessions = [___arguments.context.dataModel];
    }
    results = (await Backendless.Request.post(`${Backendless.appPath}/services/SESSIONS/v1/sessions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'sessions': sessions,'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) }));
    for (var session_index in results) {
      session = results[session_index];
      await BackendlessUI.Functions.Custom['fn_8564e643897ab40ff5d31e1473139bce'](session);
    }
    if (getObjectProperty(___arguments.context.dataModel, 'objectId')) {
      (function (message) { alert(message) })(' Session amended! 🎉🎉');
      replaceItemInList((getObjectProperty(___arguments.context.pageData, 'sessions')), (results[0]), 'objectId');
    } else {
      (function (message) { alert(message) })((String(results.length) + String(' Session(s) added! 🎉🎉')));
      (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('bd192846230e9afd7061c9114bf4b8b1');
      ___arguments.context.pageData['repeatedSessions'] = null;
      addItemToList((getObjectProperty(___arguments.context.pageData, 'sessions')), results);
    }
    ___arguments.context.pageData['calendar'] = (await BackendlessUI.Functions.Custom['fn_0d6ab7518735beb79ac73b951ae997e1']((getObjectProperty(___arguments.context.pageData, 'blankCalendar')), (getObjectProperty(___arguments.context.pageData, 'sessions'))));
    delete ___arguments.context.pageData['selectedItem'];

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/sessions/components/ab4f4e94da53152b0af73881a8f91eb8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(await BackendlessUI.Functions.Custom['fn_2d66f332efdfa294dacc1adcc07afcb4']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'duration')))),' Session (',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'status')),')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/sessions/components/95cf195e368d3dd4e8978a4f063b70b1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedItem'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/677824c2e88d44f9fe6895c1dc4d6ef1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['selectedItem'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/sessions/components/1fb5a9d5bd6625b7eec2e5e17ec85058/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedItem.objectId')) ? 'Selected Session' : 'New Session')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/sessions/components/37b85806b53be7959906b516fd737f89/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var dateString;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dateString = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'dates.start.date'));
  if (dateString) {
    return (!(await BackendlessUI.Functions.Custom['fn_c46539ee9ebb2936bbd424e93e08356b'](dateString)));
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/a39d609736256dc80c358e182d465ab2/bundle.js', [], () => ({
  /* content */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), '___ui.repeatEndPartial', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalDate, partial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  partial = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), '___ui.repeatEndPartial'));
  if ((partial.indexOf('d') !== -1) || (partial.indexOf('m') !== -1) || (partial.indexOf('y') !== -1)) {
    console.log('invalid date detected');
    originalDate = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), '___ui.repeatEnd'));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), '___ui'))['repeatEnd'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), '___ui'))['repeatEnd'] = originalDate;
  }

  },
  /* handler:onLostFocusEvent */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), '___ui.repeatEnd', ___arguments['value']);
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), '___ui.repeatEndTimestamp', (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](___arguments['value'], '23:59')));

  },
  /* handler:onComplete */
  /* content */
}))

define('./pages/sessions/components/98fedfc6be26f5b94e239f3d2de9ee25/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var invalid;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), '___ui.repeatEnd')) {
    if (!(await BackendlessUI.Functions.Custom['fn_c46539ee9ebb2936bbd424e93e08356b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), '___ui.repeatEnd'))))) {
      return true;
    }
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), '___ui.repeatEndTimestamp')) <= (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'start'))) {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/7bcb1a987caf1164d88c6fe4a7a8e3b5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'objectId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/beeff66320bba99d87dea63260047711/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'objectId')) {
    return 'Save changes';
  } else {
    return 'Add Session';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_269a047dbcd9deccf699dd9be21fa0ed'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), (getObjectProperty(___arguments.context.pageData, 'selectedItem_original')), ['___ui', 'dates']))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/sessions/components/e0fae5a6b18374641db8471e00f7766d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('9df2a50da6b51b93ac11450858e40712');

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'objectId'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/9df2a50da6b51b93ac11450858e40712/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, session;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('9df2a50da6b51b93ac11450858e40712');
    session = (getObjectProperty(___arguments.context.pageData, 'selectedItem'));
    await Backendless.Request.delete(`${Backendless.appPath}/services/SESSIONS/v2/sessions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify([(getObjectProperty(session, 'objectId'))]));
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'sessions')), session, 'objectId');
    ___arguments.context.pageData['calendar'] = (await BackendlessUI.Functions.Custom['fn_0d6ab7518735beb79ac73b951ae997e1']((getObjectProperty(___arguments.context.pageData, 'blankCalendar')), (getObjectProperty(___arguments.context.pageData, 'sessions'))));

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/sessions/components/99617cb89e2d82cd1e57ed4bb5e3c1ee/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'calendar.3_1.month')),' ',(getObjectProperty(___arguments.context.pageData, 'calendar.3_1.year'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/sessions/components/4d996e59df43b0089c8f4e9c4a45049b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, '___ui.multiSelect', (!(getObjectProperty(___arguments.context.pageData, '___ui.multiSelect'))));
  ___arguments.context.pageData['multiSelectItems'] = ({ [`objectIds`]: [],[`sessions`]: [],[`client`]: ({ [`objectId`]: undefined }),[`locum`]: ({ [`objectId`]: undefined }) });
  delete ___arguments.context.pageData['email'];
  delete ___arguments.context.pageData['selectedItem'];

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, '___ui.multiSelect')) {
    return ['iconButtonOverride selected'];
  } else {
    return ['iconButtonOverride'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/sessions/components/39d8e3e16197dd72e81340e2f3a766d9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.pageData, 'multiSelectItems.objectIds'))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/0aa1123fe30a5363beb957385c9ccb97/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.pageData, 'multiSelectItems.objectIds'))))) + String(' Sessions Selected'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/sessions/components/abb4c197ea26776718886921a469012d/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var changes, error, formData, id, option, options, results, session, sessionIds, sessions, matchingSession, settings, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  formData = ___arguments.context.dataModel;
  sessionIds = (getObjectProperty(formData, 'objectIds'));
  changes = ({ [`temp`]: undefined });
  sessions = [];
  settings = (getObjectProperty(formData, '___ui'));
  if (getObjectProperty(settings, 'locum')) {
    changes['locum'] = ({ [`objectId`]: (getObjectProperty(formData, 'locum.objectId')) });
  }
  if (getObjectProperty(settings, 'client')) {
    changes['client'] = ({ [`objectId`]: (getObjectProperty(formData, 'client.objectId')) });
  }
  options = ['rateClient', 'extraClient', 'rateLocum', 'extraLocum', 'notesSession'];
  for (var option_index in options) {
    option = options[option_index];
    if (getObjectProperty(settings, option)) {
      changes[option] = (getObjectProperty(formData, option));
    }
  }
  for (var id_index in sessionIds) {
    id = sessionIds[id_index];
    session = ({ [`objectId`]: id });
    Object.assign(session, changes);
    matchingSession = (await asyncListFind((getObjectProperty(___arguments.context.pageData, 'sessions')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) == id);
    }));
    Object.assign(session, ({ [`confirmationStatusClient`]: (getObjectProperty(matchingSession, 'confirmationStatusClient')),[`confirmationStatusLocum`]: (getObjectProperty(matchingSession, 'confirmationStatusLocum')) }));
    console.log('Session ID');
    console.log(id);
    console.log('Matching Session:');
    console.log(matchingSession);
    addItemToList(sessions, session);
  }
  console.log('CHANGES TO BE SUBMITTED');
  console.log(sessions);
  try {
    results = (await Backendless.Request.post(`${Backendless.appPath}/services/SESSIONS/v1/sessions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'sessions': sessions,'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) }));
    await BackendlessUI.Functions.Custom['fn_6ae1d56914c71148f9362167743ced54'](___arguments.context.pageData, ___arguments.context.appData);
    (getObjectProperty(___arguments.context.pageData, '___ui'))['multiSelect'] = false;
    delete ___arguments.context.pageData['multiSelectItems'];
    (function (message) { alert(message) })((String(results.length) + String(' Session amended! 🎉🎉')));

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/sessions/components/4f23d40135c4fec24d152e3e5f79736c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abb4c197ea26776718886921a469012d'), '___ui.client'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/13e7acbcbb110b417ce3c8f1726a22a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('e0ebaa01a932bf9c6627bb065dd4c96a');

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Delete ',(await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('abb4c197ea26776718886921a469012d'), 'objectIds')))),' Selected'].join(''))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/sessions/components/e0ebaa01a932bf9c6627bb065dd4c96a/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, sessionIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('e0ebaa01a932bf9c6627bb065dd4c96a');
    sessionIds = (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.objectIds'));
    await Backendless.Request.delete(`${Backendless.appPath}/services/SESSIONS/v2/sessions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(sessionIds));
    await BackendlessUI.Functions.Custom['fn_6ae1d56914c71148f9362167743ced54'](___arguments.context.pageData, ___arguments.context.appData);
    delete ___arguments.context.pageData['selectedItem'];
    (function (message) { alert(message) })((String(sessionIds.length) + String(' Sessions Deleted! 🎉🎉')));

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/sessions/components/804e90ce2ae3dcf4ff5382d352b5bb9e/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Update  ',(await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('abb4c197ea26776718886921a469012d'), 'objectIds')))),' Selected'].join(''))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/sessions/components/6fce4be3eff6efbf66f6df9c28e16c14/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  delete ___arguments.context.pageData['multiSelectItems'];
  (getObjectProperty(___arguments.context.pageData, '___ui'))['multiSelect'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/sessions/components/2ff1787f1fcdf685b9fc6372ba0911b7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.nonOwned', (!(getObjectProperty(___arguments.context.pageData, 'ui.nonOwned'))));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'ui.nonOwned')) {
    return ['iconButtonOverride selected'];
  } else {
    return ['iconButtonOverride'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onTooltipTextAssignment */
  ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.nonOwned')) ? 'Click to show only sessions of "my" clients' : 'Click to show all sessions for the agency (warning, may be slow)')

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/sessions/components/bcc5ee242e4c777bb61ac44d9376d5a1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'client.note')) ? String('Note: ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'client.note')) : '')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/sessions/components/0211b241e629cf619ea95a6b342a0bde/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_6ae1d56914c71148f9362167743ced54'](___arguments.context.pageData, ___arguments.context.appData);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.calendarLoading')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/c97f3aa81f6c10cf6fcf98e66002ca56/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var emailBody, selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedSessions = (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.sessions'));
  console.log(selectedSessions);
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'email.type', 'client');
  emailBody = (await BackendlessUI.Functions.Custom['fn_83d5a6879d5ab7634fa6d5b1feeae15c']((getObjectProperty((selectedSessions[0]), 'client.orgName')), (getObjectProperty(___arguments.context.appData, 'currentOrg.orgName')), (getObjectProperty(___arguments.context.pageData, 'email.userBody')), selectedSessions));
  Object.assign((getObjectProperty(___arguments.context.pageData, 'email')), ({ [`to`]: (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty((selectedSessions[0]), 'client.bookingEmail')))),[`cc`]: (getObjectProperty(___arguments.context.appData, 'currentOrg.billingEmail')),[`body`]: emailBody }));
  await ( async function (componentUid, html) {  ___arguments.context.getComponentByUid(componentUid).setHtml(html) })('0c893167d4bb235896fd08cfae0f1662', emailBody);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedSessions = (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.sessions'));
  if (selectedSessions) {
    if ((await BackendlessUI.Functions.Custom['fn_465a168c4dbaac59e91987824a13cce3'](selectedSessions, 'client.objectId')) == 1) {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/53e8f21d80691868083e5a3d510f4686/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var emailBody, selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedSessions = (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.sessions'));
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'email.type', 'locum');
  emailBody = (await BackendlessUI.Functions.Custom['fn_d17e964e29c4bb15c9de64bf5ef1ba7c'](selectedSessions, (getObjectProperty(___arguments.context.appData, 'currentOrg.orgName')), (getObjectProperty(___arguments.context.pageData, 'email.userBody')), null));
  Object.assign((getObjectProperty(___arguments.context.pageData, 'email')), ({ [`to`]: (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty((selectedSessions[0]), 'locum.billingEmail')))),[`cc`]: (getObjectProperty(___arguments.context.appData, 'currentOrg.billingEmail')),[`body`]: emailBody }));
  await ( async function (componentUid, html) {  ___arguments.context.getComponentByUid(componentUid).setHtml(html) })('0c893167d4bb235896fd08cfae0f1662', emailBody);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedSessions = (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.sessions'));
  if (selectedSessions) {
    if ((await BackendlessUI.Functions.Custom['fn_465a168c4dbaac59e91987824a13cce3'](selectedSessions, 'locum.objectId')) == 1) {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/bc89410797dfaf46f48c0e58b768ecfc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var userOrgEmail;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userOrgEmail = (getObjectProperty(___arguments.context.pageData, 'email.cc'));
  if (userOrgEmail) {
    return (String('A copy will be sent to: ') + String(userOrgEmail));
  } else {
    return 'Please add a billing Email for your org to receive a copy';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/sessions/components/3e09c6c56f1304195539775d33b58a0d/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    var emailBody, selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedSessions = (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.sessions'));
  if (selectedSessions) {
    if ((getObjectProperty(___arguments.context.pageData, 'email.type')) == 'client') {
      emailBody = (await BackendlessUI.Functions.Custom['fn_83d5a6879d5ab7634fa6d5b1feeae15c']((getObjectProperty((selectedSessions[0]), 'client.orgName')), (getObjectProperty(___arguments.context.appData, 'currentOrg.orgName')), (getObjectProperty(___arguments.context.pageData, 'email.userBody')), selectedSessions));
    } else {
      emailBody = (await BackendlessUI.Functions.Custom['fn_d17e964e29c4bb15c9de64bf5ef1ba7c']((getObjectProperty(___arguments.context.pageData, 'groupedSessions')), (getObjectProperty(___arguments.context.appData, 'currentOrg.orgName')), (getObjectProperty(___arguments.context.pageData, 'email.userBody'))));
    }
    await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'email.body', emailBody);
    await ( async function (componentUid, html) {  ___arguments.context.getComponentByUid(componentUid).setHtml(html) })('0c893167d4bb235896fd08cfae0f1662', emailBody);
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/sessions/components/52a388146bd310a48851e5cef8c54c53/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, selectedSessions, payload, recipients;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function setRecipients() {
  recipients = [(getObjectProperty(___arguments.context.pageData, 'email.to'))];
  if (getObjectProperty(___arguments.context.pageData, 'email.cc')) {
    addItemToList(recipients, (getObjectProperty(___arguments.context.pageData, 'email.cc')));
  }
  return recipients
}


  try {
    await Backendless.Messaging.sendEmail((String('Confirmation Email from ') + String(getObjectProperty(___arguments.context.appData, 'currentOrg.orgName'))), new Backendless.Bodyparts({ htmlmessage: (getObjectProperty(___arguments.context.pageData, 'email.body')) }), ensureArray((await setRecipients())), undefined);
    payload = [];
    selectedSessions = (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.sessions'));
    if ((getObjectProperty(___arguments.context.pageData, 'email.type')) == 'client') {
      await Backendless.Data.of('sessions').bulkUpdate( (await BackendlessUI.Functions.Custom['fn_064af7773cbb9bdb2ff71d23201cd648']('objectId', (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.objectIds')))), ({ [`confirmationStatusClient`]: 'SENT' }) );
    } else {
      await Backendless.Data.of('sessions').bulkUpdate( (await BackendlessUI.Functions.Custom['fn_064af7773cbb9bdb2ff71d23201cd648']('objectId', (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.objectIds')))), ({ [`confirmationStatusLocum`]: 'SENT' }) );
    }
    await BackendlessUI.Functions.Custom['fn_6ae1d56914c71148f9362167743ced54'](___arguments.context.pageData, ___arguments.context.appData);
    (function (message) { alert(message) })((String('Email sent! 🎉🎉 - A copy is sent to ') + String(getObjectProperty(___arguments.context.pageData, 'email.cc'))));
    delete ___arguments.context.pageData['email'];
    delete ___arguments.context.pageData['multiSelectItems'];
    (getObjectProperty(___arguments.context.pageData, '___ui'))['multiSelect'] = false;

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/sessions/components/fd785db5c195143c1787bb42dc861ce7/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'ui.listView')) {
    return 'calendar today';
  } else {
    return 'table rows';
  }

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var view;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.listView', (!(getObjectProperty(___arguments.context.pageData, 'ui.listView'))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/sessions/components/199c157c20763703bc647c843333a6f5/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var selectedSessions, selectedIds, sessions, selectedRows;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedRows = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('199c157c20763703bc647c843333a6f5'));
  sessions = (getObjectProperty(___arguments.context.pageData, 'sessions'));
  selectedIds = (selectedRows.map(item => item['sessionId']));
  selectedSessions = (await BackendlessUI.Functions.Custom['fn_666d976d080af841315daaf37fbb8b97'](selectedIds, sessions));
  if (getObjectProperty(___arguments.context.pageData, '___ui.multiSelect')) {
    Object.assign((getObjectProperty(___arguments.context.pageData, 'multiSelectItems')), ({ [`objectIds`]: selectedIds,[`sessions`]: (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selectedSessions)) }));
  } else {
    ___arguments.context.pageData['selectedItem'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((selectedSessions[0])));
    ___arguments.context.pageData['selectedItem_original'] = (selectedSessions[0]);
  }

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/sessions/components/a7a166368307d5954f5e0fc5f2df1451/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.listView')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions/components/50fb1789a23936dd1e2c482629239481/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_6ae1d56914c71148f9362167743ced54'](___arguments.context.pageData, ___arguments.context.appData);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/sessions/components/0e4a97fa906a638abf47f30c7b2a2ec5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_6ae1d56914c71148f9362167743ced54'](___arguments.context.pageData, ___arguments.context.appData);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/sessions/components/c3c105962e24b73e3f87db62156953b6/bundle.js', [], () => ({
  /* content */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'clients.all'))

  },
  /* handler:optionsLogic */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'client'))['objectId'] = null;
  delete ___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1')['processor'];

  },
  /* handler:onButtonClearClick */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}


  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'client.objectId', ___arguments['inputValue']);
  ___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1')['processor'] = (getObjectProperty((await asyncListFind((getObjectProperty(___arguments.context.appData, 'clients.all')), async (item) => {


   return ((getObjectProperty(item, 'objectId')) == ___arguments['inputValue']);
  })), 'defaultProcessor'));
  Object.assign(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), (await BackendlessUI.Functions.Custom['fn_91df63ee70a7346b083e81ead2a93b36'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), ___arguments.context.appData)));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/sessions/components/4ca2ebeb4f5904c86d8f4ebdabe631ea/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      Object.assign(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), (await BackendlessUI.Functions.Custom['fn_91df63ee70a7346b083e81ead2a93b36'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), ___arguments.context.appData)));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/sessions/components/39a3b8381451322497683d08babfb781/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var margin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  margin = (await BackendlessUI.Functions.Custom['fn_91df63ee70a7346b083e81ead2a93b36'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), ___arguments.context.appData));
  if (!margin) {
    return ;
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), margin);

  return (['Est. margin: £',(getObjectProperty(margin, 'margin')),' (£',(getObjectProperty(margin, 'hourlyMargin')),'/hr)'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/sessions/components/e7369474d21f4292b85bd2399a41e5ea/bundle.js', [], () => ({
  /* content */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'locums.allWithNone'))

  },
  /* handler:optionsLogic */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), 'locum.objectId', ___arguments['inputValue']);

  },
  /* handler:onChange */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1')['locum'] = ({ [`objectId`]: 'NONE' });

  },
  /* handler:onButtonClearClick */
  /* content */
}))

define('./pages/sessions/components/fbab3582b9c9758f2c94431bbf0e82e1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      Object.assign(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), (await BackendlessUI.Functions.Custom['fn_91df63ee70a7346b083e81ead2a93b36'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), ___arguments.context.appData)));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/sessions/components/c7d8949c10ddd7789cde82fbc946004e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      Object.assign(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), (await BackendlessUI.Functions.Custom['fn_91df63ee70a7346b083e81ead2a93b36'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), ___arguments.context.appData)));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/sessions/components/b879491834cf3a02f2def84d426791ab/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      Object.assign(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), (await BackendlessUI.Functions.Custom['fn_91df63ee70a7346b083e81ead2a93b36'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), ___arguments.context.appData)));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/sessions/components/4ea61d06f9bbfbd5d406eb9c1ebf3143/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      Object.assign(___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), (await BackendlessUI.Functions.Custom['fn_91df63ee70a7346b083e81ead2a93b36'](___arguments.context.getComponentDataStoreByUid('bd192846230e9afd7061c9114bf4b8b1'), ___arguments.context.appData)));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/sessions/components/30541f966cf920580c8eb80ba812912c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('abb4c197ea26776718886921a469012d'), 'locum.objectId', ___arguments['inputValue']);

  },
  /* handler:onChange */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('abb4c197ea26776718886921a469012d')['locum'] = ({ [`objectId`]: 'NONE' });

  },
  /* handler:onButtonClearClick */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'locums.allWithNone'))

  },
  /* handler:optionsLogic */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('abb4c197ea26776718886921a469012d'), '___ui.locum'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions-old/components/d986cfbd591e03e6265bb92f43bcb128/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, sessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    sessions = [___arguments.context.dataModel];
    if ((getObjectProperty(___arguments.context.pageData, 'repeatedSessions')) && !(getObjectProperty(___arguments.context.dataModel, 'objectId'))) {
      sessions = (getObjectProperty(___arguments.context.pageData, 'repeatedSessions'));
    }
    await Backendless.Request.post(`${Backendless.appPath}/services/SESSIONS/Sessions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'sessions': sessions });
    ___arguments.context.pageData['sessions'] = (await BackendlessUI.Functions.Custom['fn_ced8ed7da4ec620952544184e7e6cc0b']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))));
    if (getObjectProperty(___arguments.context.dataModel, 'objectId')) {
      (function (message) { alert(message) })(' Session amended! 🎉🎉');
    } else {
      (function (message) { alert(message) })((String(sessions.length) + String(' Session(s) added! 🎉🎉')));
      (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('d986cfbd591e03e6265bb92f43bcb128');
      ___arguments.context.pageData['repeatedSessions'] = null;
    }

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  ['onReset'](___arguments) {
    
  },
  /* handler:onReset */
  /* content */
}))

define('./pages/sessions-old/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var sessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function load_sessions() {
  sessions = (await BackendlessUI.Functions.Custom['fn_ced8ed7da4ec620952544184e7e6cc0b']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))));
  ___arguments.context.pageData['sessions'] = sessions;
}


  await load_sessions();
  ___arguments.context.pageData['selectedItem'] = ({ [`start`]: (new Date()),[`end`]: (new Date((new Date()).getTime() + ((2 * 3600) * 1000))) });

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/sessions-old/components/61d3c81e9856ad9681c6a0d14427137d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var sessions, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessions = (await Backendless.Data.of('sessions').find(Backendless.DataQueryBuilder.create().setWhereClause((getObjectProperty(___arguments.context.pageData, 'whereClause'))).setRelationsDepth(1).setPageSize(100)));
  for (var j_index in sessions) {
    j = sessions[j_index];
    Object.assign(j, ({ [`clientName`]: (getObjectProperty(j, 'client.orgName')),[`agencyName`]: (getObjectProperty(j, 'agency.orgName')) }));
  }
  ___arguments.context.pageData['sessions'] = sessions;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/sessions-old/components/e19d0a941102c0bda55435c4a7bfef68/bundle.js', [], () => ({
  /* content */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d986cfbd591e03e6265bb92f43bcb128'), 'locum.objectId')) {
    return 'Locum';
  } else {
    return '-- Select Locum --';
  }

  },
  /* handler:placeholderLogic */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, '___ui.locum-autocomplete')) ? (getObjectProperty(___arguments.context.pageData, '___ui.locum-autocomplete')) : [])

  },
  /* handler:optionsLogic */
  /* handler:onButtonClearClick */
  async ['onButtonClearClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'locum', null);

  },
  /* handler:onButtonClearClick */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var result;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  result = (await BackendlessUI.Functions.Custom['fn_29957d83d80974dad1a157b43bf89f85'](___arguments['inputValue'], 'locums', 'locum-autocomplete', ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))));
  if (result) {
    await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('d986cfbd591e03e6265bb92f43bcb128'), 'locum.objectId', result);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/sessions-old/components/b6bd9ba73949010d3139d63ee0192164/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var selectedSessions, selectedRowIds, selectedRows, data;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments['cellParams']);
  data = (await Backendless.Data.of('sessions').findById((getObjectProperty(___arguments['cellParams'], 'data.objectId')), Backendless.DataQueryBuilder.create().setRelationsDepth(1)));
  ___arguments.context.pageData['selectedItem'] = data;
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, '___ui.locum-autocomplete', [({ [`label`]: (getObjectProperty(data, 'locum.orgName')),[`value`]: (getObjectProperty(data, 'locum.objectId')) })]);
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, '___ui.client-autocomplete', [({ [`label`]: (getObjectProperty(data, 'client.orgName')),[`value`]: (getObjectProperty(data, 'client.objectId')) })]);
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'email.to', null);
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'email.body', null);
  selectedRows = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('b6bd9ba73949010d3139d63ee0192164'));
  if (selectedRows) {
    selectedRowIds = (selectedRows.map(item => item['objectId']));
    selectedSessions = (await (async function(sessions, objectIds) {
    	return sessions.filter(session => objectIds.includes(session.objectId));
    })((getObjectProperty(___arguments.context.pageData, 'sessions')), selectedRowIds));
    ___arguments.context.pageData['selectedSessions'] = selectedSessions;
  }

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/sessions-old/components/5a728bb3de6432f1fd79e499bcd3ef84/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var today;


  today = ((new Date()).toLocaleDateString());
  ___arguments.context.pageData['selectedItem'] = ({ [`start`]: (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](today, '09:00')),[`end`]: (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](today, '12:00')) });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/sessions-old/components/6b0fce1f05984aaf53f341a18ca7d9d5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d986cfbd591e03e6265bb92f43bcb128'), 'objectId')) ? 'Edit Session' : 'New Session')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/sessions-old/components/3449d82f6e535317ed789197a108cf3b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('de1f76a942f332f8bb5eddb4f31b340b');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/sessions-old/components/de1f76a942f332f8bb5eddb4f31b340b/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of('sessions').remove( (getObjectProperty(___arguments.context.pageData, 'selectedItem.objectId')) );
    ___arguments.context.pageData['sessions'] = (await BackendlessUI.Functions.Custom['fn_ced8ed7da4ec620952544184e7e6cc0b']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))));
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('de1f76a942f332f8bb5eddb4f31b340b');

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/sessions-old/components/1b851209293822da10819d2d21dbb396/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var sessions, endString, j, startString, repeatWeekday, repeatEnd, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  repeatWeekday = [];
  for (i = 1; i <= 7; i++) {
    if (getObjectProperty(___arguments.context.dataModel, (String('repeat') + String(i)))) {
      addItemToList(repeatWeekday, i);
    }
  }
  (getObjectProperty(___arguments.context.pageData, 'selectedItem'))['start'] = ((getObjectProperty(___arguments.context.pageData, 'selectedItem.start')).valueOf());
  (getObjectProperty(___arguments.context.pageData, 'selectedItem'))['end'] = ((getObjectProperty(___arguments.context.pageData, 'selectedItem.end')).valueOf());
  console.log((new Date((getObjectProperty(___arguments.context.dataModel, 'repeatEnd')))).toDateString());
  repeatEnd = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](((new Date((getObjectProperty(___arguments.context.dataModel, 'repeatEnd')))).toDateString()), '23:59'));
  console.log(repeatEnd);
  sessions = (await (async function(session, repeatEnd, repeatWeekdays) {
  	const { DateTime } = luxon; // Assuming luxon is already available globally

  	function parseDate(timestamp) {
  	  if (typeof timestamp === 'number') {
  	    // Timestamp in milliseconds
  	    return DateTime.fromMillis(timestamp);
  	  }
  	  return null;
  	}

  	const sessions = [];
  	const endDate = parseDate(repeatEnd);

  	if (!endDate) {
  	  console.error("Invalid end date:", repeatEnd);
  	  return sessions;
  	}

  	console.log("End Date:", endDate.toISO());

  	// Add the original session to the array
  	sessions.push(session);

  	let currentStart = parseDate(session.start);
  	let currentEnd = parseDate(session.end);

  	if (!currentStart || !currentEnd) {
  	  console.error("Invalid session start or end date:", session.start, session.end);
  	  return sessions;
  	}

  	console.log("Original Session Start:", currentStart.toISO());
  	console.log("Original Session End:", currentEnd.toISO());

  	while (true) {
  	  let nextSessionAdded = false;

  	  // Iterate over each weekday in the repeatWeekdays array
  	  for (const day of repeatWeekdays) {
  	    console.log("Checking weekday:", day);

  	    // Calculate the next session's start and end dates
  	    let nextStart = currentStart;
  	    let nextEnd = currentEnd;

  	    // Find the next occurrence of the given weekday
  	    if (currentStart.weekday <= day) {
  	      nextStart = currentStart.plus({ days: day - currentStart.weekday });
  	      nextEnd = currentEnd.plus({ days: day - currentEnd.weekday });
  	    } else {
  	      nextStart = currentStart.plus({ days: 7 - (currentStart.weekday - day) });
  	      nextEnd = currentEnd.plus({ days: 7 - (currentEnd.weekday - day) });
  	    }

  	    console.log("Next Start before adjustment:", nextStart ? nextStart.toISO() : 'null');
  	    console.log("Next End before adjustment:", nextEnd ? nextEnd.toISO() : 'null');

  	    // Ensure the next session is at least one week after the original session
  	    if (nextStart <= currentStart) {
  	      nextStart = nextStart.plus({ weeks: 1 });
  	      nextEnd = nextEnd.plus({ weeks: 1 });
  	    }

  	    console.log("Next Start after adjustment:", nextStart ? nextStart.toISO() : 'null');
  	    console.log("Next End after adjustment:", nextEnd ? nextEnd.toISO() : 'null');

  	    if (nextStart <= endDate) {
  	      nextSessionAdded = true;
  	      const newSession = {
  	        ...session,
  	        start: nextStart.toMillis(),
  	        end: nextEnd.toMillis()
  	      };
  	      sessions.push(newSession);
  	      console.log("Added new session:", newSession);
  	    } else {
  	      console.log("Next start date exceeds end date. No session added.");
  	    }
  	  }

  	  // If no new session was added, break the loop
  	  if (!nextSessionAdded) {
  	    console.log("No new sessions added. Exiting loop.");
  	    break;
  	  }

  	  // Move to the next week's sessions
  	  currentStart = currentStart.plus({ weeks: 1 });
  	  currentEnd = currentEnd.plus({ weeks: 1 });

  	  console.log("Current Start moved to:", currentStart.toISO());
  	  console.log("Current End moved to:", currentEnd.toISO());
  	}

  	return sessions;


  	// const { DateTime } = luxon; // Assuming luxon is already available globally

  	// function parseDate(date) {
  	//   if (typeof date === 'number') {
  	//     // Timestamp in milliseconds
  	//     return DateTime.fromMillis(date);
  	//   } else if (typeof date === 'string') {
  	//     // Attempt to parse as ISO date first
  	//     const isoDate = DateTime.fromISO(date);
  	//     if (isoDate.isValid) {
  	//       return isoDate;
  	//     } else {
  	//       // Fallback to parsing as custom format using JavaScript Date
  	//       const jsDate = new Date(date);
  	//       if (!isNaN(jsDate)) {
  	//         return DateTime.fromJSDate(jsDate);
  	//       }
  	//     }
  	//   }
  	//   return null;
  	// }


  	//   const sessions = [];
  	//   const endDate = parseDate(repeatEnd);

  	//   if (!endDate) {
  	//     console.error("Invalid end date:", repeatEnd);
  	//     return sessions;
  	//   }

  	//   console.log("End Date:", endDate.toISO());

  	//   // Add the original session to the array
  	//   sessions.push(session);

  	//   let currentStart = parseDate(session.start);
  	//   let currentEnd = parseDate(session.end);

  	//   if (!currentStart || !currentEnd) {
  	//     console.error("Invalid session start or end date:", session.start, session.end);
  	//     return sessions;
  	//   }

  	//   console.log("Original Session Start:", currentStart.toISO());
  	//   console.log("Original Session End:", currentEnd.toISO());

  	//   while (true) {
  	//     let nextSessionAdded = false;

  	//     // Iterate over each weekday in the repeatWeekdays array
  	//     for (const day of repeatWeekdays) {
  	//       console.log("Checking weekday:", day);

  	//       // Calculate the next session's start and end dates
  	//       let nextStart = currentStart;
  	//       let nextEnd = currentEnd;

  	//       // Find the next occurrence of the given weekday
  	//       if (currentStart.weekday <= day) {
  	//         nextStart = currentStart.plus({ days: day - currentStart.weekday });
  	//         nextEnd = currentEnd.plus({ days: day - currentEnd.weekday });
  	//       } else {
  	//         nextStart = currentStart.plus({ days: 7 - (currentStart.weekday - day) });
  	//         nextEnd = currentEnd.plus({ days: 7 - (currentEnd.weekday - day) });
  	//       }

  	//       console.log("Next Start before adjustment:", nextStart ? nextStart.toISO() : 'null');
  	//       console.log("Next End before adjustment:", nextEnd ? nextEnd.toISO() : 'null');

  	//       // Ensure the next session is at least one week after the original session
  	//       if (nextStart <= currentStart) {
  	//         nextStart = nextStart.plus({ weeks: 1 });
  	//         nextEnd = nextEnd.plus({ weeks: 1 });
  	//       }

  	//       console.log("Next Start after adjustment:", nextStart ? nextStart.toISO() : 'null');
  	//       console.log("Next End after adjustment:", nextEnd ? nextEnd.toISO() : 'null');

  	//       if (nextStart <= endDate) {
  	//         nextSessionAdded = true;
  	//         const newSession = {
  	//           ...session,
  	//           start: nextStart.toISO(),
  	//           end: nextEnd.toISO()
  	//         };
  	//         sessions.push(newSession);
  	//         console.log("Added new session:", newSession);
  	//       } else {
  	//         console.log("Next start date exceeds end date. No session added.");
  	//       }
  	//     }

  	//     // If no new session was added, break the loop
  	//     if (!nextSessionAdded) {
  	//       console.log("No new sessions added. Exiting loop.");
  	//       break;
  	//     }

  	//     // Move to the next week's sessions
  	//     currentStart = currentStart.plus({ weeks: 1 });
  	//     currentEnd = currentEnd.plus({ weeks: 1 });

  	//     console.log("Current Start moved to:", currentStart.toISO());
  	//     console.log("Current End moved to:", currentEnd.toISO());
  	//   }

  	//   return sessions;

  })((getObjectProperty(___arguments.context.pageData, 'selectedItem')), repeatEnd, repeatWeekday));
  for (var j_index in sessions) {
    j = sessions[j_index];
    startString = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'start'))));
    endString = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'end'))));
    j['startDate'] = ([(getObjectProperty(startString, 'weekday')),' ',(getObjectProperty(startString, 'dateString'))].join(''));
    j['startTime'] = (getObjectProperty(startString, 'timeString'));
    j['endTime'] = (getObjectProperty(endString, 'timeString'));
  }
  ___arguments.context.pageData['repeatedSessions'] = sessions;

  },
  /* handler:onSubmit */
  /* handler:onReset */
  ['onReset'](___arguments) {
      ___arguments.context.pageData['repeatedSessions'] = null;

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/sessions-old/components/fffcac9cbe834731639f174bcc9c219a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedSessions = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('b6bd9ba73949010d3139d63ee0192164'));
  console.log(selectedSessions);
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'email.type', 'client');
  Object.assign((getObjectProperty(___arguments.context.pageData, 'email')), ({ [`to`]: (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty((selectedSessions[0]), 'clientEmail')))),[`cc`]: (getObjectProperty(___arguments.context.appData, 'currentOrg.billingEmail')),[`body`]: (await BackendlessUI.Functions.Custom['fn_83d5a6879d5ab7634fa6d5b1feeae15c']((getObjectProperty((selectedSessions[0]), 'clientName')), (getObjectProperty(___arguments.context.appData, 'currentOrg.orgName')), (getObjectProperty(___arguments.context.pageData, 'email.userBody')), selectedSessions)) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedSessions = (getObjectProperty(___arguments.context.pageData, 'selectedSessions'));
  if (selectedSessions) {
    if ((await BackendlessUI.Functions.Custom['fn_465a168c4dbaac59e91987824a13cce3'](selectedSessions, 'clientName')) == 1) {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/sessions-old/components/c477596fe84e44e6c79a938dd5d78631/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await (async function(htmlString) {
	    const startTrim = '<!DOCTYPE html>';
	    const endTrim = '</html>';

	    // Remove the starting part
	    if (htmlString.startsWith(startTrim)) {
	        htmlString = htmlString.slice(startTrim.length).trim();
	    }

	    // Remove the ending part
	    if (htmlString.endsWith(endTrim)) {
	        htmlString = htmlString.slice(0, -endTrim.length).trim();
	    }

	    return htmlString;
})((getObjectProperty(___arguments.context.pageData, 'email.body'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/sessions-old/components/50d8c3d8613990858e9ede573c1369c2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, payload, oneload, session, selectedSessions, recipients;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function setRecipients() {
  recipients = [(getObjectProperty(___arguments.context.pageData, 'email.to'))];
  if (getObjectProperty(___arguments.context.pageData, 'email.cc')) {
    addItemToList(recipients, (getObjectProperty(___arguments.context.pageData, 'email.cc')));
  }
  return recipients
}


  try {
    await Backendless.Messaging.sendEmail((String('Confirmation Email from ') + String(getObjectProperty(___arguments.context.appData, 'currentOrg.orgName'))), new Backendless.Bodyparts({ htmlmessage: (getObjectProperty(___arguments.context.pageData, 'email.body')) }), ensureArray((await setRecipients())), undefined);
    payload = [];
    selectedSessions = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('b6bd9ba73949010d3139d63ee0192164'));
    if ((getObjectProperty(___arguments.context.pageData, 'email.type')) == 'client') {
      for (var session_index in selectedSessions) {
        session = selectedSessions[session_index];
        oneload = ({ [`objectId`]: (getObjectProperty(session, 'objectId')),[`confirmationStatusClient`]: 'SENT' });
        addItemToList(payload, oneload);
      }
    } else {
      for (var session_index2 in selectedSessions) {
        session = selectedSessions[session_index2];
        oneload = ({ [`objectId`]: (getObjectProperty(session, 'objectId')),[`confirmationStatusLocum`]: 'SENT' });
        addItemToList(payload, oneload);
      }
    }
    await Backendless.Data.of('sessions').bulkUpsert( payload );
    ___arguments.context.pageData['sessions'] = (await BackendlessUI.Functions.Custom['fn_ced8ed7da4ec620952544184e7e6cc0b']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))));
    (function (message) { alert(message) })((String('Email sent! 🎉🎉 - A copy is sent to ') + String(getObjectProperty(___arguments.context.pageData, 'email.cc'))));

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/sessions-old/components/2c0c96f24c235f8e89e696c7eb2bec6b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedSessions = (getObjectProperty(___arguments.context.pageData, 'selectedSessions'));
  if (selectedSessions) {
    if ((await BackendlessUI.Functions.Custom['fn_465a168c4dbaac59e91987824a13cce3'](selectedSessions, 'locumName')) == 1) {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedSessions, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedSessions = (getObjectProperty(___arguments.context.pageData, 'selectedSessions'));
  ___arguments.context.pageData['groupedSessions'] = (await BackendlessUI.Functions.Custom['fn_4d418f750b624e699d6def8d73e65db4'](selectedSessions, 'client'));
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'email.type', 'locum');
  Object.assign((getObjectProperty(___arguments.context.pageData, 'email')), ({ [`to`]: (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty((selectedSessions[0]), 'locum.billingEmail')))),[`cc`]: (getObjectProperty(___arguments.context.appData, 'currentOrg.billingEmail')),[`body`]: (await BackendlessUI.Functions.Custom['fn_d17e964e29c4bb15c9de64bf5ef1ba7c']((getObjectProperty(___arguments.context.pageData, 'groupedSessions')), (getObjectProperty(___arguments.context.appData, 'currentOrg.orgName')), (getObjectProperty(___arguments.context.pageData, 'email.userBody')))) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/sessions-old/components/9823e10720359899a749a28817a80b85/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    var selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedSessions = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('b6bd9ba73949010d3139d63ee0192164'));
  if (selectedSessions) {
    if ((getObjectProperty(___arguments.context.pageData, 'email.type')) == 'client') {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'email.body', (await BackendlessUI.Functions.Custom['fn_83d5a6879d5ab7634fa6d5b1feeae15c']((getObjectProperty((selectedSessions[0]), 'clientName')), (getObjectProperty(___arguments.context.appData, 'currentOrg.orgName')), (getObjectProperty(___arguments.context.pageData, 'email.userBody')), selectedSessions)));
    } else {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'email.body', (await BackendlessUI.Functions.Custom['fn_d17e964e29c4bb15c9de64bf5ef1ba7c']((getObjectProperty(___arguments.context.pageData, 'groupedSessions')), (getObjectProperty(___arguments.context.appData, 'currentOrg.orgName')), (getObjectProperty(___arguments.context.pageData, 'email.userBody')))));
    }
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/sessions-old/components/05f36d41f6027515b031eea3e534b661/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var userOrgEmail;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userOrgEmail = (getObjectProperty(___arguments.context.pageData, 'email.cc'));
  if (userOrgEmail) {
    return (String('A copy will be sent to: ') + String(userOrgEmail));
  } else {
    return 'Please add a billing Email for your org to receive a copy';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/targets/components/4bc2afd7c571ffda1f656aac1fcd0edc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var targets, dropdown, users, year;


  await BackendlessUI.Functions.Custom['fn_e062c6ac1908ee48e847dbfd09d4f472'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/targets/components/4cd69b2ca041ee7be267fe5e39a7386f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var results, targets, item, prunedTargets, target;


  targets = (await (async function(dataArray) {
  	  // List of valid month keys
  	  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'decm'];

  	  // Helper function to check if a value is an integer
  	  function isInteger(value) {
  	    if (value === null) return true; // null will be replaced by 0
  	    if (typeof value === 'number' && Number.isInteger(value)) {
  	      return true;
  	    }
  	    if (typeof value === 'string' && /^\d+$/.test(value)) {
  	      return true;
  	    }
  	    return false;
  	  }

  	  // Process each object in the array
  	  for (let obj of dataArray) {
  	    for (let month of months) {
  	      // Check if the month property exists
  	      if (obj.hasOwnProperty(month)) {
  	        let value = obj[month];
  	        if (isInteger(value)) {
  	          if (value === null) {
  	            obj[month] = 0; // Replace null with 0
  	          } else {
  	            // Convert string numbers to integers
  	            obj[month] = parseInt(value, 10);
  	          }
  	        } else {
  	          // Return error message if the value is invalid
  	          return `${month.charAt(0).toUpperCase() + month.slice(1)} target for ${obj.name} is not valid. Must be a round number.`;
  	        }
  	      }
  	    }
  	  }

  	  // Return the modified array if all values are valid
  	  return dataArray;
  })((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getRowsData() })('42585b63916eab778e74cb9f9cab98ee'))));
  ___arguments.context.pageData['targets'] = targets;
  console.log(targets);
  results = (await Backendless.Data.of('targets').bulkUpsert( targets ));
  console.log(results);
  await BackendlessUI.Functions.Custom['fn_e062c6ac1908ee48e847dbfd09d4f472'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/targets/components/bc3a95c1d6522309d32a1a57324ba41c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('targets').deepSave( ({ [`year`]: (Number((getObjectProperty(___arguments.context.pageData, 'year')))),[`consultant`]: ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'toAdd')) }) }) );
  await BackendlessUI.Functions.Custom['fn_e062c6ac1908ee48e847dbfd09d4f472'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/targets/components/c979476349a40619178f19a92fea515e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selected;


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('0b4d979420aa976629bac1b82a99485a');

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Delete ',(getObjectProperty(___arguments.context.pageData, 'selected.consultant')),' from target list'].join(''))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/targets/components/42585b63916eab778e74cb9f9cab98ee/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  ['onCellClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selected'] = (getObjectProperty(___arguments['cellParams'], 'data'));

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/targets/components/0b4d979420aa976629bac1b82a99485a/bundle.js', [], () => ({
  /* content */
  /* handler:titleLogic */
  ['titleLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Delete ',(getObjectProperty(___arguments.context.pageData, 'selected.consultant')),'?'].join(''))

  },
  /* handler:titleLogic */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var selected;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selected = (getObjectProperty(___arguments.context.pageData, 'selected.objectId'));
  await Backendless.Data.of('targets').remove( selected );
  await BackendlessUI.Functions.Custom['fn_e062c6ac1908ee48e847dbfd09d4f472'](___arguments.context.appData, ___arguments.context.pageData);
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('0b4d979420aa976629bac1b82a99485a');

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/targets/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_e062c6ac1908ee48e847dbfd09d4f472'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/targets/components/4ade8dba28af05afaa86495a5f4a1efa/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_e062c6ac1908ee48e847dbfd09d4f472'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/test/components/4ed64b65b23224045ddfeebd258a56a7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      console.log('%c>> ' + 'datagrid', 'color: #4caf50', (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('21fd7c7afa03e9780c812a3b526b4b26')));
  console.log('%c>> ' + 'datagrid-component', 'color: #4caf50', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('21fd7c7afa03e9780c812a3b526b4b26')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timeComponent/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
      ___arguments.context.pageData['sessions'] = [({ [`start`]: 1727771400000,[`end`]: 1727782200000,[`break`]: 10,[`duration`]: 170 })];

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/timeComponent/components/fa9e7a43256d17384cfd200d5dbc55bf/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'sessions'))[0])

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/timesheets/components/bb05a5ed8bf989ba73a24fa732d82d88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      console.log((await BackendlessUI.Functions.Custom['fn_363dd2f66c6285b9d669ca1a2b3620fb'](___arguments.context.appData, ___arguments.context.pageData)));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/52141663b992af1b95b9905125ceb057/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var locum, locumId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  locum = (getObjectProperty(___arguments['cellParams'], 'data'));
  locumId = (getObjectProperty(locum, 'locumId'));
  Object.assign(___arguments.context.pageData, ({ [`selectedLocum`]: ({ [`objectId`]: locumId,[`billingEmail`]: (getObjectProperty(locum, 'locumEmail')),[`name`]: (getObjectProperty(locum, 'locum')),[`timesheet`]: null,[`email`]: null }),[`timesheetsPicker`]: ({ [`in`]: ({ [`locumId`]: (getObjectProperty(locum, 'locumId')),[`multi`]: false }),[`out`]: null }) }));
  console.log('%c>> [1:43:36 AM]: ' + 'OUTSTANDING SESSIONS LOADED', 'color: #26c6da', undefined);
  await BackendlessUI.Functions.Custom['fn_bff8b1efd3a8cf98c72677aed7d13e48'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/timesheets/components/42ebd56a32b7b69ad63974f457e7e112/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var thirdPanel;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4')['selectedChaseTable'] = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('42ebd56a32b7b69ad63974f457e7e112'));
  thirdPanel = (getObjectProperty(___arguments.context.pageData, 'ui.thirdPanel'));
  await BackendlessUI.Functions.Custom['fn_31fb469a79e9912288153bfa95861b3b'](___arguments.context.pageData, ___arguments.context.appData);
  await ( async function (componentUid, html) {  ___arguments.context.getComponentByUid(componentUid).setHtml(html) })('af0b1b3cea59fe844d9e0df3b41ae9ac', (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'email.body')));

  },
  /* handler:onCellClick */
  /* handler:rowsDataLogic */
  ['rowsDataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'outstandingSessions'))

  },
  /* handler:rowsDataLogic */
  /* handler:disabledLogic */
  ['disabledLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedLocum.timesheet')) && !(getObjectProperty(___arguments.context.pageData, 'selectedLocum.timesheet.readOnly')) ? true : false)

  },
  /* handler:disabledLogic */
  /* content */
}))

define('./pages/timesheets/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var timesheetsPicker;


  await BackendlessUI.Functions.Custom['fn_363dd2f66c6285b9d669ca1a2b3620fb'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/timesheets/components/d7e7889a8bf3666abeafe161566c212d/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      await ( async function (componentUid, html) {  ___arguments.context.getComponentByUid(componentUid).setHtml(html) })('af0b1b3cea59fe844d9e0df3b41ae9ac', (await BackendlessUI.Functions.Custom['fn_31fb469a79e9912288153bfa95861b3b'](___arguments.context.pageData, ___arguments.context.appData)));

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/timesheets/components/0c27b75be45738b1dfaf199a2168bd1a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var recipients;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function setRecipients() {
  recipients = [(getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.to'))];
  if (getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.cc')) {
    addItemToList(recipients, (getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.cc')));
  }
  return recipients
}


  await Backendless.Messaging.sendEmail((String('Outstanding Timesheets from ') + String(getObjectProperty(___arguments.context.appData, 'currentOrg.orgName'))), new Backendless.Bodyparts({ htmlmessage: (getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.body')) }), ensureArray((await setRecipients())), undefined);
  (function (message) { alert(message) })((String('Email sent! 🎉🎉 - A copy is sent to ') + String(getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.cc'))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/a232fe5d392f367da48876ef88d9912a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('f5ddd5ec161c503a465de329e0d8e743')) == 'tab_chase')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/1a9c3d3f9e152f31d7cfc60a301b80a5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('f5ddd5ec161c503a465de329e0d8e743')) == 'tab_timesheet')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/81c57dcdec4e6c16412b78b622650268/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.thirdPanel', 'chaseEmail');
  await ( async function (componentUid, html) {  ___arguments.context.getComponentByUid(componentUid).setHtml(html) })('af0b1b3cea59fe844d9e0df3b41ae9ac', (await BackendlessUI.Functions.Custom['fn_31fb469a79e9912288153bfa95861b3b'](___arguments.context.pageData, ___arguments.context.appData)));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/0705b5d2167852669639a334ba8989ab/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedLocum.email'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/9b7f7bee7dbacfeb109ea3df48228f9a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, timesheet, sessions, item, margin, processor, session, duration;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function prune(sessions) {
  return await Promise.all(sessions.map(async item => {; return ({ [`objectId`]: (getObjectProperty(item, 'objectId')),[`actualStart`]: (getObjectProperty(item, 'actualStart')),[`actualEnd`]: (getObjectProperty(item, 'actualEnd')),[`actualBreak`]: (getObjectProperty(item, 'actualBreak')) ? (getObjectProperty(item, 'actualBreak')) : 0,[`rateClient`]: (getObjectProperty(item, 'rateClient')),[`extraClient`]: (getObjectProperty(item, 'extraClient')) ? (getObjectProperty(item, 'extraClient')) : 0,[`rateLocum`]: (getObjectProperty(item, 'rateLocum')),[`extraLocum`]: (getObjectProperty(item, 'extraLocum')) ? (getObjectProperty(item, 'extraLocum')) : 0,[`notesSession`]: (getObjectProperty(item, 'notesSession')) });}))
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}

/**
 * Describe this function...
 */
async function calculateActualMargin(sessions, processor) {
  processor = (await asyncListFind((getObjectProperty(___arguments.context.appData, 'processors')), async (item) => {


   return ((getObjectProperty(item, 'processor')) == processor);
  }));
  if (!processor) {
    (function (message) { alert(message) })('CRITICAL ERROR : PROCESSOR NOT FOUND! CANNOT PROCEED.');
    throw (new Error('CRITICAL ERROR : PROCESSOR NOT FOUND!'))
  }
  for (var session_index in sessions) {
    session = sessions[session_index];
    duration = (await BackendlessUI.Functions.Custom['fn_b74c8eb6ac2467ea66e7b09125c4b966']((getObjectProperty(session, 'actualStart')), (getObjectProperty(session, 'actualEnd')), (getObjectProperty(session, 'actualBreak'))));
    margin = (await (async function(session, processorRate, vatRate, actualDuration) {
    	function roundMoney(num) {
    	  return Math.round(num * 100)/100;
    	}

    	let duration = actualDuration / 60;
    	// console.log('Duration:', duration);

    	// Check if any critical variable is null or undefined, return 0
    	if (duration == null || session.rateClient == null || session.rateLocum == null || processorRate == null || vatRate == null) {
    	  // console.log('One or more critical variables are null or undefined.');
    	  return 0;
    	}

    	// Default to 0 if extraClient or extraLocum are null or undefined
    	let extraClient = session.extraClient ?? 0;
    	let extraLocum = session.extraLocum ?? 0;
    	// console.log('Extra Client:', extraClient);
    	// console.log('Extra Locum:', extraLocum);

    	let costClient = roundMoney((session.rateClient * duration) + extraClient);
    	let costLocum = roundMoney((session.rateLocum * duration) + extraLocum);
    	let costAgency = roundMoney(costClient - costLocum);
    	let processorFee = roundMoney(costClient * processorRate);
    	let vat = roundMoney(costAgency / (1 + (1 / vatRate)));

    	// console.log('Rate Client:', session.rateClient);
    	// console.log('Rate Locum:', session.rateLocum);
    	// console.log('Processor Rate:', processorRate);
    	// console.log('VAT Rate:', vatRate);
    	// console.log('Cost Client:', costClient);
    	// console.log('Cost Locum:', costLocum);
    	// console.log('Cost Agency:', costAgency);
    	// console.log('Processor Fee:', processorFee);
    	// console.log('VAT:', vat);

    	let margin = roundMoney(costAgency - processorFee - vat);
    	let hourlyMargin = roundMoney(margin/duration);
    	// console.log('Result:', result);

    	return {
    	  'margin': margin,
    	  'hourlyMargin': hourlyMargin,
    	  'processorFee': processorFee,
    	  'vat': vat
    	};
    })(session, (getObjectProperty(processor, 'fee')), (getObjectProperty(___arguments.context.appData, 'vat')), duration));
    Object.assign(session, ({ [`processor`]: (getObjectProperty(processor, 'processor')),[`actualProcessorFee`]: (getObjectProperty(margin, 'processorFee')),[`actualVat`]: (getObjectProperty(margin, 'vat')) }));
  }
  return sessions
}


  try {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9b7f7bee7dbacfeb109ea3df48228f9a', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d9658513a9b8ab760ff6ec6e774e0adf', true);
    timesheet = ___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6');
    Object.assign(timesheet, ({ [`status`]: 'APPROVED',[`readOnly`]: true }));
    sessions = await prune((getObjectProperty(timesheet, 'sessions')));
    sessions = await calculateActualMargin(sessions, (getObjectProperty(timesheet, 'processor')));
    await Backendless.Request.post(`${Backendless.appPath}/services/SESSIONS/v1/sessions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'sessions': sessions,'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) });
    await Backendless.Request.post(`${Backendless.appPath}/services/TIMESHEETS/addTimesheets`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'timesheets': [timesheet] });
    delete (getObjectProperty(___arguments.context.pageData, 'selectedLocum'))['timesheet'];
    await BackendlessUI.Functions.Custom['fn_bff8b1efd3a8cf98c72677aed7d13e48'](___arguments.context.appData, ___arguments.context.pageData);
    (function (message) { alert(message) })('🎉 Timesheet Submitted! 🎉');

  } catch (error) {
    (function (message) { alert(message) })(error);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9b7f7bee7dbacfeb109ea3df48228f9a', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d9658513a9b8ab760ff6ec6e774e0adf', false);
    ___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6')['readOnly'] = false;

  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6'), 'file')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6'), 'objectId')) ? 'Update Timesheet' : 'Submit New Timesheet')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/timesheets/components/3ac7ceb1dbb8ed450a6b9293754f5d23/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6')

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/timesheets/components/68d18bb5fea8e8567d6d30a1feebe03f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedLocum.timesheet'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/05342888529ae6a2cd3ac9e6b007c238/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, file;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('05342888529ae6a2cd3ac9e6b007c238');
  try {
    file = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6'), 'file'));
    if (file) {
      await Backendless.Files.remove(encodePath(file));
    }
    await Backendless.Data.of('timesheets').remove( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6'), 'objectId')) );
    (function (message) { alert(message) })('🗑️ Timesheet Deleted! 🗑️');

  } catch (error) {
    (function (message) { alert(message) })(error);

  } finally {
    console.log('RESET UI');
    ___arguments.context.pageData['timesheetsPicker'] = ({ [`in`]: ({ [`locumId`]: (getObjectProperty(___arguments.context.pageData, 'selectedLocum.objectId')),[`multi`]: false }),[`out`]: null });
    delete (getObjectProperty(___arguments.context.pageData, 'selectedLocum'))['timesheet'];

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/timesheets/components/33539a49642f0a693f0cd935d0072da3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('05342888529ae6a2cd3ac9e6b007c238');

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(['DRAFT', 'APPROVED'].includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6'), 'status')))))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/timesheets/components/75b3bfe1815b5d190c9316827d21a745/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6'), 'readOnly')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/bc1246c9dfe35ddb163d45290f079d62/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6')['readOnly'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/1c9784346435cb56929897fa8d6c0bce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6'), 'objectId')) {
    await BackendlessUI.Functions.Custom['fn_b063f3ff234bb19422decd3f13ae13ae'](___arguments.context.pageData, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6'), 'objectId')));
  } else {
    delete (getObjectProperty(___arguments.context.pageData, 'selectedLocum'))['timesheet'];
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/1228cee39d78e82683a953a20f2b51b7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  delete (getObjectProperty(___arguments.context.pageData, 'selectedLocum'))['timesheet'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/22071fa86d79df86e2573f61d7c105d8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6'), 'readOnly')) && (['DRAFT', 'APPROVED'].includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8dd6da33892ea7ab96ecbd9b1b3189d6'), 'status')))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/de344def28767aaf04cb6cc7f80c2a14/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'selectedChaseTable'))))) + String(' Sessions selected'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets/components/add6883c31f422397323ccf99d69ea07/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'selectedSessions'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/226f21247a4f91f44c66207271570d09/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'selectedSessionsTable'))))) + String(' Sessions selected'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets/components/bacf05755163daa1cdde8c438d1298ed/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_35adebfca318334c7b3df2f3ec67e7e4'](___arguments.context.pageData, ___arguments.context.appData);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await (async function(objectsArray) {
	    if (!Array.isArray(objectsArray)) {
	        return false; // Return false if input is not an array
	    }

	    const clientIds = new Set(); // Use a Set to store unique clientIds

	    for (const obj of objectsArray) {
	        if (obj.clientId) { // Check if clientId exists in the object
	            clientIds.add(obj.clientId);
	        }
	    }

	    return clientIds.size === 1
})((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'selectedSessionsTable'))))

  },
  /* handler:onDisplayAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'timesheet'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/timesheets/components/8446b1d6fd2465cb4f0c090fef1d0af2/bundle.js', [], () => ({
  /* content */
  /* handler:rowsDataLogic */
  ['rowsDataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedLocum.outstandingSessions'))

  },
  /* handler:rowsDataLogic */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4')['selectedSessionsTable'] = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('8446b1d6fd2465cb4f0c090fef1d0af2'));

  },
  /* handler:onCellClick */
  /* handler:disabledLogic */
  ['disabledLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'timesheet'))

  },
  /* handler:disabledLogic */
  /* content */
}))

define('./pages/timesheets/components/17ed825f6c23f6e4671ec3babed7c667/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await (async function(objectsArray) {
	    if (!Array.isArray(objectsArray)) {
	        return false; // Return false if input is not an array
	    }

	    const clientIds = new Set(); // Use a Set to store unique clientIds

	    for (const obj of objectsArray) {
	        if (obj.clientId) { // Check if clientId exists in the object
	            clientIds.add(obj.clientId);
	        }
	    }

	    return clientIds.size === 1
})((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'selectedSessionsTable')))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/19a3c4c2dfbc3f24f7cd63b9fafd97c6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('f5ddd5ec161c503a465de329e0d8e743')) == 'tab_createTimesheet')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets/components/d7b610b95bd403f1da7369cb5692c4d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  delete (getObjectProperty(___arguments.context.pageData, 'selectedLocum'))['email'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets/components/f5ddd5ec161c503a465de329e0d8e743/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('%c>> ' + 'TAB SELECTOR', 'color: #26a69a', undefined);
  Object.assign(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), ({ [`timesheet`]: null,[`email`]: null }));
  ___arguments.context.pageData['timesheetsPicker'] = null;
  if (___arguments['currentTabId'] == 'tab_timesheet') {
    console.log('TS TAB SELECTED');
    ___arguments.context.pageData['timesheetsPicker'] = ({ [`in`]: ({ [`locumId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'objectId')),[`multi`]: false }),[`out`]: null });
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets/components/0d646ff4ccdba58747380dc814ec1ff8/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'timesheetsPicker'))

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/timesheets/components/2d3dd33f2adf8abd3a9a0e87778607bc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var timesheet, timesheetId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  await new Promise(r => setTimeout(r, 20 || 0));
  timesheetId = (getObjectProperty(___arguments.context.pageData, 'timesheetsPicker.out.timesheetId'));
  stopSetTimeout(timesheetId);

  ;(function() {
    const callback = async () => {
        if (timesheetId && timesheetId != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'), 'timesheet.objectId'))) {
      timesheet = (await Backendless.Data.of('timesheets').findById(timesheetId, Backendless.DataQueryBuilder.create().setRelationsDepth(1)));
      timesheet['sessions'] = (await BackendlessUI.Functions.Custom['fn_8b9f564fdda88d8b74ab06d3e55b7ae4']((getObjectProperty(timesheet, 'sessions'))));
      timesheet['readOnly'] = true;
      console.log('%c>> 🔒 [1:51:24 AM]: ' + 'COMMAND BLOCK TRIGGERED', 'color: #4caf50', JSON.parse(JSON.stringify(___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4'))));
      ___arguments.context.getComponentDataStoreByUid('0fa330e550f8c4c686db2618b0a8f9c4')['timesheet'] = timesheet;
    }

    };

    const timerId = timesheetId;
    const timerDelay = 20;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets/components/172330257b4e0f9cd10776cc8a74c368/bundle.js', [], () => ({
  /* content */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'locums.allWithNone'))

  },
  /* handler:optionsLogic */
  /* handler:onButtonClearClick */
  async ['onButtonClearClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_363dd2f66c6285b9d669ca1a2b3620fb'](___arguments.context.appData, ___arguments.context.pageData, null);

  },
  /* handler:onButtonClearClick */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var text;


  if (___arguments['inputValue'].charAt(23) == '-' && ___arguments['inputValue'].charAt(8) == '-') {
    await BackendlessUI.Functions.Custom['fn_363dd2f66c6285b9d669ca1a2b3620fb'](___arguments.context.appData, ___arguments.context.pageData, ___arguments['inputValue']);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets/components/99de06e4a207d5ac008cc9f7746cf175/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2b45d7fba14d9b8f7b81e2176b6f517f', (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2b45d7fba14d9b8f7b81e2176b6f517f')), 'display'))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old/components/4edee263b4cbd0283904a3031488ae79/bundle.js', [], () => ({
  /* content */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selected.locumId')) {
    return 'Locum';
  } else {
    return '-- Select Locum --';
  }

  },
  /* handler:placeholderLogic */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, '___ui.locum-autocomplete')) ? (getObjectProperty(___arguments.context.pageData, '___ui.locum-autocomplete')) : [])

  },
  /* handler:optionsLogic */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var result;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  result = (await BackendlessUI.Functions.Custom['fn_29957d83d80974dad1a157b43bf89f85'](___arguments['inputValue'], 'locums', 'locum-autocomplete', ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))));
  if (result) {
    await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'selected.locumId', result);
  }
  ___arguments.context.pageData['sessions'] = (await BackendlessUI.Functions.Custom['fn_95c944c98e485f86d92494227ab43547']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')), (getObjectProperty(___arguments.context.pageData, 'selected.locumId')), (getObjectProperty(___arguments.context.pageData, 'selected.clientId')), null));

  },
  /* handler:onChange */
  /* handler:onButtonClearClick */
  async ['onButtonClearClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'selected.locumId', null);

  },
  /* handler:onButtonClearClick */
  /* content */
}))

define('./pages/timesheets-old/components/50b1901beb12969a63435b25522adccf/bundle.js', [], () => ({
  /* content */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selected.clientId')) {
    return 'Client';
  } else {
    return '-- Select Client --';
  }

  },
  /* handler:placeholderLogic */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, '___ui.client-autocomplete')) ? (getObjectProperty(___arguments.context.pageData, '___ui.client-autocomplete')) : [])

  },
  /* handler:optionsLogic */
  /* handler:onButtonClearClick */
  async ['onButtonClearClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'selected.clientId', null);

  },
  /* handler:onButtonClearClick */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var result;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  result = (await BackendlessUI.Functions.Custom['fn_29957d83d80974dad1a157b43bf89f85'](___arguments['inputValue'], 'clients', 'client-autocomplete', ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))));
  if (result) {
    await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'selected.clientId', result);
  }
  ___arguments.context.pageData['sessions'] = (await BackendlessUI.Functions.Custom['fn_95c944c98e485f86d92494227ab43547']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')), (getObjectProperty(___arguments.context.pageData, 'selected.locumId')), (getObjectProperty(___arguments.context.pageData, 'selected.clientId'))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets-old/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onTitleAssignment */
  ['onTitleAssignment'](___arguments) {
      return 'Loco Dev - Timesheets'

  },
  /* handler:onTitleAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/2204ca7efcbe2546a793ee65915ecc61/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var url;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_e091c5a99d6cb561c1c5d926db56846f']((getObjectProperty(___arguments.context.pageData, 'fileId')), 'timesheets');

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var fileUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  fileUrl = (getObjectProperty(___arguments.context.pageData, 'timesheet.file'));
  if (fileUrl) {
    return (await BackendlessUI.Functions.Custom['fn_b78404622ac4324879cd1a77d399f5ef'](fileUrl, 'timesheets'));
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onDelete */
  async ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.pageData, 'timesheet.file'))));
  delete (getObjectProperty(___arguments.context.pageData, 'timesheet'))['file'];
  await Backendless.Data.of('timesheets').save(({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'timesheet.objectId')),[`file`]: null }), true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('ea36b9447a22be8a82c60071862b4e73');

  },
  /* handler:onDelete */
  /* content */
}))

define('./pages/timesheets-old/components/ea36b9447a22be8a82c60071862b4e73/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      (function (message) { alert(message) })('Upload Failed. Try again or contact support.');
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('ea36b9447a22be8a82c60071862b4e73');

  },
  /* handler:onUploadFail */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(generateUUID().short()) + String(getObjectProperty(___arguments.file, 'name')))

  },
  /* handler:onFileNameAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'timesheet')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('Upload Success');
  console.log(___arguments.uploadedFiles);
  (getObjectProperty(___arguments.context.pageData, 'timesheet'))['file'] = ___arguments.uploadedFiles;
  await Backendless.Data.of('timesheets').save(({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'timesheet.objectId')),[`file`]: ___arguments.uploadedFiles }), true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('ea36b9447a22be8a82c60071862b4e73');

  },
  /* handler:onUploadSuccess */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'timesheet.file')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/2a366a87a6bbfb682ed96c5c7952eeb7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var timesheet;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  timesheet = (await Backendless.Data.of('timesheets').deepSave( ({ [`agency`]: ({ [`objectId`]: (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) }),[`client`]: ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selected.clientId')) }),[`locum`]: ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selected.locumId')) }) }) ));
  ___arguments.context.pageData['timesheet'] = timesheet;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selected.clientId')) && (getObjectProperty(___arguments.context.pageData, 'selected.locumId')) ? false : true)

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'timesheet')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/42023ca7c62fe896520fddab09c9b98c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await Backendless.Data.of('timesheets').remove( (getObjectProperty(___arguments.context.pageData, 'timesheet')) );
    delete ___arguments.context.pageData['timesheet'];
    (function (message) { alert(message) })('Timesheet Deleted!');

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old/components/5546132194ae7a652419d8175f9231ed/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, item, selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  try {
    selectedSessions = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'sessions')), async (item) => {


     return ((getObjectProperty(item, 'checked')) == true);
    }));
    await Backendless.Data.of('timesheets').setRelation((getObjectProperty(___arguments.context.pageData, 'timesheet.objectId')), 'sessions', (selectedSessions.map(item => item['objectId'])));
    (getObjectProperty(___arguments.context.pageData, 'timesheet'))['sessions'] = selectedSessions;
    (function (message) { alert(message) })((String(selectedSessions.length) + String(' sessions linked to timesheet.')));

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_8d4b531b860a4ba62584e6712dfb87eb']((getObjectProperty(___arguments.context.pageData, 'timesheet.sessions'))))) {
    return 'Link Sessions with Timesheet';
  }
  return 'Re-Link Sessions with Timesheet';

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/399216a84743739ce3fbf843db7a87f1/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var actualUkEnd, actualUkStart, alreadyAddedSessions, item, j, sessions, timesheet, ukEnd, ukStart;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  timesheet = (await Backendless.Data.of('timesheets').findById((getObjectProperty(___arguments['cellParams'], 'data.objectId')), Backendless.DataQueryBuilder.create().setRelationsDepth(1)));
  ___arguments.context.pageData['timesheet'] = timesheet;
  sessions = (await BackendlessUI.Functions.Custom['fn_95c944c98e485f86d92494227ab43547']((getObjectProperty(timesheet, 'agency.objectId')), (getObjectProperty(timesheet, 'locum.objectId')), (getObjectProperty(timesheet, 'client.objectId'))));
  alreadyAddedSessions = (getObjectProperty(timesheet, 'sessions'));
  for (var j_index in alreadyAddedSessions) {
    j = alreadyAddedSessions[j_index];
    if (!(getObjectProperty(j, 'actualStart'))) {
      j['actualBreak'] = (getObjectProperty(j, 'break'));
      j['actualStart'] = (getObjectProperty(j, 'start'));
      j['actualEnd'] = (getObjectProperty(j, 'start'));
    }
    ukStart = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'start'))));
    ukEnd = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'end'))));
    actualUkStart = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'actualStart'))));
    actualUkEnd = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(j, 'actualEnd'))));
    Object.assign(j, ({ [`date`]: [(getObjectProperty(ukStart, 'weekday')),' ',(getObjectProperty(ukStart, 'dateString'))].join(''),[`startTime`]: (getObjectProperty(ukStart, 'timeString')),[`endTime`]: (getObjectProperty(ukEnd, 'timeString')),[`actualDate`]: [(getObjectProperty(actualUkStart, 'weekday')),' ',(getObjectProperty(actualUkStart, 'dateString'))].join(''),[`actualStartTime`]: (getObjectProperty(actualUkStart, 'timeString')),[`actualEndTime`]: (getObjectProperty(actualUkEnd, 'timeString')),[`checked`]: true }));
  }
  addItemToList(sessions, alreadyAddedSessions);
  sessions = (await asyncListSort(sessions, 1, async (item) => {


   return (getObjectProperty(item, 'start'));
  }));
  ___arguments.context.pageData['sessions'] = sessions;

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/timesheets-old/components/2339caebdba68a4dd9718261de5adbc2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['timesheet'];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a7f74354628e22128e1a43cbcc42bb4', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf932efaf92aeeada174520192d5bf4f', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old/components/d2aff501606e654a9d6ef47b819fdb32/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a7f74354628e22128e1a43cbcc42bb4', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf932efaf92aeeada174520192d5bf4f', true);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'timesheet')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/5117e61391a6c3633e3b5e9e443180e7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var class2, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  class2 = ['row-item'];
  if (getObjectProperty(___arguments.context.dataModel, 'checked')) {
    addItemToList(class2, 'selected');
  }

  return class2

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old/components/69c8892df551a7aefeb2886859a3f597/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    (getObjectProperty(___arguments.context.pageData, 'timesheet'))['status'] = 'APPROVED';
    await Backendless.Data.of('sessions').bulkUpsert( (getObjectProperty(___arguments.context.pageData, 'timesheet.sessions')) );
    await Backendless.Data.of('timesheets').save(({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'timesheet.objectId')),[`status`]: 'APPROVED' }), true);
    delete ___arguments.context.pageData['timesheet'];
    delete ___arguments.context.pageData['sessions'];
    ___arguments.context.pageData['timesheets'] = (await BackendlessUI.Functions.Custom['fn_e73160caa2fefdbdf569314b42137f4c']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))));
    (function (message) { alert(message) })('Timesheet Submitted 🎉🎉');

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old/components/3e638609f7fa11dc9e7a202a194db2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var sessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessions = (getObjectProperty(___arguments.context.pageData, 'timesheet.sessions'));
  if (sessions && sessions.length > 0) {
    return true;
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/02a4b3f1621bd832a7e979fd5c0e3262/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('de3899fdc42398b8c3084347c812f0f6')) == 'tab-submitted')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/85e90895be8e580c58a88af9f35633bb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('de3899fdc42398b8c3084347c812f0f6')) == 'tab-submit')

  },
  /* handler:onDisplayAssignment */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
      return ___arguments.context.pageData

  },
  /* handler:onContainerDataAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/cf4a42bfea2e73a84099a42f76eee313/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('de3899fdc42398b8c3084347c812f0f6')) == 'tab-outstanding')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/de3899fdc42398b8c3084347c812f0f6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var sessions, endObj, startObj, session, sessionBatch, txResult, page, tx, offset, prop, whereClause, transaction, txCount, pageCount, sessionCount, today;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function loadOutstanding() {
  today = ((new Date()).valueOf());
  whereClause = 0;
  prop = ['objectId', 'client.objectId AS clientId', 'client.orgName AS client', 'locum.objectId AS locumId', 'locum.orgName AS locum', 'start', 'end'];
  sessionCount = (await Backendless.Data.of('sessions').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause(whereClause)));
  pageCount = Math.ceil(sessionCount / 100);
  txCount = Math.ceil(sessionCount / 1000);
  console.log('=== DEBUG');
  console.log(sessionCount);
  console.log(pageCount);
  console.log(txCount);
  sessions = [];
  var transaction_inc = 1;
  if (1 > txCount) {
    transaction_inc = -transaction_inc;
  }
  for (transaction = 1; transaction_inc >= 0 ? transaction <= txCount : transaction >= txCount; transaction += transaction_inc) {
    tx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
    for (page = 1; page <= 10; page++) {
      offset = (transaction - 1) * 1000 + (page - 1) * 100;
      await ( async () => {
        const _uow = tx;

        _uow.find('sessions', Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setRelationsDepth(1).setSortBy('start').setPageSize(100).setOffset(offset)).setOpResultId((String('p') + String(page)));

      })();

    }
    txResult = (await tx.execute());
    console.log(txResult);
    for (page = 1; page <= 10; page++) {
      sessionBatch = (getObjectProperty(txResult, (['results.p',page,'.result'].join(''))));
      console.log('adding...');
      console.log(sessionBatch);
      addItemToList(sessions, sessionBatch);
    }
  }
  console.log('Total Session Count');
  console.log(sessions.length);
  console.log('Unique Session Count');
  console.log((await (async function(objects) {
  	  const uniqueIds = new Set(objects.map(obj => obj.objectId));
  	  return uniqueIds.size;
  })(sessions)));
  for (var session_index in sessions) {
    session = sessions[session_index];
    startObj = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(session, 'start'))));
    endObj = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(session, 'end'))));
    Object.assign(session, ({ [`date`]: (getObjectProperty(startObj, 'isoDateString')),[`startTime`]: (getObjectProperty(startObj, 'timeString')),[`endTime`]: (getObjectProperty(endObj, 'timeString')),[`clientId`]: (getObjectProperty(session, 'client.objectId')),[`client`]: (getObjectProperty(session, 'client.orgName')),[`locumId`]: (getObjectProperty(session, 'locum.objectId')),[`locum`]: (getObjectProperty(session, 'locum.orgName')) }));
  }
  return sessions
}

/**
 * Describe this function...
 */
async function do_something() {
  today = ((new Date()).valueOf());
  sessions = (await Backendless.Data.of('sessions').find(Backendless.DataQueryBuilder.create().setWhereClause((['agency.objectId = \'',(getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'\' AND locum.objectId IS NOT NULL AND end <',today].join(''))).setProperties(['client.objectId AS clientId', 'client.orgName AS client', 'locum.objectId AS locumId', 'locum.orgName AS locum', 'start', 'end']).setSortBy('start').setPageSize(100)));
  for (var session_index2 in sessions) {
    session = sessions[session_index2];
    startObj = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(session, 'start'))));
    endObj = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(session, 'end'))));
    Object.assign(session, ({ [`date`]: (getObjectProperty(startObj, 'isoDateString')),[`startTime`]: (getObjectProperty(startObj, 'timeString')),[`endTime`]: (getObjectProperty(endObj, 'timeString')) }));
  }
}


  if (___arguments['currentTabId'] == 'tab-submitted') {
    delete ___arguments.context.pageData['timesheet'];
  } else if (___arguments['currentTabId'] == 'tab-submit') {
    ___arguments.context.pageData['timesheets'] = (await BackendlessUI.Functions.Custom['fn_e73160caa2fefdbdf569314b42137f4c']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))));
    console.log('Draft Timesheets loaded');
  } else if (___arguments['currentTabId'] == 'tab-outstanding') {
    ___arguments.context.pageData['outstandingSessions'] = (await loadOutstanding());
    console.log('Outstanding Sessions loaded');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets-old/components/ea5af923a855b51eeb39ee0eea498a79/bundle.js', [], () => ({
  /* content */
  /* handler:onWhereClauseAssignment */
  ['onWhereClauseAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['agencyId = \'',(getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'\''].join(''))

  },
  /* handler:onWhereClauseAssignment */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['timesheet'] = (await Backendless.Data.of('timesheets').findById((getObjectProperty(___arguments.selectedRecord, 'objectId')), Backendless.DataQueryBuilder.create().setRelationsDepth(1)));

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/timesheets-old/components/5051e3cea60b1c45b0218b87b5569db5/bundle.js', [], () => ({
  /* content */
  /* handler:onContainerDataAssignment */
  ['onContainerDataAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'timesheet'))

  },
  /* handler:onContainerDataAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'timesheet'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/2f146e7376181f990ddb56b1afdbb42f/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var fileUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_b78404622ac4324879cd1a77d399f5ef']((getObjectProperty(___arguments.context.pageData, 'timesheet.file')), 'timesheets'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_e091c5a99d6cb561c1c5d926db56846f']((getObjectProperty(___arguments.context.pageData, 'timesheet.file')), 'timesheets');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old/components/8944a848c543f74ff99d377958eaa662/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5051e3cea60b1c45b0218b87b5569db5'), 'sessions'))))) + String(' Session(s)'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/7ed58a3309f1030b2bdd7d07ecf26b0c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var dateString, dateStringSet, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dateStringSet = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('543306549ea7ab95fb458459f91c4ae9'), 'actualStart'))));
  dateString = (getObjectProperty(dateStringSet, 'dateString')).slice(0, 5);

  return ([(getObjectProperty(dateStringSet, 'weekday')),' ',dateString].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/2166c3d50ad2c07c252e6398b20612cc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var endString, startString;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  startString = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('543306549ea7ab95fb458459f91c4ae9'), 'actualStart')))), 'timeString'));
  endString = (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('543306549ea7ab95fb458459f91c4ae9'), 'actualEnd')))), 'timeString'));

  return ([startString,'-',endString].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/11284ab8e2cf802bcaa5b148b39fd522/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Notes: ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('b1ae6681dba4c354d5ac5fb76aaad69d'), 'notesSession')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old/components/d22796ea12b815788da5171df6382dec/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d22796ea12b815788da5171df6382dec');
  try {
    await Backendless.Data.of('timesheets').remove( (getObjectProperty(___arguments.context.pageData, 'timesheet.objectId')) );
    (function (message) { alert(message) })('timesheet Deleted! Note : All associated sessions are still there.');
    delete ___arguments.context.pageData['timesheet'];

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/timesheets-old/components/bec7e6030068517799c7645d0a194b4c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('d22796ea12b815788da5171df6382dec');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old/components/88d5d607cc96a65b48a310d27fc7d97f/bundle.js', [], () => ({
  /* content */
  /* handler:rowsDataLogic */
  ['rowsDataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'outstandingSessions'))

  },
  /* handler:rowsDataLogic */
  /* content */
}))

define('./pages/timesheets-old/components/651eb4663279bf555ed90cd2c304fcac/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_a70b96a266543c5ed4930e623734c10f']((getObjectProperty(___arguments.context.pageData, 'outstandingSessions')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old/components/f2df82f1135471e6ccd4175e658d1b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedSessions, sessionIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessionIds = ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('88d5d607cc96a65b48a310d27fc7d97f')).map(item => item['objectId']));
  selectedSessions = (await BackendlessUI.Functions.Custom['fn_666d976d080af841315daaf37fbb8b97'](sessionIds, (getObjectProperty(___arguments.context.pageData, 'outstandingSessions'))));
  console.log('sessionIds');
  console.log(sessionIds);
  console.log('selectedSessions');
  console.log(selectedSessions);
  console.log('outstandingSessions');
  console.log(getObjectProperty(___arguments.context.pageData, 'outstandingSessions'));
  await BackendlessUI.Functions.Custom['fn_a70b96a266543c5ed4930e623734c10f'](selectedSessions);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old2/components/6719c6722d242974b54ffa18101fadb3/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selected'] = (await Backendless.Data.of('timesheets').findById((getObjectProperty(___arguments.selectedRecord, 'objectId')), Backendless.DataQueryBuilder.create().setRelationsDepth(1)));

  },
  /* handler:onRecordSelect */
  /* handler:onFilteringAssignment */
  ['onFilteringAssignment'](___arguments) {
      return true

  },
  /* handler:onFilteringAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/43d48605def42be22a61c9cd3f88fba8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selected.objectId')) {
    return 'Selected Timesheet';
  } else {
    return 'New Timesheet';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/c0986471de2f71bd1d9cf007d051d782/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_e091c5a99d6cb561c1c5d926db56846f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'file')), 'timesheets');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old2/components/2dd8f76598a2c1dfcada4845cf9ba688/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'sessions'))))) + String(' session(s) in this timesheet'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/c08f7acb4773855f0857b7f81a1cc142/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('timesheets').remove( (getObjectProperty(___arguments.context.pageData, 'selected.objectId')) );
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('c08f7acb4773855f0857b7f81a1cc142');

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/timesheets-old2/components/dff8d4198bb35de5d758f0c334f32e06/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('c08f7acb4773855f0857b7f81a1cc142');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old2/components/6ce81b6b788b70338665bb4eada6ab41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['selected'] = ({ [`client`]: ({ [`objectId`]: undefined }),[`locum`]: ({ [`objectId`]: undefined }) });
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.editable', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old2/components/6daa498377f7cb4c919aaae7a4c780b4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selected, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'objectId'))) {
    selected = (await Backendless.Request.post(`${Backendless.appPath}/services/TIMESHEETS/addTimesheets`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'timesheets': [___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472')] }));
    ___arguments.context.pageData['selected'] = (selected[0]);
  }
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goNextStep() })('1c2e5813027687cfd24a6fdcdc5fed8c');
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.submitStep', (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentStep() })('1c2e5813027687cfd24a6fdcdc5fed8c')));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'client.objectId')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'locum.objectId')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'locum.objectId')) != 'DELETE'))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'objectId')) {
    return 'Amend';
  } else {
    return 'Next';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/49689a5c29947e825e849d9532d9fee9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) == 1 || !(getObjectProperty(___arguments.context.pageData, 'ui.submitStep')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/2f9941edad25d36afbe2ca6119b9a268/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) == 2)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/084da18cdd9c477279b678dc8171f48f/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var selected;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selected = (getObjectProperty(___arguments.context.pageData, 'selected'));
  Object.assign(selected, ({ [`file`]: ___arguments.uploadedFiles,[`filename`]: (await BackendlessUI.Functions.Custom['fn_b78404622ac4324879cd1a77d399f5ef'](___arguments.uploadedFiles, 'timesheets')) }));
  await Backendless.Data.of('timesheets').save(({ [`objectId`]: (getObjectProperty(selected, 'objectId')),[`file`]: (getObjectProperty(selected, 'file')),[`filename`]: (getObjectProperty(selected, 'filename')) }), true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('084da18cdd9c477279b678dc8171f48f');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      (function (message) { alert(message) })('Upload Failed. Try again or contact support.');
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('084da18cdd9c477279b678dc8171f48f');

  },
  /* handler:onUploadFail */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'selected.file')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(generateUUID().short()) + String(getObjectProperty(___arguments.file, 'name')))

  },
  /* handler:onFileNameAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'selected')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/852259f594c3039d0971bb9d25170a89/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_e091c5a99d6cb561c1c5d926db56846f']((getObjectProperty(___arguments.context.pageData, 'selected.file')), 'timesheets');

  },
  /* handler:onClick */
  /* handler:onDelete */
  async ['onDelete'](___arguments) {
    var selected;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  selected = (getObjectProperty(___arguments.context.pageData, 'selected'));
  await Backendless.Files.remove(encodePath((getObjectProperty(selected, 'file'))));
  delete selected['file'];
  delete selected['filename'];
  await Backendless.Data.of('timesheets').save(({ [`objectId`]: (getObjectProperty(selected, 'objectId')),[`file`]: null,[`filename`]: null }), true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('084da18cdd9c477279b678dc8171f48f');

  },
  /* handler:onDelete */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var fileUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  fileUrl = (getObjectProperty(___arguments.context.pageData, 'selected.file'));
  if (fileUrl) {
    return (await BackendlessUI.Functions.Custom['fn_b78404622ac4324879cd1a77d399f5ef'](fileUrl, 'timesheets'));
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/ccd6ae32a176347d46eb0e871de87b15/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goNextStep() })('1c2e5813027687cfd24a6fdcdc5fed8c');
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.submitStep', (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentStep() })('1c2e5813027687cfd24a6fdcdc5fed8c')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old2/components/c20b789583adca3e02c286a412ea4c4d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goPrevStep() })('1c2e5813027687cfd24a6fdcdc5fed8c');
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.submitStep', (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentStep() })('1c2e5813027687cfd24a6fdcdc5fed8c')));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) && (getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) != 1)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/4fb35df77128c005333be7841c43d881/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var sessions, session, today;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) == 3)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/373bffeefd7c4ed34b6c7a7bf3565872/bundle.js', [], () => ({
  /* content */
  /* handler:rowsDataLogic */
  async ['rowsDataLogic'](___arguments) {
    var sessions, session, today;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'selected.objectId'))) {
    return ;
  }
  sessions = (getObjectProperty(___arguments.context.pageData, 'sessions'));
  if (sessions && !!sessions.length) {
    return sessions;
  }
  today = ((new Date()).valueOf());
  sessions = (await Backendless.Request.get(`${Backendless.appPath}/services/SESSIONS/v1/sessions`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'orgId': JSON.stringify((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))),'whereClause': JSON.stringify((['locum.objectId = \'',(getObjectProperty(___arguments.context.pageData, 'selected.locum.objectId')),'\' AND client.objectId = \'',(getObjectProperty(___arguments.context.pageData, 'selected.client.objectId')),'\' AND timesheets[sessions].objectId IS NULL AND end < ',today].join(''))) }).send());
  for (var session_index in sessions) {
    session = sessions[session_index];
    await BackendlessUI.Functions.Custom['fn_8564e643897ab40ff5d31e1473139bce'](session);
  }
  ___arguments.context.pageData['sessions'] = sessions;
  return sessions;

  },
  /* handler:rowsDataLogic */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var selectedSessions, sessions, selectedIds, selectedRows;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedRows = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('373bffeefd7c4ed34b6c7a7bf3565872'));
  sessions = (getObjectProperty(___arguments.context.pageData, 'sessions'));
  selectedIds = (selectedRows.map(item => item['sessionId']));
  selectedSessions = (await BackendlessUI.Functions.Custom['fn_666d976d080af841315daaf37fbb8b97'](selectedIds, sessions));
  Object.assign((getObjectProperty(___arguments.context.pageData, 'selected')), ({ [`sessions`]: (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selectedSessions)) }));

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/timesheets-old2/components/e2d9703b68d766da388aa42a853b87da/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var sessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sessions = (getObjectProperty(___arguments.context.pageData, 'selected.sessions'));
  if (sessions && !!sessions.length) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedSessions, sessions, selectedIds, selectedRows;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedRows = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('373bffeefd7c4ed34b6c7a7bf3565872'));
  sessions = (getObjectProperty(___arguments.context.pageData, 'sessions'));
  selectedIds = (selectedRows.map(item => item['sessionId']));
  selectedSessions = (await BackendlessUI.Functions.Custom['fn_666d976d080af841315daaf37fbb8b97'](selectedIds, sessions));
  Object.assign((getObjectProperty(___arguments.context.pageData, 'selected')), ({ [`sessions`]: (await BackendlessUI.Functions.Custom['fn_8b9f564fdda88d8b74ab06d3e55b7ae4']((await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selectedSessions)))) }));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goNextStep() })('1c2e5813027687cfd24a6fdcdc5fed8c');
  (getObjectProperty(___arguments.context.pageData, 'ui'))['submitStep'] = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentStep() })('1c2e5813027687cfd24a6fdcdc5fed8c'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old2/components/80bc60d40b47f799910ccf57923efe1f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) == 4)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/b86aaeede34617057778ff21ff8a9e95/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Select sessions to include (',(await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.pageData, 'selected.sessions')))),' sessions linked)'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/042efe5d1e299095fb5f28b1906db8b5/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_2d66f332efdfa294dacc1adcc07afcb4']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDuration'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/c2c90621e233006188c6967ef4b7c21c/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    var dateStringObj, newDates, session;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  session = ___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8');
  if (___arguments['value'] == (getObjectProperty(session, 'actualDateStrings.start.date'))) {
    return ;
  }
  newDates = (await BackendlessUI.Functions.Custom['fn_2d49dc39e5110a714d2836779fb915f7']('start', ___arguments['value'], (getObjectProperty(session, 'actualDateStrings.start.time')), (getObjectProperty(session, 'actualDateStrings.end.time')), (getObjectProperty(session, 'actualBreak')), (getObjectProperty(session, 'actualDuration'))));
  Object.assign(session, ({ [`actualStart`]: (getObjectProperty(newDates, 'start')),[`actualEnd`]: (getObjectProperty(newDates, 'end')),[`actualDuration`]: (getObjectProperty(newDates, 'duration')) }));
  Object.assign((getObjectProperty(session, 'actualDateStrings.start')), (getObjectProperty(newDates, 'startString')));
  Object.assign((getObjectProperty(session, 'actualDateStrings.end')), (getObjectProperty(newDates, 'endString')));

  },
  /* handler:onComplete */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.start.partialDate', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalDate, dateStringObj, partialDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dateStringObj = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.start'));
  partialDate = (getObjectProperty(dateStringObj, 'partialDate'));
  if ((partialDate.indexOf('d') !== -1) || (partialDate.indexOf('m') !== -1) || (partialDate.indexOf('y') !== -1)) {
    console.log('invalid date detected');
    originalDate = (getObjectProperty(dateStringObj, 'date'));
    dateStringObj['date'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    dateStringObj['date'] = originalDate;
  }

  },
  /* handler:onLostFocusEvent */
  /* content */
}))

define('./pages/timesheets-old2/components/d4ae51ebc7d76e3e1cfd2dfe7bfb5488/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    var newDates, session;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  session = ___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8');
  if (___arguments['value'] == (getObjectProperty(session, 'actualDateStrings.start.time'))) {
    return ;
  }
  newDates = (await BackendlessUI.Functions.Custom['fn_2d49dc39e5110a714d2836779fb915f7']('start', (getObjectProperty(session, 'actualDateStrings.start.date')), ___arguments['value'], (getObjectProperty(session, 'actualDateStrings.end.time')), (getObjectProperty(session, 'actualBreak')), (getObjectProperty(session, 'actualDuration'))));
  Object.assign(session, ({ [`actualStart`]: (getObjectProperty(newDates, 'start')),[`actualEnd`]: (getObjectProperty(newDates, 'end')),[`actualDuration`]: (getObjectProperty(newDates, 'duration')) }));
  Object.assign((getObjectProperty(session, 'actualDateStrings.start')), (getObjectProperty(newDates, 'startString')));
  Object.assign((getObjectProperty(session, 'actualDateStrings.end')), (getObjectProperty(newDates, 'endString')));

  },
  /* handler:onComplete */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.start.partial', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalTime, dateStringObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dateStringObj = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.start'));
  if ((getObjectProperty(dateStringObj, 'partial')).indexOf('_') !== -1) {
    originalTime = (getObjectProperty(dateStringObj, 'time'));
    dateStringObj['time'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    dateStringObj['time'] = originalTime;
  }

  },
  /* handler:onLostFocusEvent */
  /* content */
}))

define('./pages/timesheets-old2/components/52452e3a6202298ff88153a4e4745d2f/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    var newDates, session;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  session = ___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8');
  if (___arguments['value'] == (getObjectProperty(session, 'actualDateStrings.end.time'))) {
    return ;
  }
  newDates = (await BackendlessUI.Functions.Custom['fn_2d49dc39e5110a714d2836779fb915f7']('end', (getObjectProperty(session, 'actualDateStrings.start.date')), (getObjectProperty(session, 'actualDateStrings.start.time')), ___arguments['value'], (getObjectProperty(session, 'actualBreak')), (getObjectProperty(session, 'actualDuration'))));
  Object.assign(session, ({ [`actualEnd`]: (getObjectProperty(newDates, 'end')),[`actualDuration`]: (getObjectProperty(newDates, 'duration')) }));
  Object.assign((getObjectProperty(session, 'actualDateStrings.end')), (getObjectProperty(newDates, 'endString')));

  },
  /* handler:onComplete */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalTime, dateStringObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dateStringObj = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.end'));
  if ((getObjectProperty(dateStringObj, 'partial')).indexOf('_') !== -1) {
    originalTime = (getObjectProperty(dateStringObj, 'time'));
    dateStringObj['time'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    dateStringObj['time'] = originalTime;
  }

  },
  /* handler:onLostFocusEvent */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.end.partial', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* content */
}))

define('./pages/timesheets-old2/components/8f115e06a7e77abc78dc8b7512cd1eb5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8')['actualDuration'] = (await BackendlessUI.Functions.Custom['fn_b74c8eb6ac2467ea66e7b09125c4b966']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualStart')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualEnd')), ___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets-old2/components/9bdc34f64dcac64d9565e029ca49767b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.start.date')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.end.date')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/17f8ba3569fa5571a5185be4504c77fd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, timesheet, sessions, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function prune(sessions) {
  return await Promise.all(sessions.map(async item => {; return ({ [`objectId`]: (getObjectProperty(item, 'objectId')),[`actualStart`]: (getObjectProperty(item, 'actualStart')),[`actualEnd`]: (getObjectProperty(item, 'actualEnd')),[`actualBreak`]: (getObjectProperty(item, 'actualBreak')) ? (getObjectProperty(item, 'actualBreak')) : 0,[`rateClient`]: (getObjectProperty(item, 'rateClient')),[`extraClient`]: (getObjectProperty(item, 'extraClient')) ? (getObjectProperty(item, 'extraClient')) : 0,[`rateLocum`]: (getObjectProperty(item, 'rateLocum')),[`extraLocum`]: (getObjectProperty(item, 'extraLocum')) ? (getObjectProperty(item, 'extraLocum')) : 0,[`notesSession`]: (getObjectProperty(item, 'notesSession')) });}))
}


  try {
    timesheet = (getObjectProperty(___arguments.context.pageData, 'selected'));
    timesheet['status'] = 'APPROVED';
    sessions = await prune((getObjectProperty(___arguments.context.pageData, 'selected.sessions')));
    await Backendless.Request.post(`${Backendless.appPath}/services/SESSIONS/v1/sessions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'sessions': sessions,'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) });
    await Backendless.Request.post(`${Backendless.appPath}/services/TIMESHEETS/addTimesheets`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'timesheets': [timesheet] });
    delete ___arguments.context.pageData['selected'];
    delete ___arguments.context.pageData['sessions'];
    await ( async function (componentUid, step) {  ___arguments.context.getComponentByUid(componentUid).setStep(step) })('1c2e5813027687cfd24a6fdcdc5fed8c', 1);
    (getObjectProperty(___arguments.context.pageData, 'ui'))['submitStep'] = 1;
    (function (message) { alert(message) })('Timesheet Submitted 🎉🎉');

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old2/components/c4e17baf38de480d8dc5b5b2e78cefb5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['ui_view'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets-old2/components/046410ed5ebcc905fd62bebc0331faa1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui_view')) == 'submit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/51f1efdaf1f74bc7f3d13f1bbb195cf6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'actualStart')))), 'shortDateString'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/08c4e86d1ae45ecd769fb5852a6202fa/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'actualStart')))), 'timeString')),'-',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'actualEnd')))), 'timeString'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/7987449166f46b62c1d93f2f6402cade/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'actualBreak')) + String('m'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/eec2e4f80c5ca7022acebc452ea86916/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'costClient')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old2/components/10a9b0547058aab6a6e6b0b6fa6e3d18/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'costLocum')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/43d48605def42be22a61c9cd3f88fba8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selected.objectId')) {
    return 'Selected Timesheet';
  } else {
    return 'New Timesheet';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/c0986471de2f71bd1d9cf007d051d782/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_e091c5a99d6cb561c1c5d926db56846f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'file')), 'timesheets');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old3/components/2dd8f76598a2c1dfcada4845cf9ba688/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'sessions'))))) + String(' session(s) in this timesheet'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/c08f7acb4773855f0857b7f81a1cc142/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  try {
    await Backendless.Data.of('timesheets').remove( (getObjectProperty(___arguments.context.pageData, 'selected.objectId')) );
    if (getObjectProperty(___arguments.context.pageData, 'selected.file')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.pageData, 'selected.file'))));
    }
    delete ___arguments.context.pageData['selected'];
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('c08f7acb4773855f0857b7f81a1cc142');
    await BackendlessUI.Functions.Custom['fn_e73160caa2fefdbdf569314b42137f4c']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')), ___arguments.context.pageData, null, null);

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/timesheets-old3/components/dff8d4198bb35de5d758f0c334f32e06/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('c08f7acb4773855f0857b7f81a1cc142');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old3/components/6ce81b6b788b70338665bb4eada6ab41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['selected'] = ({ [`client`]: ({ [`objectId`]: undefined }),[`locum`]: ({ [`objectId`]: undefined }) });
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.editable', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old3/components/6daa498377f7cb4c919aaae7a4c780b4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selected;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'objectId'))) {
    selected = (await Backendless.Request.post(`${Backendless.appPath}/services/TIMESHEETS/addTimesheets`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'timesheets': [___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472')] }));
    ___arguments.context.pageData['selected'] = (selected[0]);
  }
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goNextStep() })('1c2e5813027687cfd24a6fdcdc5fed8c');
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.submitStep', (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentStep() })('1c2e5813027687cfd24a6fdcdc5fed8c')));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'client.objectId')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'locum.objectId')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'locum.objectId')) != 'DELETE'))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'objectId')) {
    return 'Amend';
  } else {
    return 'Next';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/49689a5c29947e825e849d9532d9fee9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) == 1 || !(getObjectProperty(___arguments.context.pageData, 'ui.submitStep')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/2f9941edad25d36afbe2ca6119b9a268/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) == 2)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/084da18cdd9c477279b678dc8171f48f/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var selected;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selected = (getObjectProperty(___arguments.context.pageData, 'selected'));
  Object.assign(selected, ({ [`file`]: ___arguments.uploadedFiles,[`filename`]: (await BackendlessUI.Functions.Custom['fn_b78404622ac4324879cd1a77d399f5ef'](___arguments.uploadedFiles, 'timesheets')) }));
  await Backendless.Data.of('timesheets').save(({ [`objectId`]: (getObjectProperty(selected, 'objectId')),[`file`]: (getObjectProperty(selected, 'file')),[`filename`]: (getObjectProperty(selected, 'filename')) }), true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('084da18cdd9c477279b678dc8171f48f');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      (function (message) { alert(message) })('Upload Failed. Try again or contact support.');
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('084da18cdd9c477279b678dc8171f48f');

  },
  /* handler:onUploadFail */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'selected.file')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(generateUUID().short()) + String(getObjectProperty(___arguments.file, 'name')))

  },
  /* handler:onFileNameAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'selected')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/852259f594c3039d0971bb9d25170a89/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_e091c5a99d6cb561c1c5d926db56846f']((getObjectProperty(___arguments.context.pageData, 'selected.file')), 'timesheets');

  },
  /* handler:onClick */
  /* handler:onDelete */
  async ['onDelete'](___arguments) {
    var selected;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  selected = (getObjectProperty(___arguments.context.pageData, 'selected'));
  await Backendless.Files.remove(encodePath((getObjectProperty(selected, 'file'))));
  delete selected['file'];
  delete selected['filename'];
  await Backendless.Data.of('timesheets').save(({ [`objectId`]: (getObjectProperty(selected, 'objectId')),[`file`]: null,[`filename`]: null }), true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('084da18cdd9c477279b678dc8171f48f');

  },
  /* handler:onDelete */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var fileUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  fileUrl = (getObjectProperty(___arguments.context.pageData, 'selected.file'));
  if (fileUrl) {
    return (await BackendlessUI.Functions.Custom['fn_b78404622ac4324879cd1a77d399f5ef'](fileUrl, 'timesheets'));
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/ccd6ae32a176347d46eb0e871de87b15/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var alreadySelectedSessions, item, session, sessions, timesheet;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goNextStep() })('1c2e5813027687cfd24a6fdcdc5fed8c');
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.submitStep', (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentStep() })('1c2e5813027687cfd24a6fdcdc5fed8c')));
  console.log('LOAD AVAILABLE SESSIONS');
  timesheet = (getObjectProperty(___arguments.context.pageData, 'selected'));
  sessions = (await BackendlessUI.Functions.Custom['fn_95c944c98e485f86d92494227ab43547']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')), (getObjectProperty(timesheet, 'locum.objectId')), (getObjectProperty(timesheet, 'client.objectId'))));
  console.log(sessions);
  alreadySelectedSessions = (getObjectProperty(timesheet, 'sessions'));
  if (alreadySelectedSessions) {
    console.log('ADD SESSIONS ALREADY IN TIMESHEET');
    for (var session_index in alreadySelectedSessions) {
      session = alreadySelectedSessions[session_index];
      session['checked'] = true;
    }
    addItemToList(sessions, alreadySelectedSessions);
  }
  sessions = (await asyncListSort(sessions, 1, async (item) => {


   return (getObjectProperty(item, 'start'));
  }));
  console.log('ENRICH SESSION DATA');
  for (var session_index2 in sessions) {
    session = sessions[session_index2];
    await BackendlessUI.Functions.Custom['fn_8564e643897ab40ff5d31e1473139bce'](session);
  }
  console.log('SET PD');
  ___arguments.context.pageData['sessions'] = sessions;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old3/components/c20b789583adca3e02c286a412ea4c4d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goPrevStep() })('1c2e5813027687cfd24a6fdcdc5fed8c');
  await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'ui.submitStep', (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentStep() })('1c2e5813027687cfd24a6fdcdc5fed8c')));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) && (getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) != 1)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/4fb35df77128c005333be7841c43d881/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var sessions, session, today;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) == 3)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/e2d9703b68d766da388aa42a853b87da/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var checked;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'sessions')) {
    checked = ((getObjectProperty(___arguments.context.pageData, 'sessions')).map(item => item['checked']));
    return (await (async function(arr) {
    	return arr.reduce((count, value) => value === true ? count + 1 : count, 0);
    })(checked));
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var processor, processorCount, item, selectedSessions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}


  selectedSessions = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'sessions')), async (item) => {


   return ((getObjectProperty(item, 'checked')) == true);
  }));
  Object.assign((getObjectProperty(___arguments.context.pageData, 'selected')), ({ [`sessions`]: (await BackendlessUI.Functions.Custom['fn_8b9f564fdda88d8b74ab06d3e55b7ae4']((await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](selectedSessions)))) }));
  processorCount = (await BackendlessUI.Functions.Custom['fn_465a168c4dbaac59e91987824a13cce3'](selectedSessions, 'processor'));
  if (processorCount == 1) {
    processor = (getObjectProperty((selectedSessions[0]), 'processor'));
  } else {
    processor = (getObjectProperty((await asyncListFind((getObjectProperty(___arguments.context.appData, 'clients.all')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) == (getObjectProperty(___arguments.context.pageData, 'selected.client.objectId')));
    })), 'defaultProcessor'));
    if (processorCount > 1) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.pageData, 'selected.___ui.processorWarning', true);
    } else {
    }
  }
  (getObjectProperty(___arguments.context.pageData, 'selected'))['processor'] = processor;
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goNextStep() })('1c2e5813027687cfd24a6fdcdc5fed8c');
  (getObjectProperty(___arguments.context.pageData, 'ui'))['submitStep'] = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentStep() })('1c2e5813027687cfd24a6fdcdc5fed8c'));

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await BackendlessUI.Functions.Custom['fn_8d4b531b860a4ba62584e6712dfb87eb']((getObjectProperty(___arguments.context.pageData, 'sessions'))))) {
    return (['Link ',(await (async function(arr) {
    	    if (!Array.isArray(arr)) return 0;
    	    return arr.reduce((count, value) => value === true ? count + 1 : count, 0);
    })(((getObjectProperty(___arguments.context.pageData, 'sessions')).map(item => item['checked'])))),' Sessions with Timesheet'].join(''));
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/80bc60d40b47f799910ccf57923efe1f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui.submitStep')) == 4)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/b86aaeede34617057778ff21ff8a9e95/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Select sessions to include (',(await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.pageData, 'selected.sessions')))),' sessions linked)'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/042efe5d1e299095fb5f28b1906db8b5/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_2d66f332efdfa294dacc1adcc07afcb4']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDuration'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/c2c90621e233006188c6967ef4b7c21c/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    var dateStringObj, newDates, session;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  session = ___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8');
  if (___arguments['value'] == (getObjectProperty(session, 'actualDateStrings.start.date'))) {
    return ;
  }
  newDates = (await BackendlessUI.Functions.Custom['fn_2d49dc39e5110a714d2836779fb915f7']('start', ___arguments['value'], (getObjectProperty(session, 'actualDateStrings.start.time')), (getObjectProperty(session, 'actualDateStrings.end.time')), (getObjectProperty(session, 'actualBreak')), (getObjectProperty(session, 'actualDuration'))));
  Object.assign(session, ({ [`actualStart`]: (getObjectProperty(newDates, 'start')),[`actualEnd`]: (getObjectProperty(newDates, 'end')),[`actualDuration`]: (getObjectProperty(newDates, 'duration')) }));
  Object.assign((getObjectProperty(session, 'actualDateStrings.start')), (getObjectProperty(newDates, 'startString')));
  Object.assign((getObjectProperty(session, 'actualDateStrings.end')), (getObjectProperty(newDates, 'endString')));

  },
  /* handler:onComplete */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.start.partialDate', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalDate, dateStringObj, partialDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dateStringObj = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.start'));
  partialDate = (getObjectProperty(dateStringObj, 'partialDate'));
  if ((partialDate.indexOf('d') !== -1) || (partialDate.indexOf('m') !== -1) || (partialDate.indexOf('y') !== -1)) {
    console.log('invalid date detected');
    originalDate = (getObjectProperty(dateStringObj, 'date'));
    dateStringObj['date'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    dateStringObj['date'] = originalDate;
  }

  },
  /* handler:onLostFocusEvent */
  /* content */
}))

define('./pages/timesheets-old3/components/d4ae51ebc7d76e3e1cfd2dfe7bfb5488/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    var newDates, session;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  session = ___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8');
  if (___arguments['value'] == (getObjectProperty(session, 'actualDateStrings.start.time'))) {
    return ;
  }
  newDates = (await BackendlessUI.Functions.Custom['fn_2d49dc39e5110a714d2836779fb915f7']('start', (getObjectProperty(session, 'actualDateStrings.start.date')), ___arguments['value'], (getObjectProperty(session, 'actualDateStrings.end.time')), (getObjectProperty(session, 'actualBreak')), (getObjectProperty(session, 'actualDuration'))));
  Object.assign(session, ({ [`actualStart`]: (getObjectProperty(newDates, 'start')),[`actualEnd`]: (getObjectProperty(newDates, 'end')),[`actualDuration`]: (getObjectProperty(newDates, 'duration')) }));
  Object.assign((getObjectProperty(session, 'actualDateStrings.start')), (getObjectProperty(newDates, 'startString')));
  Object.assign((getObjectProperty(session, 'actualDateStrings.end')), (getObjectProperty(newDates, 'endString')));

  },
  /* handler:onComplete */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.start.partial', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalTime, dateStringObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dateStringObj = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.start'));
  if ((getObjectProperty(dateStringObj, 'partial')).indexOf('_') !== -1) {
    originalTime = (getObjectProperty(dateStringObj, 'time'));
    dateStringObj['time'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    dateStringObj['time'] = originalTime;
  }

  },
  /* handler:onLostFocusEvent */
  /* content */
}))

define('./pages/timesheets-old3/components/52452e3a6202298ff88153a4e4745d2f/bundle.js', [], () => ({
  /* content */
  /* handler:onComplete */
  async ['onComplete'](___arguments) {
    var newDates, session;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  session = ___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8');
  if (___arguments['value'] == (getObjectProperty(session, 'actualDateStrings.end.time'))) {
    return ;
  }
  newDates = (await BackendlessUI.Functions.Custom['fn_2d49dc39e5110a714d2836779fb915f7']('end', (getObjectProperty(session, 'actualDateStrings.start.date')), (getObjectProperty(session, 'actualDateStrings.start.time')), ___arguments['value'], (getObjectProperty(session, 'actualBreak')), (getObjectProperty(session, 'actualDuration'))));
  Object.assign(session, ({ [`actualEnd`]: (getObjectProperty(newDates, 'end')),[`actualDuration`]: (getObjectProperty(newDates, 'duration')) }));
  Object.assign((getObjectProperty(session, 'actualDateStrings.end')), (getObjectProperty(newDates, 'endString')));

  },
  /* handler:onComplete */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var originalTime, dateStringObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dateStringObj = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.end'));
  if ((getObjectProperty(dateStringObj, 'partial')).indexOf('_') !== -1) {
    originalTime = (getObjectProperty(dateStringObj, 'time'));
    dateStringObj['time'] = '';
    await new Promise(r => setTimeout(r, 100 || 0));
    dateStringObj['time'] = originalTime;
  }

  },
  /* handler:onLostFocusEvent */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.end.partial', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* content */
}))

define('./pages/timesheets-old3/components/8f115e06a7e77abc78dc8b7512cd1eb5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8')['actualDuration'] = (await BackendlessUI.Functions.Custom['fn_b74c8eb6ac2467ea66e7b09125c4b966']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualStart')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualEnd')), ___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets-old3/components/9bdc34f64dcac64d9565e029ca49767b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.start.date')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9d277998a59fcd5f66817e0d61917ff8'), 'actualDateStrings.end.date')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/17f8ba3569fa5571a5185be4504c77fd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, sessions, error, timesheet, margin, session, duration, processor;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function prune(sessions) {
  return await Promise.all(sessions.map(async item => {; return ({ [`objectId`]: (getObjectProperty(item, 'objectId')),[`actualStart`]: (getObjectProperty(item, 'actualStart')),[`actualEnd`]: (getObjectProperty(item, 'actualEnd')),[`actualBreak`]: (getObjectProperty(item, 'actualBreak')) ? (getObjectProperty(item, 'actualBreak')) : 0,[`rateClient`]: (getObjectProperty(item, 'rateClient')),[`extraClient`]: (getObjectProperty(item, 'extraClient')) ? (getObjectProperty(item, 'extraClient')) : 0,[`rateLocum`]: (getObjectProperty(item, 'rateLocum')),[`extraLocum`]: (getObjectProperty(item, 'extraLocum')) ? (getObjectProperty(item, 'extraLocum')) : 0,[`notesSession`]: (getObjectProperty(item, 'notesSession')) });}))
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}

/**
 * Describe this function...
 */
async function calculateActualMargin(sessions, processor) {
  processor = (await asyncListFind((getObjectProperty(___arguments.context.appData, 'processors')), async (item) => {


   return ((getObjectProperty(item, 'processor')) == processor);
  }));
  if (!processor) {
    (function (message) { alert(message) })('CRITICAL ERROR : PROCESSOR NOT FOUND! CANNOT PROCEED.');
    throw (new Error('CRITICAL ERROR : PROCESSOR NOT FOUND!'))
  }
  for (var session_index in sessions) {
    session = sessions[session_index];
    duration = (await BackendlessUI.Functions.Custom['fn_b74c8eb6ac2467ea66e7b09125c4b966']((getObjectProperty(session, 'actualStart')), (getObjectProperty(session, 'actualEnd')), (getObjectProperty(session, 'actualBreak'))));
    margin = (await (async function(session, processorRate, vatRate, actualDuration) {
    	function roundMoney(num) {
    	  return Math.round(num * 100)/100;
    	}

    	let duration = actualDuration / 60;
    	// console.log('Duration:', duration);

    	// Check if any critical variable is null or undefined, return 0
    	if (duration == null || session.rateClient == null || session.rateLocum == null || processorRate == null || vatRate == null) {
    	  // console.log('One or more critical variables are null or undefined.');
    	  return 0;
    	}

    	// Default to 0 if extraClient or extraLocum are null or undefined
    	let extraClient = session.extraClient ?? 0;
    	let extraLocum = session.extraLocum ?? 0;
    	// console.log('Extra Client:', extraClient);
    	// console.log('Extra Locum:', extraLocum);

    	let costClient = roundMoney((session.rateClient * duration) + extraClient);
    	let costLocum = roundMoney((session.rateLocum * duration) + extraLocum);
    	let costAgency = roundMoney(costClient - costLocum);
    	let processorFee = roundMoney(costClient * processorRate);
    	let vat = roundMoney(costAgency / (1 + (1 / vatRate)));

    	// console.log('Rate Client:', session.rateClient);
    	// console.log('Rate Locum:', session.rateLocum);
    	// console.log('Processor Rate:', processorRate);
    	// console.log('VAT Rate:', vatRate);
    	// console.log('Cost Client:', costClient);
    	// console.log('Cost Locum:', costLocum);
    	// console.log('Cost Agency:', costAgency);
    	// console.log('Processor Fee:', processorFee);
    	// console.log('VAT:', vat);

    	let margin = roundMoney(costAgency - processorFee - vat);
    	let hourlyMargin = roundMoney(margin/duration);
    	// console.log('Result:', result);

    	return {
    	  'margin': margin,
    	  'hourlyMargin': hourlyMargin,
    	  'processorFee': processorFee,
    	  'vat': vat
    	};
    })(session, (getObjectProperty(processor, 'fee')), (getObjectProperty(___arguments.context.appData, 'vat')), duration));
    Object.assign(session, ({ [`processor`]: (getObjectProperty(processor, 'processor')),[`actualProcessorFee`]: (getObjectProperty(margin, 'processorFee')),[`actualVat`]: (getObjectProperty(margin, 'vat')) }));
  }
  return sessions
}


  try {
    timesheet = (getObjectProperty(___arguments.context.pageData, 'selected'));
    timesheet['status'] = 'APPROVED';
    sessions = await prune((getObjectProperty(___arguments.context.pageData, 'selected.sessions')));
    sessions = await calculateActualMargin(sessions, (getObjectProperty(___arguments.context.pageData, 'selected.processor')));
    await Backendless.Request.post(`${Backendless.appPath}/services/SESSIONS/v1/sessions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'sessions': sessions,'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')) });
    await Backendless.Request.post(`${Backendless.appPath}/services/TIMESHEETS/addTimesheets`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'orgId': (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')),'timesheets': [timesheet] });
    delete ___arguments.context.pageData['selected'];
    delete ___arguments.context.pageData['sessions'];
    await ( async function (componentUid, step) {  ___arguments.context.getComponentByUid(componentUid).setStep(step) })('1c2e5813027687cfd24a6fdcdc5fed8c', 1);
    (getObjectProperty(___arguments.context.pageData, 'ui'))['submitStep'] = 1;
    (function (message) { alert(message) })('Timesheet Submitted 🎉🎉');

  } catch (error) {
    (function (message) { alert(message) })(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old3/components/c4e17baf38de480d8dc5b5b2e78cefb5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['ui_view'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets-old3/components/046410ed5ebcc905fd62bebc0331faa1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui_view')) == 'submit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/51f1efdaf1f74bc7f3d13f1bbb195cf6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'actualStart')))), 'shortDateString'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/08c4e86d1ae45ecd769fb5852a6202fa/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var actualEnd, actualStart;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  actualStart = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'actualStart'));
  actualEnd = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'actualEnd'));

  return (actualStart && actualEnd ? [(getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](actualStart)), 'timeString')),'-',(getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](actualEnd)), 'timeString'))].join('') : 'missing actual start & end')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/7987449166f46b62c1d93f2f6402cade/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'actualBreak')) + String('m'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/eec2e4f80c5ca7022acebc452ea86916/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'actualCostClient')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/10a9b0547058aab6a6e6b0b6fa6e3d18/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('£') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1644f655612384ccaec718a4e3b41886'), 'actualCostLocum')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/d04e1d0dfece8aa6207851a512a294eb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['ui_filter'] = (!(getObjectProperty(___arguments.context.pageData, 'ui_filter')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old3/components/79c6d8e1202c5357094ae852adb89f98/bundle.js', [], () => ({
  /* content */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'clients.all'))

  },
  /* handler:optionsLogic */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['ui_clientFilter'] = ___arguments['inputValue'];

  },
  /* handler:onChange */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      delete ___arguments.context.pageData['ui_clientFilter'];

  },
  /* handler:onButtonClearClick */
  /* content */
}))

define('./pages/timesheets-old3/components/69d4afc968a23866fef5f3bee17a5adb/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['ui_locumFilter'] = ___arguments['inputValue'];

  },
  /* handler:onChange */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'locums.all'))

  },
  /* handler:optionsLogic */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      delete ___arguments.context.pageData['ui_locumFilter'];

  },
  /* handler:onButtonClearClick */
  /* content */
}))

define('./pages/timesheets-old3/components/312d5335a48692e2ad9172b7ee6a5cf6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['ui_statusFilter'] = (___arguments['selectValue'].map(item => item['value']));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets-old3/components/f391198ecd13c7b8e1f16ebc5d2ea805/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_e73160caa2fefdbdf569314b42137f4c']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')), ___arguments.context.pageData, null, null);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old3/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
      ___arguments.context.pageData['ui_statusFilterInitial'] = ['DRAFT', 'APPROVED'];
  ___arguments.context.pageData['ui_statusFilter'] = ['DRAFT', 'APPROVED'];

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/timesheets-old3/components/812a4b2d26f672a39635c2f3c5433cc4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_e73160caa2fefdbdf569314b42137f4c']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')), ___arguments.context.pageData, null, null);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old3/components/3e9fe536ec46603aca784dd6f732ad82/bundle.js', [], () => ({
  /* content */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_e73160caa2fefdbdf569314b42137f4c']((getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')), ___arguments.context.pageData, (___arguments['currentPage'] - 1), null);

  },
  /* handler:onPageChange */
  /* handler:onGoNext */
  async ['onGoNext'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goNextPage() })('3e9fe536ec46603aca784dd6f732ad82');

  },
  /* handler:onGoNext */
  /* handler:onGoBack */
  async ['onGoBack'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goPreviousPage() })('3e9fe536ec46603aca784dd6f732ad82');

  },
  /* handler:onGoBack */
  /* handler:onGoFirst */
  async ['onGoFirst'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goFirstPage() })('3e9fe536ec46603aca784dd6f732ad82');

  },
  /* handler:onGoFirst */
  /* handler:onGoLast */
  async ['onGoLast'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goLastPage() })('3e9fe536ec46603aca784dd6f732ad82');

  },
  /* handler:onGoLast */
  /* content */
}))

define('./pages/timesheets-old3/components/f0b5bc64d06b4ef664117817124d555c/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFind(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  return list.find(item => item.value)?.source || null
}


  ___arguments.context.pageData['selected'] = (await asyncListFind((getObjectProperty(___arguments.context.pageData, 'timesheets')), async (item) => {


   return ((getObjectProperty(item, 'objectId')) == (getObjectProperty(___arguments['cellParams'], 'data.timesheetId')));
  }));

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/timesheets-old3/components/dfbe53ce9a08bcd5566fa39cca721278/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('034773f3781fdac667a455952f87d915'), 'break')) + String('m'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/8821d87808c1a7e5d7820698c7e3d3f6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_2d66f332efdfa294dacc1adcc07afcb4']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('034773f3781fdac667a455952f87d915'), 'duration'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/034773f3781fdac667a455952f87d915/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedSessions, sessions, selectedIds, selectedRows;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['checked'] = (!(getObjectProperty(___arguments.context.dataModel, 'checked')));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var class2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  class2 = ['row-item'];
  if (getObjectProperty(___arguments.context.dataModel, 'checked')) {
    addItemToList(class2, 'selected');
  }

  return class2

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/75f788033646d8e93b3411c6c6816871/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'ui_view')) == 'outstanding')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/5b7c94c8300eee7d810f09372d7e916d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'status')) == 'FACTORED')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/timesheets-old3/components/6dec81b868894fc304b529d958430fee/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}


  await Backendless.Data.of('timesheets').save(({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'objectId')),[`status`]: ___arguments.value }), true);
  replaceItemInList((getObjectProperty(___arguments.context.pageData, 'timesheets')), ___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'objectId');
  ___arguments.context.pageData['timesheets'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments.context.pageData, 'timesheets'))));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0111d20b4da97c11a4957873877ac5f6', true);
  await new Promise(r => setTimeout(r, 5000 || 0));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0111d20b4da97c11a4957873877ac5f6', false);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/timesheets-old3/components/63297847261173d3e3fa6de13f9e1b0b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472')['ui_enableStatusChange'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/timesheets-old3/components/464ae8185fa3e0e38a231d1cac2cf309/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(await BackendlessUI.Functions.Custom['fn_8d4b531b860a4ba62584e6712dfb87eb']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d4d76d7b5affbc5694cac4240c595472'), 'sessions')))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tsheet/components/bb05a5ed8bf989ba73a24fa732d82d88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      console.log((await BackendlessUI.Functions.Custom['fn_363dd2f66c6285b9d669ca1a2b3620fb'](___arguments.context.appData, ___arguments.context.pageData)));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tsheet/components/52141663b992af1b95b9905125ceb057/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    var locum;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  locum = (getObjectProperty(___arguments['cellParams'], 'data'));
  ___arguments.context.pageData['selectedLocum'] = ({ [`objectId`]: (getObjectProperty(locum, 'locumId')),[`billingEmail`]: (getObjectProperty(locum, 'locumEmail')),[`name`]: (getObjectProperty(locum, 'locum')) });
  await BackendlessUI.Functions.Custom['fn_bff8b1efd3a8cf98c72677aed7d13e48'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onCellClick */
  /* content */
}))

define('./pages/tsheet/components/42ebd56a32b7b69ad63974f457e7e112/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  async ['onCellClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('42ebd56a32b7b69ad63974f457e7e112')));
  (getObjectProperty(___arguments.context.pageData, 'selectedLocum'))['selectedSessions'] = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getSelectedRows() })('42ebd56a32b7b69ad63974f457e7e112'));
  await BackendlessUI.Functions.Custom['fn_31fb469a79e9912288153bfa95861b3b'](___arguments.context.pageData, ___arguments.context.appData);
  await ( async function (componentUid, html) {  ___arguments.context.getComponentByUid(componentUid).setHtml(html) })('af0b1b3cea59fe844d9e0df3b41ae9ac', (getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.body')));

  },
  /* handler:onCellClick */
  /* handler:rowsDataLogic */
  ['rowsDataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedLocum.outstandingSessions'))

  },
  /* handler:rowsDataLogic */
  /* content */
}))

define('./pages/tsheet/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_363dd2f66c6285b9d669ca1a2b3620fb'](___arguments.context.appData, ___arguments.context.pageData);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/tsheet/components/d7e7889a8bf3666abeafe161566c212d/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      await ( async function (componentUid, html) {  ___arguments.context.getComponentByUid(componentUid).setHtml(html) })('af0b1b3cea59fe844d9e0df3b41ae9ac', (await BackendlessUI.Functions.Custom['fn_31fb469a79e9912288153bfa95861b3b'](___arguments.context.pageData, ___arguments.context.appData)));

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/tsheet/components/0c27b75be45738b1dfaf199a2168bd1a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var recipients;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function setRecipients() {
  recipients = [(getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.to'))];
  if (getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.cc')) {
    addItemToList(recipients, (getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.cc')));
  }
  return recipients
}


  await Backendless.Messaging.sendEmail((String('Outstanding Timesheets from ') + String(getObjectProperty(___arguments.context.appData, 'currentOrg.orgName'))), new Backendless.Bodyparts({ htmlmessage: (getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.body')) }), ensureArray((await setRecipients())), undefined);
  (function (message) { alert(message) })((String('Email sent! 🎉🎉 - A copy is sent to ') + String(getObjectProperty(___arguments.context.pageData, 'selectedLocum.email.cc'))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tsheet/components/a232fe5d392f367da48876ef88d9912a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('f5ddd5ec161c503a465de329e0d8e743')) == 'tab_chase')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tsheet/components/1a9c3d3f9e152f31d7cfc60a301b80a5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('f5ddd5ec161c503a465de329e0d8e743')) == 'tab_timesheet')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tsheet/components/320b1511efa91c3bbf3cde77c5535f8c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var timesheets, timesheet, locumId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  locumId = (getObjectProperty(___arguments.context.pageData, 'selectedLocum.objectId'));
  timesheets = (await Backendless.Data.of('timesheets').find(Backendless.DataQueryBuilder.create().setWhereClause((['locum.objectId = \'',locumId,'\''].join(''))).setRelationsDepth(1).setSortBy('status').setPageSize(5)));
  for (var timesheet_index in timesheets) {
    timesheet = timesheets[timesheet_index];
    Object.assign(timesheet, ({ [`clientName`]: (getObjectProperty(timesheet, 'client.orgName')) }));
  }
  (getObjectProperty(___arguments.context.pageData, 'selectedLocum'))['timesheets'] = timesheets;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tsheet/components/0f920fd237e2569c8d8fd117306ee272/bundle.js', [], () => ({
  /* content */
  /* handler:rowsDataLogic */
  ['rowsDataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedLocum.timesheets'))

  },
  /* handler:rowsDataLogic */
  /* handler:onCellClick */
  ['onCellClick'](___arguments) {
      console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0f920fd237e2569c8d8fd117306ee272'));

  },
  /* handler:onCellClick */
  /* content */
}))

define('./layouts/Standard/components/b4f1ddf28e448051755fe4a69d565fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log('Page Data:');
  console.log(___arguments.context.pageData);
  console.log('App Data:');
  console.log(___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./layouts/Standard/components/ec6a431a4995cbf56ee18c7a3f29378a/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
    var initials;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await (async function(name) {
	  if (!name) {
	    return null;
	  }

	  const words = name.trim().split(' ');
	  if (words.length < 2) {
	    throw new Error("Name must contain at least two words");
	  }

	  const firstInitial = words[0].charAt(0).toUpperCase();
	  const lastInitial = words[words.length - 1].charAt(0).toUpperCase();
	  return firstInitial + lastInitial;
})((getObjectProperty(___arguments.context.appData, 'currentUser.name'))))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./layouts/Standard/components/root/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function directToLoginPage() {
  if (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).slice(0, 6) != 'public') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('publicLogin', undefined);
  }
}


  console.log('\\/ ======== [Standard Layout] ======== \\/');
  if (Backendless.getCurrentUserToken()) {
    console.log('User Logged In');
    if (!(getObjectProperty(___arguments.context.appData, 'currentUser'))) {
      console.log('---> No App Data present, redirecting to loading');
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loading', ({ [`redirect`]: ((function () { return BackendlessUI.Navigator.getCurrentPageName() })()) }));
    }
    console.log('---> App Data (current page)');
    console.log(___arguments.context.appData);
  } else {
    console.log('User Not Logged In');
    await directToLoginPage();
  }
  console.log('/\\ ======== [Standard Layout] ======== /\\');
  console.log('');

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./layouts/Standard/components/ee9df398a45760c681552d8c619f1cbf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('publicLogin', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./layouts/Standard/components/0a4d67e7a96bbd7a138af22bded28b2a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'currentOrg.orgName'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./layouts/Standard/components/6aa16463a0a2d3aa3f738d38a57ae569/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var role;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  role = (getObjectProperty(___arguments.context.appData, 'currentUser.roles'));
  if (role) {
    return (role.includes('admin'));
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/a01a6bdd905713250dd7cc88e43b208b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await new Promise(r => setTimeout(r, 100 || 0));

  return (String('<b>Duration: </b>') + String((await BackendlessUI.Functions.Custom['fn_2d66f332efdfa294dacc1adcc07afcb4']((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0213db6496ad2e04448e81942efbd125'), 'actualEnd')) - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0213db6496ad2e04448e81942efbd125'), 'actualStart'))) / 60000 - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0213db6496ad2e04448e81942efbd125'), 'actualBreak')))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/7861a70af45da6c495d6092efb3ae8e8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('<b>Original Proc.: </b>') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('0213db6496ad2e04448e81942efbd125'), 'processor')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/73d6b13650ea6f07222baf8d64088569/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var durationHrs;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  durationHrs = (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0213db6496ad2e04448e81942efbd125'), 'actualEnd')) - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0213db6496ad2e04448e81942efbd125'), 'actualStart'))) / 60000 - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0213db6496ad2e04448e81942efbd125'), 'actualBreak'))) / 60;

  return (String('<b>Client Pays: </b> £') + String(Number.parseFloat(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0213db6496ad2e04448e81942efbd125'), 'rateClient')) * durationHrs + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0213db6496ad2e04448e81942efbd125'), 'extraClient')))).toFixed(2)))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/3bc26e2cbb1e873473474d6f133992e7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'ui.processorWarning'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/a211dd8fe2abc9014ca5d05258014b69/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_e091c5a99d6cb561c1c5d926db56846f']((getObjectProperty(___arguments.context.rootComponentData, 'file')), 'timesheets');

  },
  /* handler:onClick */
  /* handler:onDelete */
  async ['onDelete'](___arguments) {
    var timesheet;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  timesheet = ___arguments.context.rootComponentData;
  await Backendless.Files.remove(encodePath((getObjectProperty(timesheet, 'file'))));
  delete timesheet['file'];
  delete timesheet['filename'];
  await Backendless.Data.of('timesheets').save(({ [`objectId`]: (getObjectProperty(timesheet, 'objectId')),[`file`]: null,[`filename`]: null }), true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('30823c06981ff833d3d6aa461219ad6c');

  },
  /* handler:onDelete */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var fileUrl;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  fileUrl = (getObjectProperty(___arguments.context.rootComponentData, 'file'));
  if (fileUrl) {
    return (await BackendlessUI.Functions.Custom['fn_b78404622ac4324879cd1a77d399f5ef'](fileUrl, 'timesheets'));
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onDeleteIconAssignment */
  ['onDeleteIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.rootComponentData, 'readOnly')) {
    return 'none';
  } else {
    return 'delete';
  }

  },
  /* handler:onDeleteIconAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/30823c06981ff833d3d6aa461219ad6c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((getObjectProperty(___arguments.context.rootComponentData, 'file')) || (getObjectProperty(___arguments.context.rootComponentData, 'readOnly'))))

  },
  /* handler:onDisplayAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var timesheet;


  timesheet = ___arguments.context.rootComponentData;
  Object.assign(timesheet, ({ [`file`]: ___arguments.uploadedFiles,[`filename`]: (await BackendlessUI.Functions.Custom['fn_b78404622ac4324879cd1a77d399f5ef'](___arguments.uploadedFiles, 'timesheets')) }));
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('30823c06981ff833d3d6aa461219ad6c');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      (function (message) { alert(message) })('Upload Failed. Try again or contact support.');
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('30823c06981ff833d3d6aa461219ad6c');

  },
  /* handler:onUploadFail */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(generateUUID().short()) + String(getObjectProperty(___arguments.file, 'name')))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/12c13bc8d278b56e484af9f3af632571/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String((await BackendlessUI.Functions.Custom['fn_4fec03ae65739f42bf2fd185b3dadf9f']((getObjectProperty(___arguments.context.rootComponentData, 'sessions'))))) + String(' Sessions'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/b1355539ad0559ca8025e2341b4df97a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var duration, endString, newEnd, startString;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  duration = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'duration')) + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'break'))) * 60000;
  if (___arguments.value === null) {
    ___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44')['actualStart'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'actualEnd')) - duration);
    return ;
  }
  newEnd = ___arguments.value + duration;
  startString = (await BackendlessUI.Functions.Custom['fn_8475b3f34a83206b9e192b82530e7d2b'](___arguments.value));
  endString = (await BackendlessUI.Functions.Custom['fn_8475b3f34a83206b9e192b82530e7d2b'](newEnd));
  Object.assign(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), ({ [`actualEnd`]: newEnd,[`actualStartString`]: startString,[`actualEndString`]: endString }));

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/3f26bb8a99f5134f0349d7e94c8dedf8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var duration;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value <= (getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'actualStart'))) {
    duration = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'duration')) + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'break'))) * 60000;
    ___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44')['actualEnd'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'actualStart')) + duration);
  } else {
    duration = (___arguments.value - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'actualStart'))) / 60000 - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'break'));
    Object.assign(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), ({ [`duration`]: duration,[`actualEndString`]: (await BackendlessUI.Functions.Custom['fn_8475b3f34a83206b9e192b82530e7d2b'](___arguments.value)) }));
  }

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/a9842cea190d154aa8193cbc83b615db/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'actualEnd')) - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44'), 'actualStart'))) / 60000 - ___arguments.value <= 0) {
    ___arguments.context.getComponentDataStoreByUid('999be06c95a5aab7bbd4a031ae719e44')['actualBreak'] = ___arguments.prevValue;
  }

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/1b7c7cddeb92ee229853fcb531f22074/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/d5212fb2dba47877008db3575ea98597/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/e6fc76dfb1842b3b02631df2543fc179/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/840bc94eebcc30551eb4916990111a7e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/1b2a473d2ca24fb55c2add8b24b2a489/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_398e76d156ca0a9131cbe3f294f6655c/logic/e053c79d8ed5202e833be03853e75a98/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.rootComponentData, 'readOnly'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_cecb6d567d290791fceb50b4e46ad341/logic/6c748f92c73e080dd8da5ec311a13772/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var filters, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filters = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8038646216e2dfef6edf6481f92c935'), 'in'));
  response = (await BackendlessUI.Functions.Custom['fn_3ed59821a80529e8b6475f3a89c3587d'](null, 'created', (getObjectProperty(___arguments.context.rootComponentData, 'in'))));
  console.log('%c>> 🔒 [9:57:21 PM]: ' + 'TIMESHEETS PICKER - FILTER LOADED', 'color: #ab47bc', JSON.parse(JSON.stringify(___arguments.context.rootComponentData)));
  Object.assign(___arguments.context.rootComponentData, response);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_cecb6d567d290791fceb50b4e46ad341/logic/root/bundle.js', [], () => ({
  /* content */
  /* handler:onAfterRender */
  async ['onAfterRender'](___arguments) {
    var response, filters;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filters = (getObjectProperty(___arguments.context.rootComponentData, 'in'));
  if (filters && !(getObjectProperty(___arguments.context.rootComponentData, 'timesheets'))) {
    response = (await BackendlessUI.Functions.Custom['fn_3ed59821a80529e8b6475f3a89c3587d'](null, 'created', filters));
    console.log('%c>> 🔒 [10:04:06 PM]: ' + 'TIMESHEETS PICKER INITIALISED', 'color: #ab47bc', JSON.parse(JSON.stringify(___arguments.context.rootComponentData)));
    Object.assign(___arguments.context.rootComponentData, response);
  }

  },
  /* handler:onAfterRender */
  /* handler:onBeforeUnmount */
  ['onBeforeUnmount'](___arguments) {
      ___arguments.context.rootComponentData['out'] = null;

  },
  /* handler:onBeforeUnmount */
  /* content */
}))

define('./components/reusable/r_cecb6d567d290791fceb50b4e46ad341/logic/897a9bbdb5314ea22ce93a8d21ac6c54/bundle.js', [], () => ({
  /* content */
  /* handler:onCellClick */
  ['onCellClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.rootComponentData['out'] = (getObjectProperty(___arguments['cellParams'], 'data'));

  },
  /* handler:onCellClick */
  /* content */
}))

define('./components/reusable/r_cecb6d567d290791fceb50b4e46ad341/logic/d3c9ef21c1a5a0176f21d00ee93be653/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('e8038646216e2dfef6edf6481f92c935')['status'] = (___arguments['selectValue'].map(item => item['value']));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_cecb6d567d290791fceb50b4e46ad341/logic/a195ad04f7ea2c88af066c36ae7c2e72/bundle.js', [], () => ({
  /* content */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'clients.all'))

  },
  /* handler:optionsLogic */
  /* handler:onButtonClearClick */
  ['onButtonClearClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('e8038646216e2dfef6edf6481f92c935')['clientId'] = null;

  },
  /* handler:onButtonClearClick */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.getComponentDataStoreByUid('e8038646216e2dfef6edf6481f92c935'), 'clientId', ___arguments['inputValue']);

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_cecb6d567d290791fceb50b4e46ad341/logic/2bde961dd5cdf71ab6051571b4b5ab7c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.rootComponentData, 'pageCount')) > 1)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_cecb6d567d290791fceb50b4e46ad341/logic/49998341ffdcd90e312d66e92aa5539a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e8038646216e2dfef6edf6481f92c935', (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e8038646216e2dfef6edf6481f92c935')), 'display'))));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f6a8fac14a21989edc6129f7d6b57f7e/logic/ff4bcf06583f6a611fdf25ba379d1cce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('PAGE DATA');
  console.log(___arguments.context.pageData);
  console.log('COMPONENT DATA');
  console.log(___arguments.context.rootComponentData);
  console.log('DATE STRINGS');
  console.log((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.rootComponentData, 'start')))));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f6a8fac14a21989edc6129f7d6b57f7e/logic/ee928f352e3febe6784dd6c273e443a8/bundle.js', [], () => ({
  /* content */
  /* handler:initValueLogic */
  async ['initValueLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.rootComponentData, 'start')))), 'shortDateString'))

  },
  /* handler:initValueLogic */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var break2, originalStart, originalEnd, newStart, newDateStr;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  newDateStr = (getObjectProperty(___arguments.context.rootComponentData, 'ui.partialDate'));
  originalStart = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments.context.rootComponentData, 'start'))));
  originalEnd = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments.context.rootComponentData, 'end'))));
  break2 = (getObjectProperty(___arguments.context.rootComponentData, 'break'));
  if (!(await BackendlessUI.Functions.Custom['fn_c46539ee9ebb2936bbd424e93e08356b'](newDateStr))) {
    ___arguments.context.rootComponentData['start'] = null;
    await new Promise(r => setTimeout(r, 100 || 0));
    ___arguments.context.rootComponentData['start'] = originalStart;
    return ;
  }
  newStart = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae'](newDateStr, (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](originalStart)), 'timeString'))));
  ___arguments.context.rootComponentData['start'] = newStart;
  ___arguments.context.rootComponentData['end'] = (await BackendlessUI.Functions.Custom['fn_b48df14473b24f1ae216bd5e59da9584'](newStart, break2, ((originalEnd - originalStart) / 60000 - break2)));

  },
  /* handler:onLostFocusEvent */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.rootComponentData, 'ui.partialDate', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* content */
}))

define('./components/reusable/r_f6a8fac14a21989edc6129f7d6b57f7e/logic/41ac9bedc7d53a69f9432b8d1aa34fe4/bundle.js', [], () => ({
  /* content */
  /* handler:initValueLogic */
  async ['initValueLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.rootComponentData, 'start')))), 'timeString'))

  },
  /* handler:initValueLogic */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.rootComponentData, 'ui.partialStart', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var break2, originalStart, originalEnd, newStart, newTimeStr;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  newTimeStr = (getObjectProperty(___arguments.context.rootComponentData, 'ui.partialStart'));
  originalStart = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments.context.rootComponentData, 'start'))));
  originalEnd = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments.context.rootComponentData, 'end'))));
  break2 = (getObjectProperty(___arguments.context.rootComponentData, 'break'));
  if (!(await BackendlessUI.Functions.Custom['fn_20a6411414dd0a2b5cdef3bb3bc8ed0f'](newTimeStr))) {
    ___arguments.context.rootComponentData['start'] = null;
    await new Promise(r => setTimeout(r, 100 || 0));
    ___arguments.context.rootComponentData['start'] = originalStart;
    return ;
  }
  newStart = (await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae']((getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](originalStart)), 'shortDateString')), newTimeStr));
  ___arguments.context.rootComponentData['start'] = newStart;
  ___arguments.context.rootComponentData['end'] = (await BackendlessUI.Functions.Custom['fn_b48df14473b24f1ae216bd5e59da9584'](newStart, break2, ((originalEnd - originalStart) / 60000 - break2)));

  },
  /* handler:onLostFocusEvent */
  /* content */
}))

define('./components/reusable/r_f6a8fac14a21989edc6129f7d6b57f7e/logic/294775c53d91bd2948311ab6c1cd6fa4/bundle.js', [], () => ({
  /* content */
  /* handler:initValueLogic */
  async ['initValueLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.rootComponentData, 'end')))), 'timeString'))

  },
  /* handler:initValueLogic */
  /* handler:onChangeValue */
  async ['onChangeValue'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.rootComponentData, 'ui.partialEnd', ___arguments['value']);

  },
  /* handler:onChangeValue */
  /* handler:onLostFocusEvent */
  async ['onLostFocusEvent'](___arguments) {
    var dayOffset, newTimeStr, startString, originalEnd, originalStart;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  newTimeStr = (getObjectProperty(___arguments.context.rootComponentData, 'ui.partialEnd'));
  originalStart = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments.context.rootComponentData, 'start'))));
  originalEnd = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c']((getObjectProperty(___arguments.context.rootComponentData, 'end'))));
  startString = (await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12']((getObjectProperty(___arguments.context.rootComponentData, 'start'))));
  if (!(await BackendlessUI.Functions.Custom['fn_20a6411414dd0a2b5cdef3bb3bc8ed0f'](newTimeStr))) {
    ___arguments.context.rootComponentData['end'] = null;
    await new Promise(r => setTimeout(r, 100 || 0));
    ___arguments.context.rootComponentData['end'] = originalEnd;
    return ;
  }
  dayOffset = (await BackendlessUI.Functions.Custom['fn_3a2aee9d92cd50253b5175bcd681beb3']((getObjectProperty(startString, 'timeString')), newTimeStr));
  ___arguments.context.rootComponentData['end'] = ((await BackendlessUI.Functions.Custom['fn_720e14fd43b2fb292b36238f0fd362ae']((getObjectProperty(startString, 'shortDateString')), newTimeStr)) + dayOffset * 86400000);

  },
  /* handler:onLostFocusEvent */
  /* content */
}))

define('./components/reusable/r_ef91cf1d79871b263c321b51f035d016/logic/2418909e14a55fe29f54995bcba1bebb/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var result;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  result = (await BackendlessUI.Functions.Custom['fn_29957d83d80974dad1a157b43bf89f85'](___arguments['inputValue'], 'clients', 'client-autocomplete', ___arguments.context.pageData, (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId'))));
  if (result) {
    ___arguments.context.rootComponentData['objectId'] = result;
  }

  },
  /* handler:onChange */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.rootComponentData, 'objectId')) {
    return 'Client';
  } else {
    return '-- Select Client --';
  }

  },
  /* handler:placeholderLogic */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    var options;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, '___ui.client-autocomplete')) ? (getObjectProperty(___arguments.context.pageData, '___ui.client-autocomplete')) : [])

  },
  /* handler:optionsLogic */
  /* handler:onButtonClearClick */
  async ['onButtonClearClick'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_dcaa7742e74995275d85b9230f9b24b6'](___arguments.context.rootComponentData, 'objectId', null);

  },
  /* handler:onButtonClearClick */
  /* content */
}))

define('./components/reusable/r_07c22d14277c4854a2f61d3929f1980a/logic/a0e3aa6b43389472b66647fba2e8dcc2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_07c22d14277c4854a2f61d3929f1980a/logic/f49adeeb737576ca0cc8e23eddb15fff/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var dateString;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.rootComponentData, 'day')) == 1) {
    return ([(getObjectProperty(___arguments.context.rootComponentData, 'month')),' ',(getObjectProperty(___arguments.context.rootComponentData, 'day'))].join(''));
  } else {
    return (getObjectProperty(___arguments.context.rootComponentData, 'day'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_07c22d14277c4854a2f61d3929f1980a/logic/4abde3d3ce05f4fee1111aeb5cde7423/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_a3ae033f2c0dfcff4a67570cee1961a6']((getObjectProperty(___arguments.context.rootComponentData, 'date')), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'hover')) == (getObjectProperty(___arguments.context.rootComponentData, 'date')) && !(getObjectProperty(___arguments.context.pageData, '___ui.multiSelect')) ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_07c22d14277c4854a2f61d3929f1980a/logic/7d35b16ae59d4e239eef78cb01163944/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(await BackendlessUI.Functions.Custom['fn_0791a4ce57a674abe99a52461d43d007']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f317a32d0eb5dad9d6fbc778e0199ae'), 'start')))),' ',(await BackendlessUI.Functions.Custom['fn_88457bb202285b8ddf4845cdd7fb729f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7f317a32d0eb5dad9d6fbc778e0199ae'), 'locum.orgName'))))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_07c22d14277c4854a2f61d3929f1980a/logic/7f317a32d0eb5dad9d6fbc778e0199ae/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var selectedIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, '___ui.multiSelect')) {
    selectedIds = (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.objectIds'));
    if (selectedIds && (selectedIds.includes((getObjectProperty(___arguments.context.dataModel, 'objectId'))))) {
      return ['sessionCell selected'];
    }
  } else {
    if ((getObjectProperty(___arguments.context.dataModel, 'objectId')) == (getObjectProperty(___arguments.context.pageData, 'selectedItem.objectId'))) {
      return ['sessionCell selected'];
    }
  }
  if (getObjectProperty(___arguments.context.dataModel, 'locum.objectId')) {
    return ['sessionCell filled'];
  } else {
    return ['sessionCell unfilled'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var session, sessions, objectId, selectedIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (getObjectProperty(___arguments.context.pageData, '___ui.multiSelect')) {
    selectedIds = (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.objectIds'));
    sessions = (getObjectProperty(___arguments.context.pageData, 'multiSelectItems.sessions'));
    objectId = (getObjectProperty(___arguments.context.dataModel, 'objectId'));
    if (selectedIds.includes(objectId)) {
      removeItemInList(selectedIds, objectId, '');
      removeItemInList(sessions, ___arguments.context.dataModel, 'objectId');
    } else {
      addItemToList(selectedIds, objectId);
      addItemToList(sessions, ___arguments.context.dataModel);
    }
  } else {
    session = ___arguments.context.dataModel;
    ___arguments.context.pageData['selectedItem'] = (await BackendlessUI.Functions.Custom['fn_ced4e1a3026d81b341030207bf58034c'](session));
    ___arguments.context.pageData['selectedItem_original'] = session;
    console.log('filledAt: ');
    if (getObjectProperty(___arguments.context.dataModel, 'filledAt')) {
      console.log((new Date((getObjectProperty(___arguments.context.dataModel, 'filledAt')))).toLocaleTimeString());
    }
    console.log('cancelledAt: ');
    if (getObjectProperty(___arguments.context.dataModel, 'cancelledAt')) {
      console.log((new Date((getObjectProperty(___arguments.context.dataModel, 'cancelledAt')))).toLocaleTimeString());
    }
  }

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  ['onTooltipTextAssignment'](___arguments) {
    var locumName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  locumName = (getObjectProperty(___arguments.context.dataModel, 'locum.orgName'));
  if (!locumName) {
    locumName = 'Unfilled';
  }

  return ([(getObjectProperty(___arguments.context.dataModel, 'client.orgName')),'/',locumName].join(''))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./components/reusable/r_07c22d14277c4854a2f61d3929f1980a/logic/e93553ae4bc51ab455d6ad924b0fa59b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.rootComponentData, 'isThisMonth'))) {
    return ['dayCell notMonth'];
  } else if ((getObjectProperty(___arguments.context.rootComponentData, 'date')) == (getObjectProperty((await BackendlessUI.Functions.Custom['fn_6c0c08444022d09eeab341060619eb12'](((new Date()).valueOf()))), 'isoDateString'))) {
    return ['dayCell today'];
  } else {
    return ['dayCell'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['hover'] = (getObjectProperty(___arguments.context.rootComponentData, 'date'));

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./components/reusable/r_c26494bf1e5a82a4da97bc0427b53c4a/logic/2418909e14a55fe29f54995bcba1bebb/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_5f6f7f1806d336185ecbde944bc549a8'](___arguments['inputValue'], 'clients', ___arguments.context.rootComponentData, (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')));

  },
  /* handler:onChange */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.rootComponentData, 'objectId')) {
    return 'Client';
  } else {
    return '-- Select Client --';
  }

  },
  /* handler:placeholderLogic */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    var options;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  options = (getObjectProperty(___arguments.context.rootComponentData, '___ui.options'));
  if (options) {
    return options;
  } else {
    return [({ [`value`]: (getObjectProperty(___arguments.context.rootComponentData, 'objectId')),[`label`]: (getObjectProperty(___arguments.context.rootComponentData, 'orgName')) })];
  }

  },
  /* handler:optionsLogic */
  /* handler:onButtonClearClick */
  async ['onButtonClearClick'](___arguments) {
      await (async function(obj, retainedProperties) {
  	  if (!retainedProperties || retainedProperties.length === 0) {
  	    for (let key in obj) {
  	      if (obj.hasOwnProperty(key)) {
  	        delete obj[key];
  	      }
  	    }
  	  } else {
  	    const keysToRetain = new Set(retainedProperties);
  	    for (let key in obj) {
  	      if (obj.hasOwnProperty(key) && !keysToRetain.has(key)) {
  	        delete obj[key];
  	      }
  	    }
  	  }
  })(___arguments.context.rootComponentData, null);

  },
  /* handler:onButtonClearClick */
  /* handler:disabledLogic */
  ['disabledLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:disabledLogic */
  /* content */
}))

define('./components/reusable/r_0e16a939e35cf27a854db62cd46ac912/logic/2418909e14a55fe29f54995bcba1bebb/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await BackendlessUI.Functions.Custom['fn_5f6f7f1806d336185ecbde944bc549a8'](___arguments['inputValue'], 'locums', ___arguments.context.rootComponentData, (getObjectProperty(___arguments.context.appData, 'currentOrg.objectId')));

  },
  /* handler:onChange */
  /* handler:placeholderLogic */
  ['placeholderLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.rootComponentData, 'objectId')) {
    return 'Locum';
  } else {
    return '-- Select Locum --';
  }

  },
  /* handler:placeholderLogic */
  /* handler:optionsLogic */
  ['optionsLogic'](___arguments) {
    var options;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  options = (getObjectProperty(___arguments.context.rootComponentData, '___ui.options'));
  if (options) {
    return options;
  } else {
    return [({ [`value`]: 'DELETE',[`label`]: '-- UNASSIGNED --' }), ({ [`value`]: (getObjectProperty(___arguments.context.rootComponentData, 'objectId')),[`label`]: (getObjectProperty(___arguments.context.rootComponentData, 'orgName')) })];
  }

  },
  /* handler:optionsLogic */
  /* handler:onButtonClearClick */
  async ['onButtonClearClick'](___arguments) {
      await (async function(obj, retainedProperties) {
  	  if (!retainedProperties || retainedProperties.length === 0) {
  	    for (let key in obj) {
  	      if (obj.hasOwnProperty(key)) {
  	        delete obj[key];
  	      }
  	    }
  	  } else {
  	    const keysToRetain = new Set(retainedProperties);
  	    for (let key in obj) {
  	      if (obj.hasOwnProperty(key) && !keysToRetain.has(key)) {
  	        delete obj[key];
  	      }
  	    }
  	  }
  })(___arguments.context.rootComponentData, null);

  },
  /* handler:onButtonClearClick */
  /* handler:disabledLogic */
  ['disabledLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'ui.editable')))

  },
  /* handler:disabledLogic */
  /* content */
}))

